import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const isImmutableType = (type) => {
  const currentType = type || "";
  return (
    currentType === "READING_COMPREHENSION" || currentType === "COMPOSITION"
  );
};

const QuestionTypeSelect = ({ value, handleChange, restrict }) => {
  const safeHandleChange = (event) => {
    if (typeof handleChange === "function") {
      handleChange(event);
    }
  };

  const shouldDisable = (itemValue) => {
    return (
      typeof restrict === "function" && restrict() && isImmutableType(itemValue)
    );
  };

  return (
    <FormControl fullWidth>
      <InputLabel>نوع السؤال</InputLabel>
      <Select
        value={value || ""}
        onChange={safeHandleChange}
        name="type"
        sx={{
          backgroundColor: "#fafafa",
        }}
      >
        <MenuItem
          value="LINKING_TOOLS"
          disabled={restrict && isImmutableType(value)}
        >
          أدوات الربط
        </MenuItem>
        <MenuItem
          value="PREPOSITIONS"
          disabled={restrict && isImmutableType(value)}
        >
          حروف الـجَـر
        </MenuItem>
        <MenuItem
          value="PRONOUN_TIME_COMPATIBILITY"
          disabled={restrict && isImmutableType(value)}
        >
          ملائمة الضمير مع الزمن
        </MenuItem>
        <MenuItem
          value="ADJECTIVE_NOUN_COMPATIBILITY"
          disabled={restrict && isImmutableType(value)}
        >
          ملائمة الصفة مع الموصوف
        </MenuItem>
        <MenuItem
          value="LINGUISTIC_RICHNESS"
          disabled={restrict && isImmutableType(value)}
        >
          الثروة اللغوية
        </MenuItem>
        <MenuItem
          value="SENTENCE_REPHRASING"
          disabled={restrict && isImmutableType(value)}
        >
          إعـــادة صياغة الجمل
        </MenuItem>
        <MenuItem
          value="READING_COMPREHENSION"
          disabled={shouldDisable("READING_COMPREHENSION")}
        >
          فهم المقروء
        </MenuItem>
        <MenuItem value="COMPOSITION" disabled={shouldDisable("COMPOSITION")}>
          موضوع انشاء
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default QuestionTypeSelect;
