import React, { Component } from "react";
import { Container, Typography, Paper } from "@mui/material"; // Adjust according to your MUI version
import Lottie from "lottie-react";
import somethingWrong from "../loaders/somethingWrong.json";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // log the error to an error reporting service

    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Lottie animationData={somethingWrong} height={400} width={400} />
          <Paper elevation={3} style={{ padding: "16px", marginTop: "16px" }}>
            <Typography variant="h5" gutterBottom>
              عفوًا! حدث خطأ ما.
            </Typography>
            <Typography variant="body1">
              {this.state.error && this.state.error.toString()}
            </Typography>
          </Paper>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
