import { Box } from "@mui/material";
import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0, y: "-50%" },
  visible: { opacity: 1, y: "0%" },
};

const BlankSpace = ({ previewChoice }) => (
  <Box
    component="span"
    sx={{
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid black",
      borderRadius: "5px",
      width: "fit-content",
      minWidth: "100px",
      height: "25px",
      marginLeft: "5px",
      marginRight: "5px",
      verticalAlign: "middle",
    }}
  >
    {/* Actual preview text */}
    {previewChoice && (
      <motion.span
        style={{
          whiteSpace: "nowrap",
          padding: "0 10px",
        }}
        variants={variants}
        initial="hidden"
        animate="visible"
      >
        {previewChoice}
      </motion.span>
    )}
  </Box>
);

export default BlankSpace;
