import React, { useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Box,
  Grid,
  Divider,
  Pagination,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useParams } from "react-router-dom";
import ExamScoreHeader from "./ExamScoreHeader";
import QuestionAccordion from "./QuestionAccordion";
import { useAuth } from "../../Contexts/AuthContext";
import useExamResults from "../../Hooks/useExamResults";
import ResultsSkeleton from "./ResultsSkeleton";

const ExamEnd = () => {
  const { examId } = useParams();
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentPage, setCurrentPage] = useState(1);

  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);

  const questionsPerPage = 8;

  const lastQuestionIdx = currentPage * questionsPerPage;
  const firstQuestionIdx = lastQuestionIdx - questionsPerPage;

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const { results, score, maxScore, isLoading, currentChapterPage } =
    useExamResults(examId, user.id);

  const handlePageChange = (_, value) => {
    paginate(value);
  };

  const moveToNextChapter = () => {
    if (currentChapterIndex < results.length - 1) {
      setCurrentChapterIndex((prev) => prev + 1);
      paginate(1);
    }
  };

  const moveToPreviousChapter = () => {
    if (currentChapterIndex > 0) {
      setCurrentChapterIndex((prev) => prev - 1);
      paginate(1);
    }
  };

  const calculateTotalQuestionsUpToIndex = (questions, index) => {
    let count = 0;
    for (let i = 0; i < index; i++) {
      if (questions[i].type !== "READING_COMPREHENSION") {
        count++;
      }
      if (questions[i].type === "READING_COMPREHENSION") {
        count += questions[i].associatedQuestions?.length || 0;
      }
    }
    return count;
  };

  return (
    <Container
      maxWidth="md"
      sx={{
        mb: 3,
      }}
    >
      <Paper elevation={5} style={{ padding: "2em", marginTop: "3em" }}>
        <Grid container spacing={2}>
          <ExamScoreHeader
            isLoading={isLoading}
            score={score}
            maxScore={maxScore}
          />

          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={moveToPreviousChapter}
              disabled={currentChapterIndex === 0}
              size={isMobile ? "small" : "large"}
            >
              الفصل السابق
            </Button>

            <Typography
              variant="h6"
              gutterBottom
              style={{ flexGrow: 1, textAlign: "center" }}
            >
              {results[currentChapterIndex]?.name ||
                `الفصل ${currentChapterIndex + 1}`}
            </Typography>
            <Button
              onClick={moveToNextChapter}
              disabled={currentChapterIndex === results.length - 1}
              size={isMobile ? "small" : "large"}
            >
              الفصل التالي
            </Button>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <ResultsSkeleton />
            ) : (
              results.length > 0 && (
                <React.Fragment>
                  {results[currentChapterIndex].questions &&
                    results[currentChapterIndex].questions
                      .slice(firstQuestionIdx, lastQuestionIdx)
                      .map((question, idx) => {
                        const startingCount =
                          calculateTotalQuestionsUpToIndex(
                            results[currentChapterIndex].questions,
                            firstQuestionIdx + idx
                          ) + 1;

                        return (
                          <QuestionAccordion
                            key={question.id}
                            question={question}
                            idx={idx}
                            currentPage={currentPage}
                            startingCount={startingCount}
                          />
                        );
                      })}
                </React.Fragment>
              )
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Pagination
              count={currentChapterPage[currentChapterIndex]}
              page={currentPage}
              onChange={handlePageChange}
              shape="rounded"
              showFirstButton
              showLastButton
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} align="center">
            <Box mt={2}>
              <Typography variant="body1" style={{ textAlign: "center" }}>
                استعرض إجاباتك وافهم التوضيحات. سيساعدك ذلك في تحسين أدائك في
                المستقبل.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ExamEnd;
