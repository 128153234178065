export const testimonialsData = [
  {
    studentName: 'عبلة محروم',
    city: 'الناصرة',
    feedback:
      'رأيي بالكورس بشكل عام ، مهني ، اكثر من رائع ، من ناحية الدراسة مستوى عالي جداً ، انا كشخص جرب يشتري الرزمة قبل ما يفوت معك دورة، وكثير كثير مستفيدة وحاسة بفرق كبير من ناحية حل ووقت وكل هاي الامور ، ومكملة معك لاوصل للعلامة الي بحلم فيها وفش شك والثقة مليون 🫶',
  },
  {
    studentName: 'شذا بصول',
    city: 'الرينة',
    feedback:
      'رزمة الكتب غنية عن التعريف كافية من مواد ومن اسئلة, نفس مستوى اسئلة المركز القطري وانتِ كمعلمة علمتني الكورس طريقتك مش اي طريقة عادية وانما تفكيرك كان دائما خارج الصندوق وهدفك توصلي لكل طالب بشرح مبسط وممتع عشان يوصله زي ما لازم 💙',
  },
  {
    studentName: 'براءة ريان',
    city: 'كابول',
    feedback:
      'بالرّغم من الضغط والتّعب اللي كان بعد امتحان البسيخومتري أخذت الإعفاء من اوّل مرّة وخلصّت ياعيل🥰الدّورة جدًّا قوية من كل الجوانب، الكتب بتحتوي عأسئلة جامدة كثير وشبيهة جدًّا بأسئلة الامتحان الحقيقي، وأسلوب التّدريس مهني وعمستوى عالي🤍',
  },
  {
    studentName: 'تسنيم سهلة ',
    city: 'طمرة',
    feedback:
      'أحدى الصعوبات الي واجهتها بكل إمتحان ياعيل عملته هو فصل الأنشاء ، الرزمة ساعدتني أتخطى هاي الصعوبه بشكل ملحوظ ، شكرًا كثير سارة أنك كنتِ جزء من هاد النجاح 💗',
  },
  {
    studentName: 'ليليان مراد',
    city: 'كابول',
    feedback:
      'الصراحه الكتب بلايا بجننو وفادوني  بلشو معي من الصفر وكل محل اكثر بكتشف وبتعلم اشياء جديده  هو بعدني مخلصتهنش بس الصراحه نار.',
  },
  {
    studentName: 'مرح كيال',
    city: 'جديدة المكر',
    feedback:
      'رزمة كتب ولا اروع كل اشي احتجتو لاني انجح بإمتحان الياعيل كان موجود بهدول الكتب .اخذت اول مرة بإمتحان الياعيل 73 فقدت الامل و بعد ما نصحتني صحبتي بكتب الياعيل للمعلمة سارة قررت اجرب والي صار انو جبت 110  بالياعيل  بفضل الكتب تبعت المعلمة ساره. شكرا عالنجاح    .',
  },
  {
    studentName: 'تهليل دبور',
    city: 'الناصرة',
    feedback:
      'من احلااا واروع الدورات الي شاركت فيهن فش غنى عنها جبت من اول مره 105، الكتب غنية بكل المواد كلشي فيهن وطبعًا لا غنى عن تعليمك  اروع واطيب معلمه',
  },
  {
    studentName: 'محمد عاصلة',
    city: 'عرابة',
    feedback:
      'دوره كانت على زوم وكانت تجنن صراحة وفادتني صراحة وجبت علامه اكثر مما كنت متوقع والكتب والدفتر تاع الكلمات ، كثير ساعدوني عنجد شكراً 👏 ',
  },
  {
    studentName: 'نادين عتاملة',
    city: 'الرينة',
    feedback:
      'بدي احكي عن الدوره قديش كانت قويه وخاصة الكتب جدا فادوني وفيهن اسئله كتير الي بتيجي بامتحان الياعيل وشرح كامل ووافي للاسئله ولكل الكلمات الي موجوده بالاسئله يعني الكتب مستواها جدا عالي. وبدي اتشكرك معلمتي كتير على مجهودك وعلى دعمك الي اعطتيني ياه❤️ شكرا كتير🌸',
  },
];
