import React, { useEffect, useState } from 'react';

import ComprehensiveQuestion from './ComprehensiveQuestion';
import { motion } from 'framer-motion';
import { Typography, Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import ChoicesList from './ChoicesList';
import BlankSpace from './BlankSpace';
import { saveChoice } from '../../Services/ExamService';
import Swal from 'sweetalert2';

const QuestionRenderer = ({
  currentQuestion,
  selectedChoices,
  setSelectedChoices,
  examSubmitted,
  exam,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [previewChoice, setPreviewChoice] = useState(null);
  const isComprehensive =
    currentQuestion && currentQuestion.type === 'READING_COMPREHENSION';
  const [isSavingChoice, setIsSavingChoice] = useState(false);

  let currentSelectedChoice = selectedChoices[currentQuestion.id];
  const selectedChoiceText = currentQuestion?.choices
    ? currentQuestion.choices[currentSelectedChoice]
    : '';

  useEffect(() => {}, [currentQuestion, selectedChoices]);

  const handleToggle = (index) => {
    const currentChoicesSnapshot = { ...selectedChoices };
    const currentChoice = currentChoicesSnapshot[currentQuestion.id];

    // If the same choice is clicked again, do nothing.
    if (currentChoice === index) {
      return; // Exit the function without making changes.
    }

    const newChoice = currentChoice === index ? null : index;

    // Optimistically update the UI
    const newChoices = {
      ...currentChoicesSnapshot,
      [currentQuestion.id]: newChoice,
    };
    setSelectedChoices(newChoices);

    // Only save the choice if it has changed
    if (currentChoice !== newChoice) {
      setIsSavingChoice(true);

      saveChoice(exam.id, currentQuestion.id, newChoice)
        .then(() => {
          setIsSavingChoice(false); // Stop loading on success
        })
        .catch((error) => {
          console.error('Failed to save choice:', error);

          // Revert choice on error, resetting to the previous state.
          setSelectedChoices(currentChoicesSnapshot);

          Swal.fire({
            icon: 'error',
            title: 'عذرًا...',
            text: 'حدث خطأ أثناء اختيار إجابتك. الرجاء المحاولة مرة أخرى.',
            confirmButtonText: 'حسنًا',
          }).finally(() => {
            setIsSavingChoice(false);
          });
        });
    }
  };

  const handleChoiceHover = (choice) => {
    setPreviewChoice(choice);
  };

  if (isComprehensive) {
    return (
      <ComprehensiveQuestion
        question={currentQuestion}
        examSubmitted={examSubmitted}
        setSelectedChoices={setSelectedChoices}
        selectedChoices={selectedChoices}
        exam={exam}
      />
    );
  }

  return (
    <motion.div
      key={currentQuestion.id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        width: '100%',
      }}
    >
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid item xs={10} md={7}>
          <Box mb={1}>
            <Typography
              sx={{
                fontSize: isMobile ? '1rem' : '1.2rem',
                mb: 2,
                mt: isMobile ? 2 : 0,
              }}
            >
              نص السؤال :
            </Typography>
            <Typography
              sx={{
                fontSize: isMobile ? '1.1rem' : '1.2rem',
                mb: 2,
                minHeight: '4rem',
              }}
            >
              {currentQuestion.text.split('~').map((part, i, arr) => (
                <React.Fragment key={i}>
                  {part}
                  {i < arr.length - 1 && (
                    <BlankSpace
                      previewChoice={previewChoice || selectedChoiceText}
                    />
                  )}
                </React.Fragment>
              ))}
            </Typography>
          </Box>
          <ChoicesList
            choices={currentQuestion.choices}
            selectedChoice={currentSelectedChoice}
            handleToggle={handleToggle}
            handleChoiceHover={handleChoiceHover}
            setPreviewChoice={setPreviewChoice}
            isSavingChoice={isSavingChoice}
          />
        </Grid>
      </Grid>
    </motion.div>
  );
};

export default QuestionRenderer;
