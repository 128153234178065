import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  TextField,
  Grid,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { resetPassword } from "../../Services/UserService";
import Swal from "sweetalert2";

function PasswordReset() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const handlePasswordReset = async () => {
    if (password !== confirmPassword) {
      Swal.fire("خطأ", "كلمات المرور غير متطابقة", "error");
      return;
    }

    try {
      await resetPassword(token, password);
      Swal.fire("تم", "تم إعادة تعيين كلمة المرور بنجاح!", "success");
    } catch (error) {
      Swal.fire("خطأ", error.message, "error");
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{ padding: 4, maxWidth: 400, margin: "auto", mt: 10 }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        إعادة تعيين كلمة المرور
      </Typography>

      <Grid container spacing={3} direction="column">
        <Grid item>
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            label="كلمة المرور الجديدة"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            type={showConfirmPassword ? "text" : "password"}
            label="تأكيد كلمة المرور"
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handlePasswordReset}
          >
            إرسال
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default PasswordReset;
