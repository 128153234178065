import { useState, useEffect } from 'react';
import { getAllQuestions } from '../Services/QuestionService';
import Swal from 'sweetalert2';

const useRegularQuestions = () => {
  const [regularQuestions, setRegularQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalQuestionsCount, setTotalQuestionsCount] = useState(0);
  const [currentRegularPage, setCurrentRegularPage] = useState(0);
  const [selectedQuestionType, setSelectedQuestionType] = useState([]);

  useEffect(() => {
    fetchRegularQuestions(currentRegularPage, 5, selectedQuestionType);
  }, [currentRegularPage, selectedQuestionType]);

  const fetchRegularQuestions = async (page = 0, size = 5, types = []) => {
    setLoading(true);
    try {
      const typeParam = types.length > 0 ? types.join(',') : null;
      const regularResponse = await getAllQuestions(page, size, typeParam);
      const regularContent = regularResponse?.content || [];
      setTotalQuestionsCount(regularResponse?.totalElements || 0);
      setTotalPages(regularResponse?.totalPages || 0);

      if (regularContent.length > 0) {
        setRegularQuestions(regularContent);
      } else {
        console.warn('No regular questions found.');
        if (page > 0) {
          setCurrentRegularPage(regularResponse?.totalPages);
        }
      }
    } catch (error) {
      console.error('Error fetching regular questions:', error.message);
      Swal.fire({
        title: 'خطأ!',
        text: 'فشل في جلب الأسئلة العادية. يرجى المحاولة مرة أخرى.',
        icon: 'error',
        confirmButtonText: 'حسنًا',
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    regularQuestions,
    loading,
    setLoading,
    fetchRegularQuestions,
    totalPages,
    totalQuestionsCount,
    currentRegularPage,
    setCurrentRegularPage,
    selectedQuestionType,
    setSelectedQuestionType,
  };
};

export default useRegularQuestions;
