import React, { useEffect, useState } from "react";
import { Typography, Box, Slide, useMediaQuery, Grid } from "@mui/material";

const CompositionTimer = ({ startTime, endTime, onTimeUp }) => {
  const [secondsLeft, setSecondsLeft] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [timeUp, setTimeUp] = useState(false);

  useEffect(() => {
    const end = new Date(endTime);

    const timerId = setInterval(() => {
      const now = new Date();

      if (now >= end) {
        clearInterval(timerId);
        setTimeUp(true);
        if (onTimeUp) {
          onTimeUp();
        }
      } else {
        const start = new Date(startTime);
        const totalTime = Math.round((end - start) / 1000);
        const elapsed = Math.round((now - start) / 1000);
        const secondsLeft = Math.max(totalTime - elapsed, 0);
        setSecondsLeft(secondsLeft);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [startTime, endTime, onTimeUp]);

  if (timeUp) {
    return null;
  }

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  if (secondsLeft === null) {
    return null;
  }

  const determineColor = () => {
    if (secondsLeft <= 120) {
      return "rgba(255, 59, 48, 0.7)";
    }
    return "rgb(67, 87, 117)";
  };

  return (
    <Slide direction="up" in={secondsLeft !== null} mountOnEnter unmountOnExit>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          mt: 3,
        }}
      >
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              width: "100%",
              padding: 1,
              boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.1)",
              borderRadius: 5,
              backgroundColor: "white",
              textAlign: "center",
              zIndex: 2,
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          >
            <Typography
              variant={isMobile ? "subtitle1" : "h5"}
              align="center"
              sx={{
                color: determineColor(),
                transition: "color 1s ease",
              }}
            >
              {formatTime(secondsLeft)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default CompositionTimer;
