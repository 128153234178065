import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";
import gear from "../../../../loaders/gear.svg";
import { StyledTableCell } from "../Inputs/StyledTableCell";
import { StyledTableRow } from "../Inputs/StyledTableRow";
import { getQuestionFlags } from "../../../../Services/FlagService";
import FlagDetailDialog from "./FlagDetailDialog";
import { columns, renderCellContent } from "./flagsColumns";

const QuestionFlagsTable = () => {
  const [flags, setFlags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  const fetchFlags = async () => {
    setLoading(true);
    try {
      let flagsData = await getQuestionFlags();

      flagsData = flagsData.sort((a, b) => {
        const dateA = new Date(a.flaggedAt);
        const dateB = new Date(b.flaggedAt);

        return dateB - dateA;
      });

      setFlags(flagsData);
    } catch (error) {
      console.error("حدث خطأ أثناء جلب البيانات: ", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFlags();
  }, []);

  const handleClickOpen = (flag) => {
    setSelectedFlag(flag);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        borderRadius: "15px",
        overflow: "hidden",
        boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
      }}
    >
      <Table aria-label="جدول مخصص">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell
                key={column.field}
                align="center"
                style={{ width: column.width }}
              >
                {column.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <StyledTableRow>
              <StyledTableCell colSpan={columns.length}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={gear}
                    alt="جارٍ التحميل..."
                    style={{ marginRight: "10px" }}
                  />
                  جاري تحميل البيانات...
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ) : flags.length === 0 ? (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={columns.length}>
                لا يوجد بيانات لعرضها
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            flags.map((flag) => (
              <StyledTableRow key={flag.id}>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.field}
                    align="center"
                    style={{ width: column.width }}
                  >
                    {renderCellContent(flag, column, handleClickOpen)}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))
          )}
        </TableBody>
      </Table>

      <Pagination
        count={Math.ceil(flags.length / rowsPerPage)}
        page={currentPage}
        onChange={handleChangePage}
        shape="rounded"
        variant="outlined"
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center",
        }}
      />

      <FlagDetailDialog
        open={open}
        handleClose={handleClose}
        flag={selectedFlag}
        fetchFlags={fetchFlags}
      />
    </TableContainer>
  );
};

export default QuestionFlagsTable;
