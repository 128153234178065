import React, { useEffect, useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  useTheme,
  FormHelperText,
} from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Colors } from '../../../../Utilities/Colors';

const questionTypes = [
  { id: 'LINKING_TOOLS', label: 'أدوات الربط' },
  { id: 'PREPOSITIONS', label: 'حروف الجر' },
  { id: 'PRONOUN_TIME_COMPATIBILITY', label: 'ملائمة الضمير مع الزمن' },
  { id: 'ADJECTIVE_NOUN_COMPATIBILITY', label: 'ملائمة الصفة مع الموصوف' },
  { id: 'LINGUISTIC_RICHNESS', label: 'الثروة اللغوية' },
  { id: 'SENTENCE_REPHRASING', label: 'إعادة صياغة الجمل' },
  { id: 'COMPOSITION', label: 'مواضيع الإنشاء' },
];

const TypesFilter = ({ value, handleChange, loading }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedTypes, setSelectedTypes] = useState(value);

  useEffect(() => {
    setSelectedTypes(value);
  }, [value]);

  const handleTypeChange = (event) => {
    const newValue =
      typeof event.target.value === 'string'
        ? event.target.value.split(',')
        : event.target.value;
    setSelectedTypes(newValue);
    handleChange(newValue);
  };
  return (
    <FormControl
      sx={{
        width: isMobile ? '100%' : '450px',
        backgroundColor: Colors.inputColor,
        borderRadius: '5px',
        borderColor: Colors.mediumBlue,
      }}
    >
      <InputLabel
        id="multi-select-question-type-label"
        sx={{ color: Colors.darkBlue, pl: 1 }}
      >
        أنواع الأسئلة
      </InputLabel>
      <Select
        multiple
        value={selectedTypes}
        onChange={handleTypeChange}
        input={
          <OutlinedInput
            label="أنواع الأسئلة"
            sx={{
              '& fieldset': { borderColor: Colors.independence },
              '&:hover fieldset': { borderColor: Colors.brightOrange },
              '&.Mui-focused fieldset': { borderColor: Colors.mediumBlue },
            }}
          />
        }
        renderValue={(selected) =>
          selected
            .map(
              (val) =>
                questionTypes.find((type) => type.id === val)?.label || val
            )
            .join(', ')
        }
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 48 * 4.5 + 8,
              width: 250,
              backgroundColor: Colors.platinum,
            },
          },
        }}
        sx={{
          '& .MuiSelect-select': { color: Colors.darkBlue },
          '& .MuiSvgIcon-root': { color: Colors.mediumBlue },
        }}
      >
        {questionTypes.map(({ id, label }) => (
          <MenuItem
            key={id}
            value={id}
            sx={{
              '&.Mui-selected': {
                backgroundColor: 'transparent',
                '&:hover': { backgroundColor: Colors.lightBlue },
              },
              '&:hover': { backgroundColor: Colors.lightBlue },
            }}
            disabled={loading}
          >
            <Checkbox
              checked={selectedTypes.includes(id)}
              sx={{
                '&.Mui-checked': {
                  color: Colors.brightOrange,
                  '&.MuiCheckbox-colorSecondary.Mui-checked:hover': {
                    backgroundColor: 'rgba(253, 143, 36, 0.08)',
                  },
                },
                color: Colors.mediumBlue,
              }}
              disabled={loading}
            />
            <ListItemText primary={label} sx={{ color: Colors.darkBlue }} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText
        sx={{
          color: Colors.darkBlue,
          textAlign: 'right',
        }}
      >
        اختر الأنواع للبحث
      </FormHelperText>
    </FormControl>
  );
};

export default TypesFilter;
