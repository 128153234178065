import React from "react";
import Lottie from "lottie-react";
import hourglass from "../../loaders/hourglass.json";
import { Typography, Box } from "@mui/material";
import { Colors } from "../../Utilities/Colors";

const AuthLoader = ({ statusMessage }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        backgroundColor: Colors.inputColor,
        p: 4,
        borderRadius: 2,
        boxShadow: "0 0 15px rgba(0,0,0,0.1)",
      }}
    >
      <Box
        sx={{
          p: 2,
        }}
      >
        <Lottie
          animationData={hourglass}
          style={{
            width: "100%",
            maxWidth: "200px",
          }}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{
          color: Colors.darkBlue,
          textAlign: "center",
          maxWidth: "80%",
          fontWeight: "bold",
        }}
      >
        {statusMessage}
      </Typography>
    </Box>
  );
};

export default AuthLoader;
