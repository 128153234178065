import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

export const sharedColumns = (handleEdit, handleDelete) => [
  {
    field: 'type',
    headerName: 'النوع',
    align: 'center',
    renderCell: (params) => {
      switch (params.value) {
        case 'LINKING_TOOLS':
          return 'أدوات الربط';
        case 'PREPOSITIONS':
          return 'حروف الجر';
        case 'PRONOUN_TIME_COMPATIBILITY':
          return 'ملائمة الضمير مع الزمن';
        case 'ADJECTIVE_NOUN_COMPATIBILITY':
          return 'ملائمة الصفة مع الموصوف';
        case 'LINGUISTIC_RICHNESS':
          return 'الثروة اللغوية';
        case 'SENTENCE_REPHRASING':
          return 'إعادة صياغة الجمل';
        case 'READING_COMPREHENSION':
          return 'فهم المقروء';
        case 'COMPOSITION':
          return 'موضوع انشاء';
        default:
          return 'غير معروف';
      }
    },
  },
  {
    field: 'actions',
    headerName: 'الإجراءات',
    align: 'center',
    renderCell: (params) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={() => handleEdit(params.row)}
          aria-label="edit"
          sx={{
            color: '#23314B',
            marginRight: '10px',
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="error"
          onClick={() => handleDelete(params.row.id)}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </div>
    ),
  },
];

export const regularColumns = (setCurrentQuestion, setQuestionDetailsOpen) => [
  {
    field: 'text',
    headerName: 'نص السؤال',

    renderCell: (params) => {
      const parts = params.value?.split('~') || [];
      return (
        <span>
          {parts.map((part, index) => (
            <React.Fragment key={index}>
              {part}
              {index !== parts.length - 1 && (
                <span
                  style={{
                    display: 'inline-block',
                    borderBottom: '2.5px solid #23314B',
                    borderRadius: '10px',
                    width: '3rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    lineHeight: 1,
                  }}
                >
                  &nbsp;
                </span>
              )}
            </React.Fragment>
          ))}
        </span>
      );
    },
  },
  {
    field: 'choices',
    headerName: 'الخيارات',
    align: 'center',
    renderCell: (params) => {
      if (params.row.type === 'COMPOSITION') {
        return 'غير مرتبط';
      }

      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setCurrentQuestion(params.row);
              setQuestionDetailsOpen(true);
            }}
            sx={{
              margin: 'auto',
              padding: '8px 16px',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
              borderRadius: '20px',
              textTransform: 'none',
              transition: '0.3s',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            انظر الاختيارات
          </Button>
        </Box>
      );
    },
  },
  {
    field: 'correctAnswer',
    headerName: 'الإجابة الصحيحة',
    renderCell: (params) => {
      if (params.row.type === 'COMPOSITION') {
        return 'غير مرتبط';
      }
      const answer = params.row.choices?.[params.value] || '';
      const isLongContent = answer.length > 15;
      if (isLongContent) {
        return (
          <Tooltip title={answer} arrow>
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                whiteSpace: 'nowrap',
                width: '140px',
              }}
            >
              {answer}
            </span>
          </Tooltip>
        );
      } else {
        return answer;
      }
    },
  },
];

export const comprehensiveColumns = (
  setCurrentQuestion,
  setQuestionDetailsOpen
) => [
  {
    field: 'text',
    headerName: 'نص السؤال',
    renderCell: (params) => {
      const text = params.value || '';
      const isLongContent = text.length > 80;
      if (isLongContent) {
        return (
          <Tooltip title={text} arrow>
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'block',
                whiteSpace: 'nowrap',
                width: '140px',
              }}
            >
              {text}
            </span>
          </Tooltip>
        );
      } else {
        return text;
      }
    },
  },
  {
    field: 'associatedQuestions',
    headerName: 'الأسئلة المرتبطة',
    align: 'center',
    renderCell: (params) => {
      const isLongContent =
        params.value?.some((question) => question?.text.length > 15) || false;

      if (isLongContent) {
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                setCurrentQuestion(params.row);
                setQuestionDetailsOpen(true);
              }}
              sx={{
                margin: 'auto',
                padding: '8px 16px',
                boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.1)',
                borderRadius: '20px',
                textTransform: 'none',
                transition: '0.3s',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: '2px 4px 6px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              انظر الأسئلة المرتبطة
            </Button>
          </Box>
        );
      } else {
        return (
          <div>
            {params.value.map((question, index) => (
              <Chip
                key={index}
                label={question.text}
                variant="outlined"
                style={{ marginRight: '4px', marginBottom: '4px' }}
              />
            ))}
          </div>
        );
      }
    },
  },
  {
    field: 'numberOfQuestions',
    headerName: 'عدد الأسئلة',
    align: 'center',
    renderCell: (params) => {
      const associatedQuestions = params.row.associatedQuestions;
      return associatedQuestions ? associatedQuestions.length : 0;
    },
  },
];

export const columnsForRegular = (
  handleEdit,
  handleDelete,
  setCurrentQuestion,
  setQuestionDetailsOpen
) => [
  ...regularColumns(setCurrentQuestion, setQuestionDetailsOpen),
  ...sharedColumns(handleEdit, handleDelete),
];

export const columnsForComprehensive = (
  handleEdit,
  handleDelete,
  setCurrentQuestion,
  setQuestionDetailsOpen
) => [
  ...comprehensiveColumns(setCurrentQuestion, setQuestionDetailsOpen),
  ...sharedColumns(handleEdit, handleDelete),
];
