import React from "react";
import {
  Typography,
  Grid,
  Stack,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Colors } from "../../../Utilities/Colors";

const ContinuousPerk = ({ perkDetail }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={2} alignItems="center" sx={{ my: 0.5 }}>
      <Grid
        item
        container
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction="row"
          spacing={{ xs: 1, sm: 3 }}
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%" }}
          useFlexGap
          flexWrap="wrap"
        >
          <Box textAlign="center" sx={{ flex: "1" }}>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              color={Colors.darkBlue}
              fontWeight="bold"
              gutterBottom
            >
              {perkDetail.perk.name}
            </Typography>
            <Typography variant="caption" color={Colors.mediumBlue}>
              الميزة
            </Typography>
          </Box>

          <Box textAlign="center" sx={{ flex: "1" }}>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              color={Colors.independence}
              fontWeight="bold"
            >
              {perkDetail.perk.level}
            </Typography>
            <Typography variant="caption" color={Colors.mediumBlue}>
              المستوى
            </Typography>
          </Box>
        </Stack>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          mt: 1,
        }}
      >
        <Typography variant="body2" color={Colors.independence} gutterBottom>
          وصف الميزة
        </Typography>
        <Typography variant="body2" color={Colors.independence} gutterBottom>
          {perkDetail.perk.description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ContinuousPerk;
