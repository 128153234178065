import { axiosInstance, apiWrapper } from './Service';

export async function getAllPlansForUsers() {
  const response = await apiWrapper(
    () => axiosInstance.get('/plans'),
    'Fetched subscription plans successfully!',
    'Failed to fetch subscription plans.'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getAllPlansForAdmin() {
  const response = await apiWrapper(
    () => axiosInstance.get('/plans/all'),
    'Fetched subscription plans successfully!',
    'Failed to fetch subscription plans.'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getAllPerks() {
  const response = await apiWrapper(
    () => axiosInstance.get('/perks'),
    'Fetched subscription perks successfully!',
    'Failed to fetch subscription perks.'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function createSubscriptionPlan(planDto) {
  const response = await apiWrapper(
    () => axiosInstance.post('/plans/admin', planDto),
    'Created subscription plan successfully!',
    'Failed to create subscription plan.'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function deleteSubscriptionPlan(planId) {
  const response = await apiWrapper(
    () => axiosInstance.delete(`/plans/admin/${planId}`),
    'Deleted subscription plan successfully!',
    'Failed to delete subscription plan.'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function updateSubscriptionPlan(planId, planDto) {
  const response = await apiWrapper(
    () => axiosInstance.put(`/plans/admin/${planId}`, planDto),
    'Updated subscription plan successfully!',
    'Failed to update subscription plan.'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function createPerk(perkData) {
  const response = await apiWrapper(
    () => axiosInstance.post('/perks/admin', perkData),
    'Perk created successfully!',
    'Failed to create perk.'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function updatePerk(perkId, updatedData) {
  const response = await apiWrapper(
    () => axiosInstance.put(`/perks/admin/${perkId}`, updatedData),
    'Perk updated successfully!',
    'Failed to update perk.'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function deletePerk(perkId) {
  const response = await apiWrapper(
    () => axiosInstance.delete(`/perks/admin/${perkId}`),
    'Perk deleted successfully!',
    'Failed to delete perk.'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getPerkById(perkId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/perks/perk/${perkId}`),
    `Fetched perk with ID ${perkId} successfully!`,
    `Failed to fetch perk with ID ${perkId}.`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function makeTransaction(paymentRequest) {
  const response = await apiWrapper(
    () => axiosInstance.post('/payment/transaction', paymentRequest),
    'Transaction successful',
    'Failed to make transaction'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}
