import { Box, Grid, Typography } from '@mui/material';
import DigitalTimer from './DigitalTimer';

const PageHeader = ({ isMobile, exam, currentChapterIndex, handleTimeUp }) => (
  <Grid container alignItems="center" dir="rtl">
    <Grid item md={2} xs={0}></Grid>

    <Grid
      item
      md={8}
      xs={7}
      sx={{
        zIndex: 2,
      }}
    >
      <Typography
        variant={isMobile ? 'h6' : 'h5'}
        gutterBottom
        align="center"
        sx={{
          visibility: isMobile ? 'hidden' : 'visible',
          fontFamily: 'ExpoArabic',
        }}
      >
        صفحة الامتحان
      </Typography>
    </Grid>

    <Grid item md={2} xs={5}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <DigitalTimer
          startTime={exam.chapters[currentChapterIndex].startTime}
          endTime={exam.chapters[currentChapterIndex].endTime}
          onTimeUp={handleTimeUp}
        />
      </Box>
    </Grid>
  </Grid>
);

export default PageHeader;
