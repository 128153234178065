import React from "react";
import { Button } from "@mui/material";
import { banUser, deleteUser } from "../../../Services/AdminService";
import Swal from "sweetalert2";
import { deleteSwal } from "../../../Utilities/deleteSwal";

const UserActions = ({ user, fetchUsers }) => {
  const handleBanUser = (email, banned) => {
    Swal.fire({
      title: banned ? "هل تريد إلغاء الحظر؟" : "هل أنت متأكد؟",
      text: `${
        banned ? "هل تريد إلغاء حظر" : "هل تريد حظر"
      } المستخدم: ${email}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: banned ? "نعم، إلغاء الحظر!" : "نعم، حظر!",
      cancelButtonText: "إلغاء",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await banUser(email, !banned);
          Swal.fire(
            `${!banned ? "تم حظر" : "تم إلغاء حظر"} المستخدم!`,
            "",
            "success"
          );
          fetchUsers();
        } catch (error) {
          Swal.fire(
            "خطأ",
            `فشل ${!banned ? "حظر" : "إلغاء حظر"} المستخدم`,
            "error"
          );
        }
      }
    });
  };

  const handleDeleteUser = async (email) => {
    await deleteSwal({
      onConfirm: () => deleteUser(email),
      onSuccessDescription: "تم حذف المستخدم!",
      onErrorDescription: "فشل حذف المستخدم.",
    }).then(() => {
      fetchUsers();
    });
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => handleBanUser(user.email, user.banned)}
        sx={{
          marginLeft: 1,
          backgroundColor: user.banned ? "#FD8F24" : "#435775",
        }}
      >
        {user.banned ? "إلغاء الحظر" : "حظر"}
      </Button>
      <Button
        variant="contained"
        onClick={() => handleDeleteUser(user.email)}
        sx={{
          backgroundColor: user.banned ? "#FD8F24" : "#435775",
        }}
      >
        حذف
      </Button>
    </>
  );
};

export default UserActions;
