import React from "react";
import { AccordionDetails, Skeleton } from "@mui/material";
import { Box, Accordion, AccordionSummary } from "@mui/material";

const ResultsSkeleton = () => {
  const renderSkeletonAccordion = () => (
    <Accordion expanded={true}>
      <AccordionSummary>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="text" sx={{ flexGrow: 1, mx: 2 }} />
          <Skeleton variant="circular" width={20} height={20} />
        </Box>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          flexDirection: "column",
          padding: "16px",
        }}
      >
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" width="60%" />
      </AccordionDetails>
    </Accordion>
  );

  return (
    <div>
      {renderSkeletonAccordion()}
      {renderSkeletonAccordion()}
    </div>
  );
};

export default ResultsSkeleton;
