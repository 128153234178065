import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  Container,
  Box,
  useMediaQuery,
} from '@mui/material';
import { testimonialsData } from './testimonialsData';
import { Colors } from '../../Utilities/Colors';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
import { useTheme } from '@emotion/react';

const Testimonials = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    pauseOnHover: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        p: 2,
      }}
    >
      <Slider {...settings}>
        {testimonialsData.map((testimonial, index) => (
          <Box
            key={index}
            sx={{
              p: { xs: 1, sm: 2 },
              display: 'flex',
              justifyContent: 'center',
              boxSizing: 'border-box',
              mt: isMobile ? 2 : 6,
            }}
          >
            <Card
              elevation={3}
              sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                p: 1,
                height: '220px',
                maxWidth: '500px',
                borderRadius: '4px',
                backgroundColor: `${Colors.inputColor}`,
                overflow: 'hidden',
                mr: isMobile ? 0 : 8,
              }}
            >
              <CardContent
                sx={{
                  flex: '1 0 auto',
                  pb: 0,
                  overflowY: 'auto',
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontFamily: 'ExpoArabic',
                    color: Colors.textColor,
                    mb: 2,
                    textAlign: 'center',
                    direction: 'rtl',
                    height: '100px',
                    fontSize: { xs: '14px', sm: '16px' },
                  }}
                >
                  “{testimonial.feedback}”
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  height: '60px',
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    fontFamily: 'ExpoArabic',
                    color: Colors.textColor,
                    fontWeight: 'medium',
                    textAlign: 'center',
                    flex: 1,
                    m: 2,
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      display: 'block',
                      fontWeight: 'bold',
                      fontSize: { xs: '16px', sm: '18px' },
                    }}
                  >
                    {testimonial.studentName}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{
                      display: 'block',
                      fontSize: { xs: '14px', sm: '16px' },
                    }}
                  >
                    {testimonial.city}
                  </Typography>
                </Typography>
              </Box>
            </Card>
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default Testimonials;
