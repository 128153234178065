import React, { useState, useEffect } from "react";
import { Typography, Paper, Container, Box } from "@mui/material";
import {
  deleteComposition,
  getAllCompositions,
} from "../../../../Services/CompositionService";
import CompositionDialog from "./CompositionDialog";
import { getUserById } from "../../../../Services/UserService";
import CompositionsTable from "./CompositionsTable";
import SearchCompositions from "./SearchCompositions";
import { deleteSwal } from "../../../../Utilities/deleteSwal";

const themeStyles = {
  heading: {
    color: "#23314B",
  },
};

const CompositionManagement = () => {
  const [compositions, setCompositions] = useState([]);
  const [selectedComposition, setSelectedComposition] = useState(null);
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchCompositions = async () => {
    try {
      setLoading(true);
      const data = await getAllCompositions();
      setCompositions(data);

      const uniqueUserIds = [...new Set(data.map((comp) => comp.userId))];
      const usersData = {};

      for (const userId of uniqueUserIds) {
        const userData = await getUserById(userId);
        usersData[userId] = userData;
      }
      setUsers(usersData);
    } catch (error) {
      console.error("Failed to fetch compositions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompositions();
  }, []);

  const filteredCompositions = compositions.filter((composition) => {
    const user = users[composition.userId];
    if (user) {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return fullName.includes(searchQuery.toLowerCase());
    }
    return true;
  });

  const handleDeleteConfirmation = (compositionId) => {
    deleteSwal({
      onConfirm: () => handleDeleteComposition(compositionId),
      onSuccessDescription: "تم حذف الإنشاء بنجاح.",
      onErrorDescription: "فشل في حذف الإنشاء.",
    });
  };

  const handleDeleteComposition = async (compositionId) => {
    try {
      await deleteComposition(compositionId);
      const updatedCompositions = compositions.filter(
        (comp) => comp.id !== compositionId
      );
      setCompositions(updatedCompositions);
    } catch (error) {
      console.error("Error deleting composition:", error.message);
    }
  };

  return (
    <Container
      sx={{
        p: 4,
        backgroundColor: "#EAEAEC",
        minHeight: "90vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h5" sx={themeStyles.heading}>
          إدارة مواضيع الإنشاء
        </Typography>
        <SearchCompositions onChange={setSearchQuery} />
      </Box>

      <Paper elevation={3}>
        <CompositionsTable
          compositions={filteredCompositions}
          users={users}
          loading={loading}
          handleDeleteConfirmation={handleDeleteConfirmation}
          setSelectedComposition={setSelectedComposition}
        />
      </Paper>

      <CompositionDialog
        composition={selectedComposition}
        onClose={() => setSelectedComposition(null)}
        fetchCompositions={fetchCompositions}
      />
    </Container>
  );
};

export default CompositionManagement;
