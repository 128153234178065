import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Grid,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import AddQuestionDialog from './AddQuestionDialog';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../../loaders/loader.svg';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import AssociatedQuestionForm from './ComprehensiveQuestion/AssociatedQuestionForm';
import { useAddQuestion } from '../../../Hooks/useAddQuestion';

const AddQuestion = ({
  fetchRegularQuestions,
  fetchComprehensionQuestions,
  currentRegularPage,
  currentComprehensivePage,
  selectedQuestionType,
}) => {
  const {
    question,
    associatedQuestions,
    currentAssociatedQuestion,
    isSideDialogOpen,
    toggleSideDialog,
    open,
    handleOpen,
    handleClose,
    isLoading,
    handleChange,
    handleSubmit,
    submitAssociatedQuestion,
    handleCurrentAssociatedQuestionChange,
    handleAssociatedChoiceChange,
    removeAssociatedChoice,
    addAssociatedChoice,
    removeAssociatedQuestion,
  } = useAddQuestion(fetchRegularQuestions, fetchComprehensionQuestions);

  const handleSubmitWithReset = async () => {
    const result = await handleSubmit();
    if (result) {
      fetchRegularQuestions(currentRegularPage, 5, selectedQuestionType);
      fetchComprehensionQuestions(currentComprehensivePage, 5);
    }
  };

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial example"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
        icon={<SpeedDialIcon />}
        onClick={handleOpen}
        FabProps={{
          sx: {
            bgcolor: '#fe8826',
            '&:hover': {
              bgcolor: '#ff9f4f',
            },
          },
        }}
      >
        <SpeedDialAction
          icon={<AddIcon />}
          tooltipTitle="إضافة سؤال جديد"
          onClick={handleOpen}
        />
      </SpeedDial>
      <Dialog open={open} onClose={handleClose}>
        <Box position="relative" height="100%" width="100%">
          {isLoading && (
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bgcolor="rgba(255, 255, 255, 0.7)"
              zIndex={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <img src={Loader} alt="Loading..." />
            </Box>
          )}
          <DialogTitle textAlign="center">أضف سؤال جديد</DialogTitle>
          <DialogContent>
            <AddQuestionDialog
              question={question}
              handleChange={handleChange}
              associatedQuestions={associatedQuestions}
              handleSideDialogOpen={toggleSideDialog}
              removeAssociatedQuestion={removeAssociatedQuestion}
            />
          </DialogContent>
          <DialogActions
            sx={{
              padding: '0px 20px 20px 20px',
            }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={handleClose}
                  sx={{
                    borderColor: 'red',
                    color: 'red',
                    padding: '5px 20px',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundColor: 'rgba(255,0,0,0.1)',
                    },
                  }}
                  startIcon={<CloseIcon sx={{ ml: 2 }} />}
                >
                  إلغاء
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={handleSubmitWithReset}
                  sx={{
                    borderColor: 'green',
                    color: 'green',
                    padding: '5px 20px',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundColor: 'rgba(0,128,0,0.1)',
                    },
                  }}
                  startIcon={<CheckIcon sx={{ ml: 2 }} />}
                >
                  إضافة
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Dialog>

      <AssociatedQuestionForm
        isSideDialogOpen={isSideDialogOpen}
        toggleSideDialog={toggleSideDialog}
        currentAssociatedQuestion={currentAssociatedQuestion}
        handleCurrentAssociatedQuestionChange={
          handleCurrentAssociatedQuestionChange
        }
        handleAssociatedChoiceChange={handleAssociatedChoiceChange}
        removeAssociatedChoice={removeAssociatedChoice}
        addAssociatedChoice={addAssociatedChoice}
        submitAssociatedQuestion={submitAssociatedQuestion}
      />
    </>
  );
};

export default AddQuestion;
