import React from "react";
import { Button, Paper, Typography, CircularProgress } from "@mui/material";
import CompositionTimer from "./CompositionTimer";

const CompositionButtons = ({
  unsubmittedComposition,
  handleStartComposition,
  lastComposition,
  isLoading,
  isCreatingComposition,
}) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 3,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        background: "#f7f8fc",
      }}
    >
      <Typography variant="subtitle1" align="center">
        كتابة موضوع الإنشاء
      </Typography>

      <Button
        variant="contained"
        onClick={handleStartComposition}
        sx={{
          width: "100%",
          mb: 3,
          mt: 3,
          backgroundColor: unsubmittedComposition ? "#435775" : "#dd8024",
          "&:hover": {
            backgroundColor: unsubmittedComposition ? "#344c64" : "#e0892d",
          },
        }}
        disabled={isLoading || isCreatingComposition}
      >
        {isCreatingComposition ? (
          <>
            <CircularProgress size={24} sx={{ ml: 1 }} />
            جارٍ بدء إنشاء جديد
          </>
        ) : unsubmittedComposition ? (
          "استكمال الإنشاء السابق"
        ) : (
          "بدء إنشاء جديد"
        )}
      </Button>

      {unsubmittedComposition && (
        <>
          <Typography variant="subtitle2" color="textSecondary" align="center">
            لديك انشاء غير مُسلّم! استكمل الانشاء السابق
          </Typography>

          {lastComposition && (
            <CompositionTimer
              startTime={lastComposition.compositionStartDate}
              endTime={lastComposition.compositionEndDate}
            />
          )}
        </>
      )}
    </Paper>
  );
};

export default CompositionButtons;
