import { useState, useEffect } from "react";
import {
  getUserPerkUsage,
  getUserSubscriptionPlan,
} from "../Services/UserService";
import { getExamsInfo } from "../Services/ExamService";

export const usePersonalData = (userId, showSubscription) => {
  const [loading, setLoading] = useState({
    recentExamsScores: true,
    studyConsistency: true,
    subscription: true,
    perks: true,
  });
  const [recentExamsScores, setRecentExamsScores] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [perks, setPerks] = useState([]);
  const [error, setError] = useState({
    recentExamsScores: null,
    studyConsistency: null,
    subscription: null,
    perks: null,
  });

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const examsScoreData = await getExamsInfo(userId);
        setRecentExamsScores(examsScoreData);
      } catch (error) {
        setError((prev) => ({ ...prev, recentExamsScores: error.message }));
      } finally {
        setLoading((prev) => ({ ...prev, recentExamsScores: false }));
      }
    };

    const fetchPerkUsageData = async () => {
      try {
        const perkUsageData = await getUserPerkUsage(userId);
        setPerks(perkUsageData);
      } catch (error) {
        setError((prev) => ({ ...prev, perks: error.message }));
      } finally {
        setLoading((prev) => ({ ...prev, perks: false }));
      }
    };

    const fetchSubscriptionData = async () => {
      try {
        const subscriptionData = await getUserSubscriptionPlan(userId);
        setSubscription(subscriptionData);
      } catch (error) {
        setError((prev) => ({ ...prev, subscription: error.message }));
      } finally {
        setLoading((prev) => ({ ...prev, subscription: false }));
      }
    };

    fetchExamData();
    if (!showSubscription) {
      fetchSubscriptionData();
      fetchPerkUsageData();
    }
  }, [userId, showSubscription]);

  return {
    loading,
    recentExamsScores,
    subscription,
    perks,
    error,
  };
};
