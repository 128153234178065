import React from "react";
import { PerformanceCard } from "./PerformanceCard";
import { Box } from "@mui/material";

const PerformanceMetrics = ({ data }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 2,
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        borderRadius: 2,
      }}
    >
      {Object.entries(data).map(([questionType, metrics]) => (
        <PerformanceCard
          key={questionType}
          questionType={questionType}
          data={metrics}
        />
      ))}
    </Box>
  );
};

export default PerformanceMetrics;
