import { format } from "date-fns";
import differenceInSeconds from "date-fns/differenceInSeconds";

const getDifferenceInMinutesAndSeconds = (startDate, endDate) => {
  const totalSeconds = differenceInSeconds(
    new Date(endDate),
    new Date(startDate)
  );
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  return `${minutes} دقيقة و ${seconds} ثانية`;
};

const getDifferenceInHoursAndMinutes = (startDate, endDate) => {
  const totalSeconds = differenceInSeconds(
    new Date(endDate),
    new Date(startDate)
  );

  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);

  let result = "";

  if (days > 0) {
    result += `${days} يوم, `;
  }
  if (hours > 0 || days > 0) {
    result += `${hours} ساعة, `;
  }
  result += `${minutes} دقيقة`;

  return result;
};

const getDifferenceInHoursMinutesSeconds = (startDate, endDate) => {
  const totalSeconds = differenceInSeconds(
    new Date(endDate),
    new Date(startDate)
  );
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return `${hours} ساعة, ${minutes} دقيقة و ${seconds} ثانية`;
};

const getDurationRepresentation = (duration) => {
  const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
  const matches = regex.exec(duration);

  if (!matches) return null;

  const hours = matches[1] || 0;
  const minutes = matches[2] || 0;

  return `${hours} ساعة, ${minutes} دقيقة `;
};

const formatDateWithHoursAndMinutes = (date) => {
  return format(new Date(date), "dd/MM/yyyy HH:mm");
};

export const timeUtil = {
  inMinutesAndSeconds: getDifferenceInMinutesAndSeconds,
  inHoursAndMinutes: getDifferenceInHoursAndMinutes,
  inHoursMinutesSeconds: getDifferenceInHoursMinutesSeconds,
  extractTime: getDurationRepresentation,
  formatDate: formatDateWithHoursAndMinutes,
};
