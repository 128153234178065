import React from "react";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";

const CustomPagination = ({
  itemsCount,
  itemsPerPage,
  currentPage,
  onPageChange,
}) => {
  return (
    <Grid
      container
      justifyContent="center"
      spacing={1}
      style={{ marginTop: "16px" }}
    >
      <Pagination
        count={Math.ceil(itemsCount / itemsPerPage)}
        page={currentPage}
        onChange={onPageChange}
        variant="outlined"
        color="primary"
        shape="rounded"
      />
    </Grid>
  );
};

export default CustomPagination;
