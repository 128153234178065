import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { TableCell } from "@mui/material";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#2b3c53",
    color: theme.palette.common.white,
    boxShadow: "0px 2px 8px rgba(0,0,0,0.5)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
    color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
    [`&.${tableCellClasses.body}`]: {
      fontSize: 13,
    },
    [`&.${tableCellClasses.head}`]: {},
  },
}));
