import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  List,
  ListItem,
  Button,
  Grid,
  Box,
  Container,
  Paper,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthContext';

const SubscriptionPlans = ({ plans, isSubscriber }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const planRank = {
    GOLD: 3,
    SILVER: 2,
    BRONZE: 1,
  };

  const planColors = {
    GOLD: 'linear-gradient(to right, #af9500, #c5ad33)',
    SILVER: 'linear-gradient(to right, #d7d7d7, #b6b6b6)',
    BRONZE: 'linear-gradient(to right, #6a3805, #a98551)',
  };

  const sortedPlans = [...plans].sort(
    (a, b) => planRank[a.role] - planRank[b.role]
  );

  return (
    <Container
      sx={{
        mb: 10,
      }}
    >
      <Grid container spacing={3}>
        {sortedPlans.map((plan) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={plan.id}
            sx={{
              position: 'relative',
              zIndex: 3,
              mb: { xs: 6, sm: 0 },
            }}
          >
            <CardHeader
              title={plan.name}
              titleTypographyProps={{
                fontFamily: 'ExpoArabic',
                sx: {
                  height: '100%',
                  display: 'flex',
                  alignItems: 'flex-start',
                },
              }}
              sx={{
                width: '75%',
                margin: '0 auto',
                position: 'relative',
                top: '40px',
                background: planColors[plan.role],
                color: plan.role === 'SILVER' ? '#333333' : '#ffffff',
                textAlign: 'center',
                zIndex: 0,
                borderRadius: '20px',
                height: '100px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            />
            <Card
              elevation={3}
              sx={{
                height: '520px',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                background:
                  'linear-gradient(to right, rgba(229, 229, 229, 0.7), rgba(247, 247, 247, 0.2))',
                color: '#23314B',
                backdropFilter: 'blur(5px)',
                WebkitBackdropFilter: 'blur(5px)',
                '&:hover': {
                  boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                },
                transition: 'transform .2s',
                zIndex: 3,
                pointerEvents: 'none',
                position: 'relative',
              }}
            >
              <CardContent
                sx={{ flex: '1 0 auto', p: 0, mt: 4, pointerEvents: 'auto' }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: '#333333',
                  }}
                >
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{
                      textAlign: 'center',
                      fontFamily: 'ExpoArabic',
                    }}
                  >
                    ₪
                    <span
                      style={{
                        color:
                          plan.role === 'GOLD'
                            ? '#c6ae30'
                            : plan.role === 'SILVER'
                            ? '#555555'
                            : plan.role === 'BRONZE'
                            ? '#6d3c09'
                            : undefined,
                        fontWeight: 'bold',
                      }}
                    >
                      {plan.price}
                    </span>
                  </Typography>
                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      textAlign: 'center',
                      marginTop: 1,
                      fontFamily: 'ExpoArabic',
                    }}
                  >
                    مدة الاشتراك:
                    <span
                      style={{
                        color:
                          plan.role === 'GOLD'
                            ? '#c6ae30'
                            : plan.role === 'SILVER'
                            ? '#555555'
                            : plan.role === 'BRONZE'
                            ? '#6d3c09'
                            : undefined,
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}
                      {plan.duration}{' '}
                    </span>
                    يوماً
                  </Typography>
                </Box>

                {plan.perks && plan.perks.length > 0 && (
                  <List sx={{ mt: 2, padding: 0 }}>
                    {' '}
                    {plan.perks.map((perk) => (
                      <ListItem
                        key={perk.id}
                        sx={{
                          py: 0.5,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          my: 0.5,
                        }}
                        disablePadding
                      >
                        <Paper
                          elevation={0}
                          sx={{
                            p: '8px',
                            backgroundColor: 'white',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'right',
                            width: '100%',
                          }}
                        >
                          <Tooltip
                            title={perk.description}
                            arrow
                            placement="top"
                          >
                            <IconButton
                              size="small"
                              sx={{ color: 'green', ml: '8px' }}
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/icons/checkIcon.png`}
                                alt="check"
                                style={{ fontSize: 'inherit' }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            component="div"
                            sx={{
                              flexGrow: 1,
                              textAlign: 'right',
                              fontFamily: 'ExpoArabic',
                            }}
                          >
                            {perk.name}
                          </Typography>
                        </Paper>
                      </ListItem>
                    ))}
                  </List>
                )}
              </CardContent>
            </Card>
            <Button
              variant="contained"
              onClick={() => {
                if (user) {
                  navigate('/subscriptions/subscribe', { state: { plan } });
                } else {
                  navigate('/auth/register');
                }
              }}
              sx={{
                position: 'absolute',
                bottom: '-50px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '60%',
                p: '20px 20px 6px 20px',
                fontSize: '24px',
                background: planColors[plan.role],
                color: plan.role === 'SILVER' ? '#333333' : '#ffffff',
                zIndex: 0,
                borderRadius: '20px',
                height: '80px',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                textAlign: 'center',
                fontFamily: 'ExpoArabic',
                cursor: 'pointer',
                pointerEvents: 'auto',
              }}
              disabled={isSubscriber}
            >
              {isSubscriber
                ? 'لديك بالفعل اشتراك نشط'
                : !user
                ? 'اشترك الآن'
                : 'اشتراك'}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SubscriptionPlans;
