import React from 'react';
import { Typography, Box, Stack, Divider } from '@mui/material';
import { Colors } from '../../Utilities/Colors';
import { Link } from '@mui/material';

const AcademyInfo = () => {
  const email = 'Sarah.rayyan97@gmail.com';
  const phoneNumber = '+972 50 551 1221';
  const lat = 32.849709;
  const lon = 35.182995;
  const wazeLink = `https://waze.com/ul?ll=${lat}%2C${lon}&navigate=yes`;

  return (
    <Box
      sx={{
        pt: 3,
        pb: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Box
        sx={{
          display: 'block',
          width: 'fit-content',
          textAlign: 'center',
          mb: 2,
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            fontSize: '36px',
            fontFamily: 'ExpoArabic',
            fontWeight: 'bold',
            color: Colors.electricBlue,
            width: 'fit-content',
          }}
        >
          طرق الوصول للمعهَد
        </Typography>
        <Divider
          sx={{
            width: '50px',
            bgcolor: '#b1b1b1',
            mx: 'auto',
            height: '4px',
            borderRadius: '5px',
          }}
        />
      </Box>

      <Stack
        direction={{ xs: 'column', sm: 'row-reverse' }}
        spacing={2}
        sx={{ width: '100%' }}
      >
        <Stack
          direction="row"
          justifyContent={{ xs: 'right', sm: 'center' }}
          spacing={2}
          sx={{ width: '100%', mb: 2, gap: 2 }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src="/assets/icons/phone-icon.png"
              alt="Phone Icon"
              style={{ width: '37px', height: '37px' }}
            />
          </Box>
          <Stack direction="column">
            <Typography
              variant="body1"
              sx={{
                textAlign: 'right',
                color: 'inherit',
                fontFamily: 'ExpoArabic',
                fontWeight: 'bold',
              }}
            >
              الرقم
            </Typography>
            <Typography
              variant="body1"
              component="a"
              href={`tel:${phoneNumber}`}
              sx={{
                textDecoration: 'none',
                color: 'inherit',
                direction: 'ltr',
                unicodeBidi: 'embed',
                textAlign: 'right',
                fontFamily: 'ExpoArabic',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {phoneNumber}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          justifyContent={{ xs: 'right', sm: 'center' }}
          spacing={2}
          sx={{ width: '100%', mb: 2, gap: 2 }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src="/assets/icons/mail-icon.png"
              alt="Mail Icon"
              style={{ width: '37px', height: '37px' }}
            />
          </Box>
          <Stack direction="column">
            <Typography
              variant="body1"
              sx={{
                textAlign: 'right',
                color: 'inherit',
                fontFamily: 'ExpoArabic',
                fontWeight: 'bold',
              }}
            >
              الإيميل
            </Typography>
            <Typography
              variant="body1"
              component="a"
              href={`mailto:${email}`}
              sx={{
                textDecoration: 'none',
                color: 'inherit',
                textAlign: 'right',
                fontFamily: 'ExpoArabic',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {email}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ width: '100%', mb: 2, gap: 2 }}
          justifyContent={{ xs: 'right', sm: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img
              src="/assets/icons/location-icon.png"
              alt="Location Icon"
              style={{ width: '37px', height: '37px' }}
            />
          </Box>
          <Stack direction="column">
            <Typography
              variant="body1"
              sx={{
                textAlign: 'right',
                color: 'inherit',
                fontFamily: 'ExpoArabic',
                fontWeight: 'bold',
              }}
            >
              العنوان
            </Typography>
            <Typography
              variant="body1"
              component={Link}
              href={wazeLink}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                color: 'inherit',
                textAlign: 'right',
                cursor: 'pointer',
                fontFamily: 'ExpoArabic',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              طَمرة - المنطقة الصناعية
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AcademyInfo;
