import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Lottie from "lottie-react";

const MySwal = withReactContent(Swal);

export const dynamicTitleSwal = ({ animationData, loadingMessages }) => {
  let currentMessageIndex = 0;
  let interval;

  const SwalWithDynamicTitle = MySwal.mixin({
    title: loadingMessages[currentMessageIndex],
    html: <Lottie animationData={animationData} height={50} width={50} />,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
      const titleElement = Swal.getTitle();
      titleElement.style.transition = "opacity 0.3s ease";
      titleElement.style.opacity = "1";
      titleElement.style.fontSize = "20px";

      interval = setInterval(() => {
        titleElement.style.opacity = "0";

        setTimeout(() => {
          currentMessageIndex =
            (currentMessageIndex + 1) % loadingMessages.length;
          titleElement.textContent = loadingMessages[currentMessageIndex];
          titleElement.style.opacity = "1";
        }, 300);
      }, 3000);
    },
    didClose: () => {
      clearInterval(interval);
    },
  });

  SwalWithDynamicTitle.fire();

  return {
    close: () => Swal.close(),
  };
};
