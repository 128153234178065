import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Box, Paper, Typography } from "@mui/material";
import ExamDetailModal from "./ExamDetails/ExamDetailModal";
import { useAuth } from "../../Contexts/AuthContext";
import { getExamScoresByUserId } from "../../Services/ExamService";
import ExamChartSkeleton from "../../loaders/ExamChartSkeleton";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Paper elevation={5} style={{ padding: 1.5, borderRadius: 5 }}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="body1" color="textPrimary">
            {`النتيجة: ${payload[0].value}`}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            انقر على النقطة لمشاهدة التفاصيل
          </Typography>
        </Box>
      </Paper>
    );
  }

  return null;
};

const ExamResultsChart = () => {
  const [examResults, setExamResults] = useState(null);
  const { user } = useAuth();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedExamId, setSelectedExamId] = useState(null);

  useEffect(() => {
    async function fetchResults() {
      try {
        setIsLoading(true);
        const results = await getExamScoresByUserId(user.id);

        const reversedResults = results.reverse();

        const numberedResults = reversedResults.map((result, index) => ({
          ...result,
          examNumber: `الامتحان ${index + 1}`,
        }));

        setExamResults(numberedResults);
      } catch (error) {
        console.error("Failed to fetch exam results:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchResults();
  }, [user.id]);

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleDotClick = (_, index) => {
    const examData = examResults[index.index];
    setSelectedExamId(examData.examId);
    setOpen(true);
  };

  if (isLoading) {
    return <ExamChartSkeleton />;
  }

  return (
    <Paper elevation={5} sx={{ borderRadius: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "340px",
          width: "100%",
          maxWidth: "100%",
          maxHeight: "350px",
          borderRadius: 1,
          margin: 2,
          padding: 0,
          marginBottom: "6rem",
          position: "relative",
        }}
      >
        <Typography variant="h6" align="center" gutterBottom>
          نتائج الامتحانات
        </Typography>

        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={examResults || []}
            margin={{ top: 5, right: 20, bottom: 35, left: 0 }}
          >
            <Line
              type="monotone"
              dataKey="score"
              stroke="#fc801e"
              activeDot={{
                r: 10,
                onClick: (data, index) => handleDotClick(data, index),
                cursor: "pointer",
              }}
            />

            <CartesianGrid stroke="rgba(42,58,81,0.4)" strokeDasharray="5 5" />
            <XAxis
              dataKey="examNumber"
              angle={-15}
              textAnchor="middle"
              stroke="rgb(67, 87, 117)"
            />

            <YAxis
              tick={{ dx: -20 }}
              stroke="rgb(67, 87, 117)"
              domain={[50, "auto"]}
            />
            <Tooltip content={<CustomTooltip />} />
          </LineChart>
        </ResponsiveContainer>

        {!examResults ||
          (examResults.length === 0 && (
            <Typography
              variant="body1"
              align="center"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              لا توجد نتائج متاحة حتى الآن.
            </Typography>
          ))}
      </Box>
      <ExamDetailModal
        open={open}
        handleClose={handleModalClose}
        selectedExamId={selectedExamId}
      />
    </Paper>
  );
};

export default ExamResultsChart;
