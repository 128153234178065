import { useState, useEffect } from "react";
import {
  getExamResultsById,
  getLastSubmittedExam,
} from "../Services/ExamService";
import { getQuestionsByIds } from "../Services/QuestionService";
import { standardizeScore } from "../Utilities/standardizeScore";

const useExamResults = (examId, userId) => {
  const [results, setResults] = useState([]);
  const [score, setScore] = useState(null);
  const [maxScore, setMaxScore] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentChapterPage, setCurrentChapterPage] = useState([]);

  const questionsPerPage = 8;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      let latestExam;
      try {
        latestExam = examId
          ? await getExamResultsById(examId, userId)
          : await getLastSubmittedExam(userId);
      } catch {
        console.error("Error fetching exam data.");
        setIsLoading(false);
        return;
      }

      if (
        !latestExam ||
        (!latestExam.questionsAnswers && !latestExam.submittedAnswers)
      ) {
        console.error("The exam does not have answers!");
        setIsLoading(false);
        return;
      }

      const allQuestionIds = latestExam.chapters.flatMap(
        (chapter) => chapter.questionIds
      );

      let questionsResponse;
      try {
        questionsResponse = await getQuestionsByIds(allQuestionIds, userId);
      } catch {
        console.error("Error fetching questions.");
        setIsLoading(false);
        return;
      }

      const resultData = latestExam.chapters.map((chapter) => {
        const chapterQuestions = chapter.questionIds.map((qId) => {
          const question = questionsResponse.find((q) => q.id === qId);
          const userAnswerObj = latestExam.questionsAnswers.find(
            (entry) => entry.questionId === qId
          );
          const userAnswer = userAnswerObj
            ? userAnswerObj.answerIndex
            : undefined;

          const isCorrect = question.correctAnswer === userAnswer;
          const yourChosenAnswer =
            question.choices[userAnswer] || "لم يتم اختيار اجابة";

          if (
            question.type === "READING_COMPREHENSION" &&
            question.associatedQuestions
          ) {
            const associatedData = question.associatedQuestions.map(
              (associatedQuestion) => {
                const assocAnswerObj = latestExam.questionsAnswers.find(
                  (entry) => entry.questionId === associatedQuestion.id
                );
                const assocUserAnswer = assocAnswerObj
                  ? assocAnswerObj.answerIndex
                  : undefined;
                const isAssocCorrect =
                  associatedQuestion.correctAnswer === assocUserAnswer;
                const yourAssociatedChosenAnswer =
                  associatedQuestion.choices[assocUserAnswer] ||
                  "لم يتم اختيار اجابة";

                return {
                  ...associatedQuestion,
                  correct: isAssocCorrect,
                  yourAnswer: yourAssociatedChosenAnswer,
                };
              }
            );

            return {
              ...question,
              correct: isCorrect,
              yourAnswer: yourChosenAnswer,
              associatedQuestions: associatedData,
            };
          }

          return {
            ...question,
            correct: isCorrect,
            yourAnswer: yourChosenAnswer,
          };
        });

        return {
          id: chapter.id,
          name: chapter.name,
          questionTypes: chapter.questionTypes,
          questions: chapterQuestions,
        };
      });

      const maxStandardScore = standardizeScore();

      setScore(latestExam.score);

      setMaxScore(maxStandardScore);
      setResults(resultData);
      setCurrentChapterPage(
        resultData.map((chap) =>
          Math.ceil(chap.questions.length / questionsPerPage)
        )
      );
      setIsLoading(false);
    };

    fetchData();
  }, [examId, userId]);

  return {
    results,
    score,
    maxScore,
    isLoading,
    currentChapterPage,
  };
};

export default useExamResults;
