import React from "react";
import {
  Button,
  Grid,
  Typography,
  Paper,
  lighten,
  Box,
  darken,
} from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import { Colors } from "../../Utilities/Colors";

const CompositionUpload = ({ handleSubmit, handleFileChange, file }) => {
  const handleButtonClick = () => {
    document.getElementById("contained-button-file").click();
  };
  return (
    <Paper
      elevation={5}
      sx={{
        padding: 3,
        borderRadius: 2,
        boxShadow: "0 4px 14px 0 rgba(0, 0, 0, 0.1)",
        backgroundColor: Colors.brightGray,
        maxWidth: "600px",
        margin: "0 auto",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          mb: 3,
          fontWeight: 600,
          color: Colors.darkBlue,
          borderBottom: `2px solid ${Colors.lightBlue}`,
          pb: 1,
        }}
        align="center"
      >
        قم بتحميل الانشاء
      </Typography>

      <Grid container spacing={3} alignItems="center" justifyContent="center">
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            hidden
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="contained"
              startIcon={<UploadFile sx={{ ml: 1, color: Colors.platinum }} />}
              sx={{
                backgroundColor: Colors.darkBlue,
                ":hover": { backgroundColor: darken(Colors.darkBlue, 0.1) },
                color: Colors.inputColor,
                my: 1,
              }}
              onClick={handleButtonClick}
            >
              اختر الملف
            </Button>
          </label>

          {file && (
            <Box
              sx={{
                display: "block",
                mt: 1,
                color: Colors.independence,
                textAlign: "center",
              }}
            >
              الملف المختار:
              <span sx={{ color: Colors.darkBlue, fontWeight: "bold" }}>
                {file.name}
              </span>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            disabled={!file}
            onClick={handleSubmit}
            sx={{
              backgroundColor: Colors.independence,
              ":hover": { backgroundColor: lighten(Colors.independence, 0.1) },
              borderRadius: 2,
              mt: 1,
              width: "100%",
            }}
          >
            تسليم الإنشاء
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompositionUpload;
