import React, { useState, useCallback, useEffect } from 'react';
import './ExamStart.scss';
import { useNavigate } from 'react-router-dom';
import { fetchSelectedChoices, submitExam } from '../../Services/ExamService';
import { Box, Typography, Grid, Button, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import Container from '@mui/material/Container';
import ProgressStepper from './ProgressStepper';
import useExamData from '../../Hooks/useExamData';
import QuestionRenderer from './QuestionRenderer';
import Loader from '../../loaders/loader.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Lottie from 'lottie-react';
import hourglass from '../../loaders/hourglass.json';
import PageHeader from './PageHeader';
import ErrorPage from '../ErrorPage/ErrorPage';
import { Colors } from '../../Utilities/Colors';

const MySwal = withReactContent(Swal);

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

const ExamStart = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { exam, questions, loading, setExam } = useExamData();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const [feedback, setFeedback] = useState({});

  useEffect(() => {
    if (exam && exam.submitted) {
      Swal.fire({
        icon: 'info',
        title: 'تم تقديم الامتحان بالفعل!',
        text: 'لا يمكن إعادة تقديم الامتحان .',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });

      navigate('/me');
    }
  }, [exam, navigate]);

  const [selectedChoices, setSelectedChoices] = useState(() => {
    const initialChoices = {};
    exam?.questionsAnswers.forEach((qa) => {
      initialChoices[qa.questionId] = qa.answerIndex;
    });
    return initialChoices;
  });

  const currentChapter = exam?.chapters[currentChapterIndex];
  const currentChapterQuestions = currentChapter
    ? currentChapter.questionIds
    : [];
  const currentQuestion = questions.find(
    (q) => q.id === currentChapterQuestions[currentQuestionIndex]
  );

  const [loadingMessage, setLoadingMessage] = useState('يتم إعداد الامتحان...');

  useEffect(() => {
    if (loading) {
      const loadingMessages = [
        'الامتحان جاهز، نجهز مقاعد الاستعداد!',
        'الأسئلة في الطريق، تتأهب للقفز إلى شاشتك!',
        'نراجع كل شيء مرة أخرى... لأن الكمال مطلوب!',
        'كل شيء جاهز!',
      ];

      let currentMessageIndex = 0;
      const messageInterval = setInterval(() => {
        currentMessageIndex += 1;
        if (currentMessageIndex >= loadingMessages.length) {
          currentMessageIndex = 0;
        }
        setLoadingMessage(loadingMessages[currentMessageIndex]);
      }, 3000);

      return () => clearInterval(messageInterval);
    }
  }, [loading]);

  useEffect(() => {
    const fetchChoices = async () => {
      try {
        if (exam) {
          const fetchedChoices = await fetchSelectedChoices(exam.id);

          setSelectedChoices(fetchedChoices);
        }
      } catch (error) {
        console.error('Failed to fetch selected choices:', error);
      }
    };

    fetchChoices();
  }, [exam]);

  const handleSubmit = useCallback(async () => {
    MySwal.fire({
      title: 'يتم تقييم الامتحان',
      html: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Lottie
            animationData={hourglass}
            style={{ width: '250px', height: '250px', margin: 'auto' }}
          />
        </div>
      ),
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });

    const formattedChoices = Object.keys(selectedChoices).map((key) => {
      return {
        questionId: parseInt(key, 10),
        answerIndex: selectedChoices[key],
      };
    });
    try {
      await submitExam(exam.id, formattedChoices);
      MySwal.close();

      Toast.fire({
        title: 'تم تقديم الامتحان بنجاح!',
        icon: 'success',
        iconColor: Colors.independence,
      });

      setExam({ ...exam, submitted: true });
      navigate(`/exam/end/`);
    } catch (error) {
      console.error(error);
      MySwal.close();
      Toast.fire({
        icon: 'error',
        title: 'فشل في تقديم الامتحان.',
        iconColor: Colors.brightOrange,
      });
    }
  }, [selectedChoices, setExam, exam, navigate]);

  const handleTimeUp = useCallback(() => {
    if (exam && currentChapterIndex < exam.chapters.length - 1) {
      setCurrentChapterIndex((prev) => prev + 1);
      setCurrentQuestionIndex(0);
    } else {
      handleSubmit();
    }
  }, [currentChapterIndex, exam, handleSubmit]);

  const loadingMessageVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 50 },
  };

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <img src={Loader} alt="Loading..." />
        <motion.div
          key={loadingMessage}
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={loadingMessageVariants}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
            }}
          >
            {loadingMessage}
          </Typography>
        </motion.div>
      </Box>
    );
  }

  if (!exam) {
    return (
      <ErrorPage
        errorCode="404"
        title="حدث خطأ!"
        message="نعتذر, لا يمكننا العثور على الامتحان المطلوب. قد يكون هناك مشكلة في النظام أو أن هناك شيء لم يكن صحيحًا. يرجى التحقق من الرابط الذي اتبعته أو الاتصال بالدعم إذا استمرت المشكلة."
      />
    );
  }
  if (!currentQuestion) {
    return (
      <ErrorPage
        errorCode="404"
        title="حدث خطأ!"
        message="نعتذر, لا يمكننا العثور على السؤال المطلوب في هذا الامتحان. قد يكون هناك مشكلة في النظام أو أن هناك شيء لم يكن صحيحًا. يرجى محاولة تحديث الصفحة أو الاتصال بالدعم إذا استمرت المشكلة."
      />
    );
  }

  const handleNext = () => {
    const currentChapter = exam.chapters[currentChapterIndex];
    const isLastQuestionOfChapter =
      currentQuestion.id ===
      currentChapter.questionIds[currentChapter.questionIds.length - 1];

    if (isLastQuestionOfChapter) {
      setCurrentChapterIndex((prev) => prev + 1);
      setCurrentQuestionIndex(0);
      return;
    }

    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrev = () => {
    const currentChapter = exam.chapters[currentChapterIndex];
    const isFirstQuestionOfChapter =
      currentQuestion.id === currentChapter.questionIds[0];

    if (isFirstQuestionOfChapter && currentChapterIndex !== 0) {
      Toast.fire({
        icon: 'info',
        title: 'لا يمكنك العودة إلى الفصل السابق.',
        iconColor: Colors.independence,
      });
      return;
    }

    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  return (
    <Container maxWidth="xl" disableGutters>
      <Box
        sx={{
          borderRadius: '15px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          padding: (theme) => theme.spacing(2),
          minHeight: '90vh',
        }}
        component={motion.div}
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -30 }}
      >
        <PageHeader
          isMobile={isMobile}
          exam={exam}
          currentChapterIndex={currentChapterIndex}
          handleTimeUp={handleTimeUp}
        />

        <Grid container>
          <Grid item xs={12}>
            <QuestionRenderer
              key={currentQuestion.id}
              currentQuestion={currentQuestion}
              selectedChoices={selectedChoices}
              setSelectedChoices={setSelectedChoices}
              examSubmitted={exam && exam.submitted}
              exam={exam}
              feedback={feedback}
              setFeedback={setFeedback}
            />
          </Grid>

          <Grid container sx={{ mt: 2 }} justifyContent="center">
            <Grid item xs={12} md={7}>
              <Grid container spacing={2} direction="column">
                <Grid item>
                  <ProgressStepper
                    steps={currentChapterQuestions.length}
                    currentStep={currentQuestionIndex}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    variant="progress"
                    isLastChapter={
                      currentChapterIndex === exam.chapters.length - 1
                    }
                    isFirstChapter={currentChapterIndex === 0}
                    nextLabel="السؤال التالي"
                    prevLabel="السؤال السابق"
                  />
                </Grid>

                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={6}>
                      <Button
                        fullWidth
                        variant="outlined"
                        onClick={handleSubmit}
                        sx={{
                          whiteSpace: 'nowrap',
                          py: 1,
                          width: '100%',
                          fontFamily: 'ExpoArabic',
                        }}
                        // disabled={
                        //   exam.submitted ||
                        //   currentChapterIndex !== exam.chapters.length - 1
                        // }
                      >
                        انهاء الامتحان
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ExamStart;
