import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import Swal from "sweetalert2";
import { createSubscriptionPlan } from "../../../Services/SubscriptionService";
import "./AddPlan.scss";
import PlanForm from "./PlanForm";
import CustomDialogActions from "./CustomDialogActions";
const AddPlan = ({ allPerks, fetchPlans, open, onClose }) => {
  const [plan, setPlan] = useState({
    name: "",
    duration: 0,
    price: 0,
    perkIds: [],
    role: "",
  });

  const handleChange = (e) => {
    setPlan({
      ...plan,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    try {
      await createSubscriptionPlan(plan);
      Swal.fire("نجاح", "تم إنشاء خطة الاشتراك بنجاح", "success");
      fetchPlans();
      handleClose();
    } catch (error) {
      console.error("فشل في إنشاء خطة الاشتراك", error);
      Swal.fire("خطأ", "فشل في إنشاء خطة الاشتراك", "error");
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>إضافة خطة جديدة</DialogTitle>
      <DialogContent>
        <PlanForm plan={plan} onChange={handleChange} allPerks={allPerks} />
      </DialogContent>
      <CustomDialogActions onCancel={handleClose} onConfirm={handleSubmit} />
    </Dialog>
  );
};

export default AddPlan;
