import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import Lottie from 'lottie-react';
import hourglassAnimation from '../../loaders/hourglass.json';
import SocialMediaAndAccount from './SocialMediaAndAccount';

const linkStyle = {
  textDecoration: 'none',
  color: 'inherit',
  display: 'block',
  textAlign: 'center',
};

const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

const DesktopNavBar = ({ isAuthenticated, logout, isAdmin, isLoggingOut }) => (
  <>
    {isLoggingOut && (
      <div style={overlayStyle}>
        <Lottie animationData={hourglassAnimation} />
        <p
          style={{
            color: '#fff',
            fontWeight: 'bold',
            fontSize: '1.5rem',
            marginTop: '20px',
          }}
        >
          جارٍ تسجيل الخروج، يرجى الانتظار...
        </p>
      </div>
    )}

    <Grid
      container
      spacing={1}
      alignItems="center"
      justifyContent="flex-start"
      sx={{ flexGrow: 1 }}
    >
      {[
        ['الرئيسية', '/'],
        ['التمرين', '/practice/selection'],
        ['معلومات ', '/information'],
        ['المساحة الشخصية', '/me'],
        ['المتجر', '/subscriptions/plans'],
        ['تواصل', '/contact'],
      ].map(([text, link], index) => (
        <Grid item key={index} sx={{ flexGrow: 1 }}>
          <Link to={link} style={linkStyle}>
            <Button
              color="inherit"
              sx={{
                fontSize: '16px',
                transition: 'all 0.3s',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.33)',
                },
                width: '100%',
                fontFamily: 'ExpoArabic',
              }}
            >
              {text}
            </Button>
          </Link>
        </Grid>
      ))}
      {isAdmin && (
        <Grid item sx={{ flexGrow: 1 }}>
          <Link to="/admin/dashboard" style={linkStyle}>
            <Button
              color="inherit"
              sx={{
                fontSize: '16px',
                fontFamily: 'ExpoArabic',
                transition: 'all 0.3s',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.33)',
                },
                width: '100%',
              }}
            >
              لوحة الإدارة
            </Button>
          </Link>
        </Grid>
      )}
      {isAuthenticated ? (
        <Grid item sx={{ flexGrow: 1 }}>
          <Button
            color="inherit"
            onClick={logout}
            fullWidth
            sx={{
              fontSize: '16px',
              fontFamily: 'ExpoArabic',
              transition: 'all 0.3s',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.33)',
              },
              width: '100%',
            }}
          >
            تسجيل الخروج
          </Button>
        </Grid>
      ) : (
        <Grid item sx={{ flexGrow: 1 }}>
          <Link to="/auth/login" style={linkStyle}>
            <Button
              color="inherit"
              fullWidth
              sx={{
                fontSize: '16px',
                fontFamily: 'ExpoArabic',
                transition: 'all 0.3s',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.33)',
                },
                width: '100%',
              }}
            >
              تسجيل الدخول
            </Button>
          </Link>
        </Grid>
      )}
      <Grid item sx={{ flexGrow: 1 }} />
      <Grid item sx={{ flexGrow: 0 }}>
        <SocialMediaAndAccount />
      </Grid>
    </Grid>
  </>
);

export default DesktopNavBar;
