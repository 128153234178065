import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Grid,
} from '@mui/material';
import Swal from 'sweetalert2';
import { deleteSwal } from '../../Utilities/deleteSwal';

const EditUser = ({ open, onClose, userInfo, onUpdate }) => {
  const [details, setDetails] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  });

  useEffect(() => {
    if (userInfo) {
      setDetails({
        firstName: userInfo.firstName || '',
        lastName: userInfo.lastName || '',
        phoneNumber: userInfo.phoneNumber || '',
        email: userInfo.email || '',
      });
    }
  }, [userInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      if (
        !details.firstName ||
        !details.lastName ||
        !details.phoneNumber ||
        !details.email
      ) {
        Swal.fire('خطأ', 'يرجى ملء جميع الحقول المطلوبة', 'error');
        return;
      }

      await deleteSwal({
        onConfirm: () => onUpdate(userInfo.id, details),
        onSuccessDescription: 'تم تحديث تفاصيل المستخدم بنجاح.',
        onErrorDescription: 'فشل في تحديث تفاصيل المستخدم.',
      }).then(() => {
        onClose();
      });
    } catch (err) {
      Swal.fire('خطأ', err.message || 'فشل في تحديث تفاصيل المستخدم', 'error');
    }
  };

  return (
    <>
      <style>
        {`
        .swal2-container {
          z-index: 2000 !important;
        }
      `}
      </style>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>تغيير التفاصيل</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="normal"
                label="الاسم الأول"
                name="firstName"
                value={details.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="normal"
                label="اسم العائلة"
                name="lastName"
                value={details.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="normal"
                label="رقم الهاتف"
                name="phoneNumber"
                value={details.phoneNumber}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                margin="normal"
                label="عنوان البريد الإلكتروني"
                name="email"
                value={details.email}
                disabled
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            إلغاء
          </Button>
          <Button onClick={handleSave} color="primary">
            حفظ
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditUser;
