import React, { useState } from 'react';
import {
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  Divider,
} from '@mui/material';

import { StyledTextarea } from './Inputs/StyledTextarea';
import AddIcon from '@mui/icons-material/Add';
import AssociatedQuestionsList from './ComprehensiveQuestion/AssociatedQuestionsList';
import QuestionTypeSelect from './Inputs/QuestionTypeSelect';
import DifficultySelect from './Inputs/DifficultySelect';

const AddQuestionDialog = ({
  question,
  handleChange,
  associatedQuestions,
  handleSideDialogOpen,
  removeAssociatedQuestion,
}) => {
  const [choicesInputs, setChoicesInputs] = useState(question.choices);
  const isSpecialType = (type) =>
    type === 'READING_COMPREHENSION' || type === 'COMPOSITION';

  const handleChoiceChange = (index, value) => {
    const newChoices = [...choicesInputs];
    newChoices[index] = value;
    setChoicesInputs(newChoices);
    handleChange({
      target: {
        name: 'choices',
        value: newChoices,
      },
    });
  };

  return (
    <Paper elevation={3} sx={{ padding: '2rem', marginBottom: '2rem' }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={8}>
          <QuestionTypeSelect
            value={question.type}
            handleChange={handleChange}
            restrict={false}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <StyledTextarea
              id="text"
              name="text"
              minRows={3}
              maxRows={10}
              value={question.text}
              onChange={handleChange}
              placeholder="نص السؤال"
              aria-label="نص السؤال"
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            mt: 1,
            mb: 1,
          }}
        >
          <Divider />
        </Grid>

        {!isSpecialType(question.type) && (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            item
          >
            {choicesInputs.map((choice, index) => (
              <Grid item xs={6} key={index}>
                <TextField
                  label={`الخيار ${index + 1}`}
                  variant="outlined"
                  value={choice}
                  onChange={(e) => handleChoiceChange(index, e.target.value)}
                  style={{
                    width: '100%',
                    backgroundColor: '#fafafa',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {!isSpecialType(question.type) && (
          <Grid item xs={8}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>الإجابة الصحيحة</InputLabel>
              <Select
                value={question.correctAnswer}
                onChange={handleChange}
                label="الإجابة الصحيحة"
                name="correctAnswer"
                sx={{
                  backgroundColor: '#fafafa',
                }}
              >
                {question.choices.map((choice, index) => (
                  <MenuItem key={index} value={index}>
                    {choice}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {question.type !== 'COMPOSITION' && (
          <Grid item xs={question.type === 'READING_COMPREHENSION' ? 5 : 4}>
            <DifficultySelect
              value={question.difficultyLevel}
              onChange={handleChange}
            />
          </Grid>
        )}
        {question.type === 'READING_COMPREHENSION' && (
          <>
            <Grid
              item
              xs={7}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                onClick={handleSideDialogOpen}
                startIcon={
                  <AddIcon sx={{ ml: 1, transition: 'all 0.3s ease-in-out' }} />
                }
                variant="outlined"
                sx={{
                  padding: '1rem 1rem',
                  backgroundColor: '#fafafa',
                  color: '#388d3f',
                  borderColor: 'green',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    borderColor: 'green',
                  },
                }}
              >
                أضف أسئلة مرتبطة
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="right">
                الأسئلة المرتبطة المضافة ⮮
              </Typography>
              <AssociatedQuestionsList
                associatedQuestions={associatedQuestions}
                removeAssociatedQuestion={removeAssociatedQuestion}
              />
            </Grid>
          </>
        )}
        {!isSpecialType(question.type) && (
          <>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <StyledTextarea
                  id="explanation"
                  name="explanation"
                  minRows={2}
                  maxRows={10}
                  value={question.explanation}
                  onChange={handleChange}
                  placeholder="الشرح"
                  aria-label="الشرح"
                />
              </FormControl>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default AddQuestionDialog;
