import React from "react";
import { DialogActions, Button } from "@mui/material";
import { Colors } from "../../../Utilities/Colors";

const CustomDialogActions = ({ onCancel, onConfirm }) => (
  <DialogActions
    sx={{
      justifyContent: "space-between",
      backgroundColor: Colors.inputColor,
      padding: "0.5rem 1rem",
      borderRadius: "8px",
    }}
  >
    <Button
      onClick={onCancel}
      sx={{
        color: Colors.brightOrange,
        borderColor: Colors.brightOrange,
        "&:hover": {
          backgroundColor: Colors.brightOrange,
          color: Colors.inputColor,
        },
      }}
      variant="outlined"
    >
      إلغاء
    </Button>
    <Button
      onClick={onConfirm}
      sx={{
        color: Colors.lightBlue,
        borderColor: Colors.mediumBlue,
        backgroundColor: Colors.mediumBlue,
        "&:hover": {
          backgroundColor: Colors.darkBlue,
          color: Colors.inputColor,
        },
      }}
      variant="contained"
    >
      حفظ
    </Button>
  </DialogActions>
);
export default CustomDialogActions;
