import React, { memo, useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Divider,
  Grid,
  Container,
  Stack,
} from "@mui/material";
import Lottie from "lottie-react";
import gear from "../../../loaders/fetchAnimation.json";

import { getDetailedExamResults } from "../../../Services/ExamService";
import PerformanceMetrics from "./PerformanceMetrics";

import { Colors } from "../../../Utilities/Colors";
import { format } from "date-fns";
const ExamDetailModal = ({ open, handleClose, selectedExamId }) => {
  const [examDetails, setExamDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!selectedExamId) return;

    async function fetchDetails() {
      setIsLoading(true);
      try {
        const details = await getDetailedExamResults(selectedExamId);
        setExamDetails(details);
      } catch (error) {
        console.error("Failed to fetch exam details:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchDetails();
  }, [selectedExamId]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            bgcolor: Colors.platinum,
            p: 4,
            borderRadius: 2,
            height: "80vh",
            width: "100%",
            overflowY: "auto",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                zIndex: 5,
              }}
            >
              <Lottie
                animationData={gear}
                style={{
                  width: "80px",
                  height: "80px",
                  marginRight: "1rem",
                }}
              />
              <Typography
                variant="h6"
                align="center"
                sx={{
                  color: "#ffffff",
                }}
              >
                جارٍ جلب تفاصيل الامتحان...
              </Typography>
            </Box>
          ) : examDetails ? (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={{ xs: 1, sm: 3 }}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ width: "100%" }}
                      useFlexGap
                      flexWrap="wrap"
                    >
                      <Box textAlign="center" sx={{ flex: "1" }}>
                        <Typography
                          variant="subtitle2"
                          color="textPrimary"
                          fontWeight="bold"
                        >
                          {examDetails.chaptersInfo.length > 0
                            ? format(
                                new Date(examDetails.chaptersInfo[0].startTime),
                                "HH:mm - yyyy-MM-dd"
                              )
                            : ""}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          بداية الاختبار
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>

                  <Grid item xs={4}>
                    <Typography
                      id="modal-modal-title"
                      variant="h5"
                      sx={{
                        textAlign: "center",
                        mb: 2,
                        color: Colors.darkBlue,
                      }}
                    >
                      تفاصيل الاختبار
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={{ xs: 1, sm: 3 }}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ width: "100%" }}
                      useFlexGap
                      flexWrap="wrap"
                    >
                      <Box textAlign="center" sx={{ flex: "1" }}>
                        <Typography
                          variant="subtitle2"
                          color="textPrimary"
                          fontWeight="bold"
                        >
                          {examDetails.score}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          النتيجة الكلية
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                </Grid>
                <Divider sx={{ borderColor: Colors.brightGray, mt: 2 }} />
              </Grid>
              {examDetails.chaptersInfo.map((chapter, _) => (
                <Grid item xs={12} key={chapter.id}>
                  <Box
                    sx={{
                      mt: 2,
                      p: 2,
                      bgcolor: Colors.inputColor,
                      borderRadius: 2,
                      boxShadow: `0 2px 4px 0 ${Colors.brightGray}`,
                    }}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <Typography
                          variant="h6"
                          color="textPrimary"
                          fontWeight="bold"
                          textAlign="center"
                        >
                          {chapter.name}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack
                          direction="row"
                          divider={<Divider orientation="vertical" flexItem />}
                          spacing={{ xs: 1, sm: 3 }}
                          alignItems="center"
                          justifyContent="center"
                          sx={{ width: "100%" }}
                          useFlexGap
                          flexWrap="wrap"
                        >
                          <Box textAlign="center" sx={{ flex: "1" }}>
                            <Typography
                              variant="h6"
                              color="textPrimary"
                              fontWeight="bold"
                              sx={{
                                color: Colors.darkBlue,
                              }}
                            >
                              {chapter.score}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              علامة الفصل
                            </Typography>
                          </Box>
                        </Stack>
                      </Grid>

                      <Grid container spacing={2} mt={2}>
                        <Grid item xs={12}>
                          <PerformanceMetrics
                            data={chapter.performanceDetails}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>Error loading details. Please try again.</Typography>
          )}
        </Box>
      </Container>
    </Modal>
  );
};

export default memo(ExamDetailModal);
