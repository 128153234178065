import React from "react";
import {
  List,
  ListItem,
  Paper,
  Card,
  CardContent,
  Grid,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";

const AssociatedQuestionsList = ({
  associatedQuestions,
  removeAssociatedQuestion,
}) => {
  if (!associatedQuestions || associatedQuestions.length === 0) {
    return (
      <Typography
        align="center"
        variant="body1"
        color="textSecondary"
        sx={{ mt: 2 }}
      >
        لا توجد أسئلة مرتبطة حتى الآن.
      </Typography>
    );
  }
  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      disablePadding
    >
      {associatedQuestions.map((q, idx) => (
        <ListItem key={idx}>
          <Paper elevation={3} sx={{ width: "100%" }}>
            <Card
              sx={{
                width: "100%",
                backgroundColor: "#fafafa",
                borderRadius: 2,
              }}
            >
              <CardContent>
                <Grid container alignItems="center" flexDirection="row-reverse">
                  <Grid item>
                    <Tooltip
                      title={
                        <>
                          <Typography color="inherit" align="right">
                            Choices:
                          </Typography>
                          <ul style={{ textAlign: "right" }}>
                            {q.choices.map((choice, i) => (
                              <li key={i}>{choice}</li>
                            ))}
                          </ul>
                          <Typography color="inherit" align="right">
                            Correct Answer: {q.choices[q.correctAnswer]}
                          </Typography>
                          <Typography color="inherit" align="right">
                            Explanation: {q.explanation}
                          </Typography>
                        </>
                      }
                      arrow
                    >
                      <IconButton size="small">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      size="small"
                      onClick={() => removeAssociatedQuestion(idx)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2" align="right">
                      {idx + 1}. {q.text}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </ListItem>
      ))}
    </List>
  );
};

export default AssociatedQuestionsList;
