import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Paper,
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
  Fade,
  Tooltip,
  Collapse,
  Alert,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import { useLocation } from "react-router-dom";
import OverlayPaper from "../../Utilities/OverlayPaper";
import IframeModal from "./IframeModal";
import { useAuth } from "../../Contexts/AuthContext";

const Subscribe = () => {
  const location = useLocation();
  const { plan } = location.state || {};
  const { user } = useAuth();
  const [iframeURL, setIframeURL] = useState("");
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    if (plan) {
      const baseIframeURL = process.env.REACT_APP_IFRAME_BASE_URL;

      const purchaseData = {
        product_name: plan.name,
        product_quantity: 1,
        product_price: plan.price,
      };
      const params = new URLSearchParams({
        user_id: user.id,
        product_id: plan.id,
        sum: plan.price,
        currency: 1,
        cred_type: 1,
        json_purchase_data: JSON.stringify([purchaseData]),
      });

      setIframeURL(`${baseIframeURL}?${params.toString()}`);
    }
  }, [plan, user.id]);

  if (!plan) return <div>لم يتم اختيار خطة!</div>;

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Fade in={true} timeout={500}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              item
              xs={12}
              sx={{
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                onClick={() => window.history.back()}
                sx={{
                  position: "absolute",
                  right: 0,
                  rotate: `180deg`,
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography
                variant="h5"
                gutterBottom
                align="center"
                sx={{
                  textAlign: "center",
                }}
              >
                صفحة الاشتراك
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <OverlayPaper>
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                >
                  <Grid item>
                    <Stack
                      direction="column"
                      alignItems="center"
                      spacing={1}
                      sx={{ textAlign: "center" }}
                    >
                      <Typography
                        variant="h5"
                        color="textPrimary"
                        fontWeight="bold"
                        sx={{ color: "#DADADC" }}
                      >
                        {plan.name}
                      </Typography>
                      <Typography variant="caption" sx={{ color: "#EAEAEC" }}>
                        الخطة المختارة
                      </Typography>
                    </Stack>
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    sx={{ mb: 2 }}
                  >
                    <Grid item xs={6}>
                      <Stack
                        direction="column"
                        alignItems="center"
                        spacing={1}
                        sx={{ textAlign: "center" }}
                      >
                        <Typography
                          variant="subtitle1"
                          color="textPrimary"
                          fontWeight="bold"
                          sx={{ color: "#DADADC" }}
                        >
                          {plan.duration} يوماً
                        </Typography>
                        <Typography variant="caption" sx={{ color: "#EAEAEC" }}>
                          مدة الاشتراك
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack
                        direction="column"
                        alignItems="center"
                        spacing={1}
                        sx={{ textAlign: "center" }}
                      >
                        <Typography
                          variant="subtitle1"
                          color="textPrimary"
                          fontWeight="bold"
                          sx={{ color: "#DADADC" }}
                        >
                          {plan.price} ₪
                        </Typography>
                        <Typography variant="caption" sx={{ color: "#EAEAEC" }}>
                          السعر
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setShowIframe(true)}
                  >
                    اشترك الآن
                  </Button>
                </Grid>
              </OverlayPaper>
            </Grid>

            {plan.perks && plan.perks.length > 0 && (
              <Grid item xs={12}>
                <Collapse in={true} timeout="auto" unmountOnExit>
                  <Box sx={{ mt: 3, mb: 5 }}>
                    <Typography variant="h6" gutterBottom>
                      المزايا المرافقة للخطة
                    </Typography>
                    <List>
                      {plan.perks.map((perk) => (
                        <ListItem
                          key={perk.id}
                          secondaryAction={
                            <Tooltip title="معلومات إضافية" arrow>
                              <IconButton edge="end">
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          }
                        >
                          <ListItemText
                            align="right"
                            primary={perk.name}
                            secondary={perk.description}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Collapse>
              </Grid>
            )}

            <IframeModal
              open={showIframe}
              onClose={() => setShowIframe(false)}
              iframeURL={iframeURL}
            />

            <Grid item xs={12}>
              <Alert
                severity="info"
                variant="outlined"
                sx={{
                  "& .MuiAlert-icon": {
                    marginLeft: "0.5rem",
                    color: "#FD8F24",
                  },
                  borderColor: "#435775",
                }}
              >
                سيتم توجيهك إلى بوابة الدفع بمجرد الضغط على "اشترك الآن"
              </Alert>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Container>
  );
};

export default Subscribe;
