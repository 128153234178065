import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import ErrorPage from "../ErrorPage/ErrorPage";
import AuthLoader from "./AuthLoader";

function ProtectedRoute({ children, roles, redirectPath }) {
  const {
    isAuthenticated,
    loading,
    user,
    error,
    authError,
    checkAuthentication,
    statusMessage,
  } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      checkAuthentication();
    }
  }, [isAuthenticated, checkAuthentication]);
  if (error) {
    return (
      <ErrorPage
        errorCode="503"
        title="عفوًا، هناك مشكلة في الاتصال بالخادم"
        message="يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بالدعم الفني إذا استمرت المشكلة."
      />
    );
  }

  if (loading || !user) {
    return <AuthLoader statusMessage={statusMessage} />;
  }

  if (!isAuthenticated || authError) {
    return <Navigate to="/auth/login" replace />;
  }
  if (roles && (!user || !roles.includes(user.role))) {
    return <Navigate to={redirectPath || "/subscriptions/plans"} replace />;
  }

  return children;
}

export default ProtectedRoute;
