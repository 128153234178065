import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {
  getAllPlansForAdmin,
  getAllPerks,
  getPerkById,
} from "../../../Services/SubscriptionService";
import AddPlan from "./AddPlan";
import Plans from "./Plans";
import AddPerk from "./AddPerk";
import Perks from "./Perks";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";

const SubscriptionsManagement = () => {
  const [plans, setPlans] = useState([]);
  const [allPerks, setAllPerks] = useState([]);
  const [speedDialOpen, setSpeedDialOpen] = useState(false);
  const [openPlanDialog, setOpenPlanDialog] = useState(false);
  const [openPerkDialog, setOpenPerkDialog] = useState(false);

  useEffect(() => {
    fetchPlans();
    fetchPerks();
  }, []);

  const fetchPerks = async () => {
    try {
      const fetchedPerks = await getAllPerks();
      setAllPerks(fetchedPerks);
    } catch (error) {
      console.error("Failed to fetch perks:", error.message);
    }
  };
  const fetchPlans = async () => {
    try {
      const fetchedPlans = await getAllPlansForAdmin();
      const plansWithPerks = await Promise.all(
        fetchedPlans.map(async (plan) => {
          const perks = await Promise.all(plan.perkIds.map(getPerkById));
          return { ...plan, perks };
        })
      );
      setPlans(plansWithPerks);
    } catch (error) {
      console.error("Failed to fetch subscription plans:", error.message);
    }
  };

  const handleOpen = () => {
    setSpeedDialOpen(true);
  };

  const handleClose = () => {
    setSpeedDialOpen(false);
  };

  const actions = [
    {
      icon: (
        <AddIcon
          sx={{
            color: "#23314B;",
          }}
        />
      ),
      name: "إضافة خطة",
      onClick: () => setOpenPlanDialog(true),
    },
    {
      icon: (
        <AddIcon
          sx={{
            color: "#2B5AAA;",
          }}
        />
      ),
      name: "إضافة ميزة",
      onClick: () => setOpenPerkDialog(true),
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ padding: "2rem" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
            إدارة الخطط و المزايا
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            الخطط المتاحة
          </Typography>
          <Plans plans={plans} allPerks={allPerks} fetchPlans={fetchPlans} />
          <AddPlan
            allPerks={allPerks}
            fetchPlans={fetchPlans}
            open={openPlanDialog}
            onClose={() => setOpenPlanDialog(false)}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            المزايا المتاحة
          </Typography>
          <Perks allPerks={allPerks} fetchPerks={fetchPerks} />
          <AddPerk
            fetchPerks={fetchPerks}
            open={openPerkDialog}
            onClose={() => setOpenPerkDialog(false)}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ position: "fixed", bottom: 16, right: 16 }}>
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              sx={{ position: "absolute", bottom: 16, right: 16 }}
              icon={<SpeedDialIcon />}
              onClose={handleClose}
              onOpen={handleOpen}
              open={speedDialOpen}
              direction="up"
              FabProps={{
                sx: {
                  bgcolor: "#fe8826",
                  "&:hover": {
                    bgcolor: "#ff9f4f",
                  },
                },
              }}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={action.onClick}
                />
              ))}
            </SpeedDial>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubscriptionsManagement;
