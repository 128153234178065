import { useEffect } from "react";

const TransactionSuccess = () => {
  useEffect(() => {
    window.parent.postMessage("transactionFail", "*");
  }, []);

  return null;
};

export default TransactionSuccess;
