import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import Lottie from "lottie-react";
import fetchAnimation from "../../loaders/fetchAnimation.json";
import ScoreCountUp from "./ScoreCountUp";
import { Colors } from "../../Utilities/Colors";

const ExamScoreHeader = ({ isLoading, score, maxScore }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loadingMessage, setLoadingMessage] = useState("جارِ التحميل...");

  useEffect(() => {
    if (isLoading) {
      const loadingMessages = [
        "جارِ التحميل...",
        "جارِ إحضار النتائج...",
        "فضلاً انتظر، قاربنا على الانتهاء...",
      ];

      let currentMessageIndex = 0;
      const messageInterval = setInterval(() => {
        currentMessageIndex += 1;
        if (currentMessageIndex >= loadingMessages.length) {
          currentMessageIndex = 0;
        }
        setLoadingMessage(loadingMessages[currentMessageIndex]);
      }, 3000);

      return () => clearInterval(messageInterval);
    }
  }, [isLoading]);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={12}>
        <Box>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              textAlign: "center",
              color: Colors.darkBlue,
              fontWeight: "bold",
            }}
          >
            نتائج الاختبار
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: isMobile ? "60" : "80px" }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={isMobile ? 180 : 240}
            height={isMobile ? 50 : 70}
            borderRadius="5px"
            sx={{
              border: (t) => `2px solid ${Colors.mediumBlue}`,
              backgroundColor: Colors.inputColor,
              boxShadow: (t) => `0 0 15px 0 ${t.palette.primary.main}`,
              mb: 3,
              position: "relative",
            }}
          >
            {isLoading ? (
              <>
                <Lottie
                  animationData={fetchAnimation}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "30%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
                <Typography
                  variant={isMobile ? "caption" : "subtitle2"}
                  component="div"
                  sx={{
                    color: Colors.darkBlue,
                    fontWeight: "bold",
                    position: "absolute",
                    top: "80%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {loadingMessage}
                </Typography>
              </>
            ) : (
              <Typography
                variant={isMobile ? "h4" : "h3"}
                component="div"
                sx={{
                  color: Colors.darkBlue,
                  fontWeight: "bold",
                }}
              >
                {maxScore} / <ScoreCountUp end={score} />
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ExamScoreHeader;
