import React from 'react';
import { Container, Box, Grid, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import Hero from './Hero';
import Features from './Features';
import StudentsGrades from './StudentsGrades';
import Testimonials from './Testimonials';
import './Home.scss';
import { useTheme } from '@emotion/react';

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Helmet>
        <title>الصفحة الرئيسية - אל-יע״ל</title>
        <meta
          name="description"
          content="اكتشف مجموعة واسعة من الموارد التعليمية لمساعدتك في تحقيق النجاح في امتحاناتك. تصفح شهادات المتعلمين وتعرف على المميزات الحصرية."
        />
        <meta
          name="keywords"
          content="دورات تعليمية, موارد تعليمية, امتحانات عملية, شهادات, تحليل الأداء, مراجعات, حلول تفصيلية"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: 'الصفحة الرئيسية - אל-יע״ל',
            description:
              'الصفحة الرئيسية لموقعنا توفر معلومات حول المميزات للموقع والموارد التعليمية وشهادات المتعلمين. اكتشف كيف يمكننا مساعدتك في تحقيق أعلى الدرجات.',
            inLanguage: 'ar',
            url: 'http://elyael.com/',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'http://elyael.com/',
            },
            image: {
              '@type': 'ImageObject',
              url: 'https://elyael.com/assets/yael-logo.jpg',
            },
            publisher: {
              '@type': 'Organization',
              name: 'אל-יע״ל',
              logo: {
                '@type': 'ImageObject',
                url: 'https://elyael.com/assets/logo.jpg',
              },
            },
          })}
        </script>
      </Helmet>
      <Container maxWidth={false} component="main" disableGutters>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <motion.section initial="initial" animate="animate">
            <Grid container spacing={0} direction="column">
              {/* Hero Section */}
              <Grid
                item
                xs={12}
                component="article"
                id="hero"
                sx={{
                  position: 'relative',
                  backgroundImage: {
                    xs: `url('${
                      isMobile
                        ? '/assets/hero-bg-mobile.png'
                        : '/assets/hero-bg.png'
                    }')`,
                    sm: `url('/assets/hero-bg.png')`,
                  },
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: {
                    xs: 'center left -150px',
                    sm: 'center',
                  },
                  minHeight: `${isMobile ? '300px' : '550px'}`,
                  mb: 3,
                }}
              >
                <Hero />
              </Grid>

              {/* Features Section */}
              <Grid item xs={12} component="section" id="features">
                <Features />
              </Grid>

              {/* Testimonials Section */}
              <Grid
                item
                xs={12}
                component="section"
                id="testimonials"
                sx={{
                  position: 'relative',
                  backgroundImage: `url('/assets/testimonials-bg.jpg')`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  minHeight: `${isMobile ? '300px' : '400px'}`,
                  width: '100%',
                }}
              >
                <Testimonials />
              </Grid>

              {/* StudentsGrades Section */}
              <Grid
                item
                xs={12}
                sx={{
                  width: '100%',
                }}
                component="section"
                id="testimonials"
              >
                <StudentsGrades />
              </Grid>
            </Grid>
          </motion.section>
        </Box>
      </Container>
    </>
  );
};

export default Home;
