import { axiosInstance, apiWrapper } from "./Service";

export async function createExam(examData, isRealExam = false) {
  const response = await apiWrapper(
    () => axiosInstance.post(`/exams?isRealExam=${isRealExam}`, examData),
    "Exam created successfully",
    "Failed to create exam"
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getExamByUserId(userId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/exams/${userId}`),
    `Exam with user ID ${userId} retrieved successfully`,
    `Failed to get exam with user ID ${userId}`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getExamResultsById(examId, userId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/exams/${examId}/results`, { params: { userId } }),
    `Exam with ID ${examId} retrieved successfully`,
    `Failed to get exam with ID ${examId} scores`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function submitExam(examId, answers) {
  const response = await apiWrapper(
    () => axiosInstance.post(`/exams/${examId}/submit`, answers),
    "Exam submitted successfully",
    "Failed to submit exam"
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getExamsInfo(userId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/exams/${userId}/user`),
    `Recent exams for user ID ${userId} retrieved successfully`,
    `Failed to get recent exams for user ID ${userId}`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getExamScoresByUserId(userId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/exams/${userId}/scores`),
    `Exam scores for user ID ${userId} retrieved successfully`,
    `Failed to get exam scores for user ID ${userId}`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getLastSubmittedExam(userId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/exams/${userId}/latest`),
    `Latest exam for user ID ${userId} retrieved successfully`,
    `Failed to get the latest exam for user ID ${userId}`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }
  return response.data;
}

export async function getDetailedExamResults(examId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/exams/${examId}/detailed`),
    `Detailed exam results for exam ID ${examId} retrieved successfully`,
    `Failed to get detailed exam results for exam ID ${examId}`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function saveChoice(examId, questionId, choice) {
  const response = await apiWrapper(
    () =>
      axiosInstance.post(`/exams/${examId}/questions/${questionId}/choice`, {
        choice,
      }),
    "Choice recorded successfully",
    "Failed to record choice"
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function fetchSelectedChoices(examId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/exams/${examId}/choices`),
    "Choices fetched successfully",
    "Failed to fetch choices"
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}
