import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QuestionTypesContext } from "./Contexts/QuestionTypesContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ExamProvider } from "./Contexts/ExamContext";
import "./index.scss";
import ErrorBoundary from "./Utilities/ErrorBoundary";
import { HelmetProvider } from "react-helmet-async";
import { Colors } from "./Utilities/Colors";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.independence,
    },
    background: {
      default: "#F4F4F4",
    },
  },
  typography: {
    fontFamily: "'Noto Sans Arabic', 'IBM Plex Sans Hebrew', sans-serif",
  },
  direction: "rtl",
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          left: "inherit",
          right: "1.75rem",
          transformOrigin: "right",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          textAlign: "right",
        },
        input: {
          textAlign: "right",
        },
      },
    },
    MuiTextareaAutosize: {
      styleOverrides: {
        root: {
          textAlign: "right",
          direction: "rtl",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          textAlign: "right",
          direction: "rtl",
        },
        input: {
          textAlign: "right",
          direction: "rtl",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          right: "unset",
          left: "7px",
        },
      },
    },
  },
});

export default theme;

const questionTypes = [
  { id: "LINKING_TOOLS", label: "أدوات الربط" },
  { id: "PREPOSITIONS", label: "حروف الجر" },
  { id: "PRONOUN_TIME_COMPATIBILITY", label: "ملائمة الضمير مع الزمن" },
  { id: "ADJECTIVE_NOUN_COMPATIBILITY", label: "ملائمة الصفة مع الموصوف" },
  { id: "LINGUISTIC_RICHNESS", label: "الثروة اللغوية" },
  { id: "SENTENCE_REPHRASING", label: "إعادة صياغة الجمل" },
  { id: "READING_COMPREHENSION", label: "فهم المقروء" },
];

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ExamProvider>
      <QuestionTypesContext.Provider value={questionTypes}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </QuestionTypesContext.Provider>
    </ExamProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();

reportWebVitals();
