import { createContext, useContext, useState, useCallback } from "react";
import {
  logoutUser,
  getUserInformation,
  loginUser,
  verifyTwoFACode,
} from "../Services/UserService";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Colors } from "../Utilities/Colors";

const initialAuthContextValue = {
  isAuthenticated: false,
  login: async () => {},
  user: null,
  logout: () => {},
  loading: true,
  statusMessage: "",
};

export const AuthContext = createContext(initialAuthContextValue);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const navigate = useNavigate();

  const [authState, setAuthState] = useState({
    user: null,
    isAuthenticated: false,
    loading: false,
    error: null,
    authError: null,
    isLoggingOut: false,
    statusMessage: "",
  });

  const setStatusMessage = (message) => {
    setAuthState((prevState) => ({ ...prevState, statusMessage: message }));
  };

  const checkAuthentication = useCallback(async () => {
    try {
      setStatusMessage("جارٌ التحقق من بيانات الدخول...");
      const userData = await getUserInformation();
      setAuthState({
        user: userData,
        isAuthenticated: true,
        loading: false,
      });
      setStatusMessage("");
    } catch (error) {
      console.error("Authentication error:", error.message);
      setAuthState({
        user: null,
        isAuthenticated: false,
        loading: false,
        authError: error.message,
      });
      setStatusMessage(
        "حدث خطأ أثناء محاولة التحقق من بيانات الدخول، يرجى المحاولة مجدداً."
      );
      if (
        error.message === "Refresh token is invalid or expired" ||
        error.message === "Unauthorized"
      ) {
        navigate("/auth/login");
      }
    }
  }, [navigate]);

  const login = async (email, password) => {
    try {
      setStatusMessage("جار تسجيل الدخول...");

      // First step: Attempt to log in the user
      const response = await loginUser(email, password);
      // Second step: Check if 2FA is required
      if (response && response.requires2FA) {
        const { value: twoFACode } = await Swal.fire({
          title: "أدخل رمز المصادقة الثنائية (2FA) الخاص بك",
          input: "text",
          inputLabel: "الرمز",
          inputPlaceholder: "أدخل رمز المصادقة الثنائية هنا",
          confirmButtonText: "موافق",
          confirmButtonColor: Colors.darkBlue,
        });

        if (twoFACode) {
          // Verify the 2FA code by calling the appropriate service function
          await verifyTwoFACode(twoFACode, email);
        } else {
          throw new Error("مطلوب رمز 2FA لتسجيل الدخول.");
        }
      }

      // If login was successful and 2FA was either not required or successfully verified, check authentication status
      await checkAuthentication();
    } catch (error) {
      console.error("Login error:", error.message);
      throw error; // rethrow the error so you can catch it outside the context where login is called
    } finally {
      setStatusMessage("");
    }
  };

  const handleLogout = useCallback(() => {
    setAuthState({
      user: null,
      isAuthenticated: false,
      loading: false,
    });

    navigate("/auth/login");
  }, [navigate]);

  const logout = async () => {
    setAuthState((prevState) => ({ ...prevState, isLoggingOut: true }));

    try {
      await logoutUser();
      handleLogout();
    } catch (error) {
      if (error.message.includes("Token Expired")) {
        handleLogout();
      } else {
        console.error("An error occurred during logout:", error.message);
      }
    } finally {
      setAuthState((prevState) => ({ ...prevState, isLoggingOut: false }));
    }
  };

  return (
    <AuthContext.Provider
      value={{ ...authState, login, logout, checkAuthentication }}
    >
      {children}
    </AuthContext.Provider>
  );
}
