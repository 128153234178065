import { Box, TextField } from "@mui/material";
import React from "react";
import { Colors } from "../../../../Utilities/Colors";
const SearchCompositions = ({ onChange }) => (
  <Box
    sx={{
      width: "25%",
      float: "left",
    }}
  >
    <TextField
      fullWidth
      variant="outlined"
      placeholder="ابحث عن طريق الاسم..."
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        style: {
          backgroundColor: Colors.inputColor,
          borderColor: Colors.independence,
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "25px",
          borderColor: Colors.independence,
        },
        "& .MuiOutlinedInput-input": {
          padding: "12px 16px",
          color: Colors.darkBlue,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: Colors.mediumBlue,
        },
      }}
    />
  </Box>
);

export default SearchCompositions;
