import React, { createContext, useContext, useReducer } from "react";

const ExamContext = createContext({
  selectedChoices: {},
  setSelectedChoices: () => {},
});

export const useExamContext = () => {
  return useContext(ExamContext);
};

const CHOICES_ACTION = {
  SET: "SET",
};

const reducer = (state, action) => {
  switch (action.type) {
    case CHOICES_ACTION.SET:
      return {
        ...state,
        selectedChoices: action.payload,
      };
    default:
      return state;
  }
};

export const ExamProvider = ({ children }) => {
  const initialState = {
    selectedChoices: {},
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const setSelectedChoices = (choices) => {
    dispatch({ type: CHOICES_ACTION.SET, payload: choices });
  };

  return (
    <ExamContext.Provider value={{ ...state, setSelectedChoices }}>
      {children}
    </ExamContext.Provider>
  );
};
