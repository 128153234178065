import { useState, useEffect, useCallback } from "react";
import { getExamByUserId } from "../Services/ExamService";
import { getQuestionsByIds } from "../Services/QuestionService";
import { useAuth } from "../Contexts/AuthContext";

const useExamData = () => {
  const [exam, setExam] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  const fetchQuestionsByIds = useCallback(
    async (ids) => {
      try {
        return await getQuestionsByIds(ids, user.id);
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    [user.id]
  );

  useEffect(() => {
    const fetchExamAndQuestions = async () => {
      try {
        const response = await getExamByUserId(user.id);
        if (response) {
          setExam(response);

          let allQuestions = [];

          // Fetch questions for each chapter
          for (const chapter of response.chapters) {
            const chapterQuestions = await fetchQuestionsByIds(
              chapter.questionIds
            );
            allQuestions.push(...chapterQuestions);
          }

          // Fetch associated questions
          let associatedIds = [];
          for (let ids of Object.values(response.associatedQuestionMap)) {
            associatedIds = [...associatedIds, ...ids];
          }

          if (associatedIds.length > 0) {
            const associatedQuestions = await fetchQuestionsByIds(
              associatedIds
            );
            allQuestions.push(...associatedQuestions);
          }

          const filteredQuestions = allQuestions.filter(
            (q) => !associatedIds.includes(q.id)
          );

          setQuestions(filteredQuestions);
        }
      } catch (error) {
        console.error(error);
        alert("Failed to fetch exam.");
      } finally {
        setLoading(false);
      }
    };

    fetchExamAndQuestions();
  }, [user.id, fetchQuestionsByIds]);

  return { exam, questions, loading, setExam };
};

export default useExamData;
