import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Swal from "sweetalert2";
import EditPerk from "./EditPerk";
import { motion } from "framer-motion";
import { updatePerk, deletePerk } from "../../../Services/SubscriptionService";
import CustomPagination from "./CustomPagination";
import { deleteSwal } from "../../../Utilities/deleteSwal";

const MotionCard = motion(Card);

const Perks = ({ allPerks, fetchPerks }) => {
  const [selectedPerk, setSelectedPerk] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const itemsPerPage = 3;
  const [page, setPage] = useState(1);

  const handleEditClick = (perk) => {
    setSelectedPerk(perk);
    setEditDialogOpen(true);
  };

  const handleUpdate = async (updatedPerk) => {
    const finalPerk = { ...updatedPerk };

    // Convert fields with empty strings to null for optional fields
    if (finalPerk.frequency === "") finalPerk.frequency = null;
    if (finalPerk.level === "") finalPerk.level = null;

    try {
      await updatePerk(finalPerk.id, finalPerk);
      Swal.fire("تم التحديث!", "تم تحديث الميزة بنجاح.", "success");
    } catch (error) {
      Swal.fire("خطأ!", "فشل في تحديث الميزة.", "error");
    }
    setEditDialogOpen(false);
  };

  const handleDeletePerk = (perkId) => {
    deleteSwal({
      onConfirm: () => deletePerk(perkId),
      onSuccessDescription: "تم حذف الميزة بنجاح.",
      onErrorDescription: "فشل في حذف الميزة.",
    }).then(() => fetchPerks());
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedPerks = allPerks.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );
  return (
    <Grid container spacing={2}>
      {paginatedPerks.map((p) => (
        <Grid item xs={12} sm={6} md={4} key={p.id}>
          <MotionCard
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -30 }}
            whileHover={{ scale: 1.03 }}
            elevation={3}
            sx={{
              height: "100%",
              borderRadius: 2,
              backgroundColor: "linear-gradient(120deg, #f6f9fc, #eef5f9)",
            }}
          >
            <CardHeader
              title={
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {p.name}
                </Typography>
              }
              subheaderTypographyProps={{
                fontSize: "0.9rem",
              }}
              subheader={p.description}
              action={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => handleEditClick(p)}
                    sx={{
                      color: "grey.600",
                      "&:hover": { color: "info.dark" },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeletePerk(p.id)}
                    sx={{
                      color: "grey.600",
                      "&:hover": { color: "error.dark" },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              }
            />
          </MotionCard>
        </Grid>
      ))}
      <EditPerk
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        initialData={selectedPerk}
        onSubmit={handleUpdate}
        fetchPerks={fetchPerks}
      />
      <CustomPagination
        itemsCount={allPerks.length}
        itemsPerPage={itemsPerPage}
        currentPage={page}
        onPageChange={handlePageChange}
      />
    </Grid>
  );
};

export default Perks;
