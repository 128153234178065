import React from "react";
import { Grid, Skeleton, Box } from "@mui/material";

const RecentExamsSkeleton = ({ count }) => (
  <Box
    sx={{
      padding: 1,
      mt: 5,
      borderRadius: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    }}
  >
    {Array.from({ length: count }).map((_, idx) => (
      <Grid key={idx} item xs={4}>
        <Box
          sx={{
            borderRadius: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            flexGrow: 1,
            minHeight: "116px",
            paddingTop: 0,
            paddingBottom: 0,
            border: "1px solid transparent",
          }}
        >
          <Skeleton variant="text" width={80} height={24} />
          <Skeleton variant="rectangular" height={24} width="100%" />
          <Skeleton variant="rectangular" height={24} width="100%" />
        </Box>
      </Grid>
    ))}
  </Box>
);

export default RecentExamsSkeleton;
