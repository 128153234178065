import React from 'react';
import { Typography, Box, LinearProgress, Grid, Stack } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Colors } from '../../../Utilities/Colors';

const translateFrequency = (frequency) => {
  const translations = {
    EIGHT_HOURS: 'مرة كل ثمان ساعات',
    BI_DAILY: 'مرة كل 24 ساعة',
    DAILY: 'كل يوم مرة',
    WEEKLY: 'مرة أسبوعياً',
    MONTHLY: 'مرة شهرياً',
  };
  return translations[frequency] || frequency;
};

const QuantitativePerk = ({
  perkDetail,
  progressValue,
  regenerationMessage,
}) => {
  const quantityUsed = perkDetail.usage?.quantityUsed || 0;
  const totalQuantity = perkDetail.perk.quantity || 'غير محدد';

  const renderUsageText = () => {
    if (quantityUsed === 0) {
      return 'لم يتم الاستعمال بعد';
    } else {
      return `تم استعمال: ${quantityUsed}/${totalQuantity}`;
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={6}>
          <Stack
            direction="row"
            spacing={{ xs: 1, sm: 3 }}
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%' }}
            useFlexGap
            flexWrap="wrap"
          >
            <Box textAlign="center" sx={{ flex: '1' }}>
              <Typography
                variant="body1"
                color={Colors.darkBlue}
                fontWeight="bold"
                gutterBottom
              >
                {perkDetail.perk.name}
              </Typography>
              <Typography variant="body2" color={Colors.mediumBlue}>
                الميزة
              </Typography>
            </Box>
          </Stack>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="body2" color={Colors.independence}>
            {perkDetail.perk.description}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={6}
          alignItems="center"
          sx={{
            mt: 1,
            mb: 2,
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="body2"
              color={Colors.mediumBlue}
              sx={{ mr: 1 }}
            >
              {renderUsageText()}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress
            variant="determinate"
            value={progressValue}
            sx={{
              backgroundColor: Colors.brightGray,
              height: '0.8rem',
              borderRadius: 2,
              '& .MuiLinearProgress-bar': {
                backgroundColor: Colors.brightOrange,
              },
              mb: 1,
            }}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="space-between"
        >
          {perkDetail.perk.frequency && (
            <>
              <Grid item>
                <Box display="flex" alignItems="center">
                  <ScheduleIcon
                    sx={{ fontSize: 24, color: Colors.independence }}
                  />
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    {regenerationMessage}
                  </Typography>
                </Box>
              </Grid>

              <Grid item>
                <Typography variant="body2" color={Colors.mediumBlue}>
                  تتجدد {translateFrequency(perkDetail.perk.frequency)}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default QuantitativePerk;
