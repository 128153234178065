import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Pagination,
  Grid,
  Chip,
} from '@mui/material';
import gear from '../../../loaders/gear.svg';
import { StyledTableCell } from './Inputs/StyledTableCell';
import { StyledTableRow } from './Inputs/StyledTableRow';
import TypesFilter from './Inputs/TypesFilter';
import fetchAnimation from '../../../loaders/fetchAnimation.json';
import Lottie from 'lottie-react';

const getFilteredIds = (questions = []) => {
  const comprehensionQuestionIds = questions
    .filter((q) => q.type === 'READING_COMPREHENSION')
    .map((q) => q.id);

  const associatedQuestionIds = questions
    .filter((q) => q.type === 'READING_COMPREHENSION' && q.associatedQuestions)
    .reduce((acc, q) => {
      const ids = q.associatedQuestions.map((aq) => aq.id);
      return [...acc, ...ids];
    }, []);

  return [...new Set([...comprehensionQuestionIds, ...associatedQuestionIds])];
};

const QuestionTable = ({
  columns,
  regularQuestions,
  loading,
  fetchRegularQuestions,
  totalPages,
  currentRegularPage,
  setCurrentRegularPage,
  totalQuestionsCount,
  selectedQuestionType,
  setSelectedQuestionType,
}) => {
  // Logic to filter questions based on some criteria
  const filteredIds = getFilteredIds(regularQuestions);
  const filteredQuestions = regularQuestions.filter(
    (q) => !filteredIds.includes(q.id)
  );

  const handleChangePage = (_, newPage) => {
    setCurrentRegularPage(newPage - 1);
    fetchRegularQuestions(newPage - 1, 5, selectedQuestionType);
  };

  const handleQuestionTypeChange = (newTypes) => {
    setSelectedQuestionType(newTypes);
    fetchRegularQuestions(currentRegularPage, 5, newTypes);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          mb: 2,
        }}
      >
        <Grid item xs={12} md={8}>
          <TypesFilter
            value={selectedQuestionType}
            handleChange={handleQuestionTypeChange}
            loading={loading}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Chip
            label={
              loading ? (
                <div
                  style={{
                    position: 'relative',
                    height: '20px',
                    width: '50px',
                  }}
                >
                  <Lottie
                    animationData={fetchAnimation}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      height: '50px',
                      width: '50px',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </div>
              ) : (
                `إجمالي الأسئلة: ${totalQuestionsCount}`
              )
            }
            color="primary"
            sx={{
              p: 1,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'auto',
              overflow: 'visible',
            }}
          />
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '15px',
          overflow: 'hidden',
          boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
        }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  align={column.align || 'center'}
                  key={column.field}
                >
                  {column.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={columns.length}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={gear}
                      alt="Loading..."
                      style={{ marginRight: '10px' }}
                    />
                    جاري جلب الأسئلة...
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ) : filteredQuestions.length === 0 ? (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={columns.length}>
                  لا تتوافر بيانات
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              filteredQuestions.map((row) => (
                <StyledTableRow key={row.id}>
                  {columns.map((column) => (
                    <StyledTableCell
                      align={column.align || 'right'}
                      key={column.field}
                    >
                      {column.renderCell
                        ? column.renderCell({ row, value: row[column.field] })
                        : row[column.field]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Pagination
            count={totalPages}
            page={currentRegularPage + 1}
            shape="rounded"
            onChange={handleChangePage}
            sx={{
              '.MuiPaginationItem-root': {
                transform: 'scale(1.2)',
              },
              direction: 'ltr',
              '.MuiPaginationItem-icon': {
                rotate: '180deg',
              },
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default QuestionTable;
