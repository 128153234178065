import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  Typography,
} from '@mui/material';
import DifficultySelect from '../Inputs/DifficultySelect';

const AssociatedQuestions = ({
  associatedQuestions,
  currentSubQuestionIndex,
  handleAssociatedQuestionChange,
  removeAssociatedQuestion,
  addAssociatedQuestion,
  setCurrentSubQuestionIndex,
}) => {
  const defaultQuestion = {
    text: '',
    choices: ['', '', '', ''],
    correctAnswer: 0,
    difficultyLevel: 'NOT_SPECIFIED',
    explanation: '',
  };
  const currentQuestion =
    associatedQuestions[currentSubQuestionIndex] || defaultQuestion;

  if (
    currentSubQuestionIndex >= associatedQuestions.length ||
    currentSubQuestionIndex < 0
  ) {
    console.error('Invalid currentSubQuestionIndex:', currentSubQuestionIndex);
    return null;
  }
  return (
    <>
      <Grid item xs={12} container direction="column" alignItems="center">
        <Typography variant="h6" align="center" sx={{ mt: -1 }}>
          السؤال الفرعي {currentSubQuestionIndex + 1}
        </Typography>
      </Grid>
      <Grid item xs={12} container direction="column">
        <Typography variant="subtitle1" gutterBottom>
          نص السؤال
        </Typography>
        <TextField
          name={`associatedQuestions[${currentSubQuestionIndex}].text`}
          value={currentQuestion.text || ''}
          onChange={(e) =>
            handleAssociatedQuestionChange(e, currentSubQuestionIndex, 'text')
          }
          fullWidth
          sx={{
            backgroundColor: '#fafafa',
          }}
        />
      </Grid>

      {currentQuestion.choices &&
        currentQuestion.choices.map((choice, idx) => (
          <Grid item xs={6} key={idx}>
            <TextField
              fullWidth
              label={`خيار ${idx + 1}`}
              value={choice}
              onChange={(e) =>
                handleAssociatedQuestionChange(
                  e,
                  currentSubQuestionIndex,
                  'choices',
                  idx
                )
              }
              sx={{
                backgroundColor: '#fafafa',
              }}
            />
          </Grid>
        ))}

      <Grid item xs={6}>
        <FormControl
          fullWidth
          variant="outlined"
          sx={{
            backgroundColor: '#fafafa',
          }}
        >
          <InputLabel>الإجابة الصحيحة</InputLabel>
          <Select
            name={`associatedQuestions[${currentSubQuestionIndex}].correctAnswer`}
            value={
              typeof currentQuestion.correctAnswer !== 'undefined'
                ? currentQuestion.correctAnswer
                : ''
            }
            onChange={(e) =>
              handleAssociatedQuestionChange(
                e,
                currentSubQuestionIndex,
                'correctAnswer'
              )
            }
          >
            {currentQuestion.choices &&
              currentQuestion.choices.map((choice, idx) => (
                <MenuItem key={idx} value={idx}>
                  {choice}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <DifficultySelect
          value={currentQuestion.difficultyLevel || 'NOT_SPECIFIED'}
          onChange={(e) =>
            handleAssociatedQuestionChange(
              e,
              currentSubQuestionIndex,
              'difficultyLevel'
            )
          }
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="الشرح"
          name={`associatedQuestions[${currentSubQuestionIndex}].explanation`}
          value={currentQuestion.explanation || ''}
          onChange={(e) =>
            handleAssociatedQuestionChange(
              e,
              currentSubQuestionIndex,
              'explanation'
            )
          }
          sx={{
            backgroundColor: '#fafafa',
          }}
        />
      </Grid>

      <Grid container item xs={12} alignItems="center">
        {/* Grouping the related actions together and centering them */}
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          gap={5}
          sx={{
            mb: 2,
          }}
        >
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => removeAssociatedQuestion(currentSubQuestionIndex)}
              size="small"
              sx={{}}
            >
              ازالة السؤال الفرعي
            </Button>
          </Grid>

          <Grid item xs={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={addAssociatedQuestion}
              size="small"
            >
              إضافة سؤال فرعي جديد
            </Button>
          </Grid>
        </Grid>

        {/* Navigation between sub-questions with space-between */}
        <Grid
          container
          item
          xs={12}
          spacing={2}
          justifyContent="space-between"
          sx={{
            mb: 2,
          }}
        >
          <Grid item xs={4}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setCurrentSubQuestionIndex((prev) => prev - 1)}
              disabled={currentSubQuestionIndex === 0}
              size="small"
            >
              السؤال الفرعي السابق
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => setCurrentSubQuestionIndex((prev) => prev + 1)}
              disabled={
                currentSubQuestionIndex === associatedQuestions.length - 1
              }
              size="small"
            >
              السؤال الفرعي التالي
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AssociatedQuestions;
