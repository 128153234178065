import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import React from "react";

const tips = [
  "قبل بدء كتابة المقال، قم بقراءة الموضوع بعناية وحدد المفاهيم الرئيسية التي ستركز عليها في النص.",
  "حاول تنظيم أفكارك بشكل جيد قبل الكتابة. استخدم خطة بسيطة أو قائمة نقاط للمساعدة في التنظيم.",
  "اكتب بلغة واضحة ومفهومة. تجنب استخدام مصطلحات معقدة وغير مفهومة للقارئ.",
  "ابدأ بفقرة مقدمة تقدم فيها الموضوع العام للمقال والفكرة الرئيسية التي ستناقشها. ",
];

const TipsAndTricks = () => {
  return (
    <Paper
      elevation={5}
      sx={{
        padding: 3,
        borderRadius: 5,
        background: "#f7f8fc",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          mb: 2,
          fontWeight: "bold",
          borderBottom: "2px solid #333",
          pb: 1,
          textAlign: "center",
        }}
      >
        نصائح وحيل
      </Typography>

      <List dense={true}>
        {tips.map((tip, index) => (
          <React.Fragment key={index}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "medium",
                      textAlign: "right",
                      color: "#34495e",
                    }}
                  >
                    {tip}
                  </Typography>
                }
              />
            </ListItem>
            {index !== tips.length - 1 && <Divider component="li" />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default TipsAndTricks;
