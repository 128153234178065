import React, { useState } from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import ChoicesList from './ChoicesList';
import ProgressStepper from './ProgressStepper';
import { saveChoice } from '../../Services/ExamService';
import Swal from 'sweetalert2';

const ComprehensiveQuestion = ({
  question,
  examSubmitted,
  setSelectedChoices,
  selectedChoices,
  exam,
}) => {
  const [isSavingChoice, setIsSavingChoice] = useState(false);

  const associatedQuestionsCount = question.associatedQuestions
    ? question.associatedQuestions.length
    : 0;
  const [previewChoice, setPreviewChoice] = useState(null);

  const [currentAssociatedQuestionIndex, setCurrentAssociatedQuestionIndex] =
    useState(0);

  const handleAssociatedNext = () => {
    setCurrentAssociatedQuestionIndex((prevIndex) =>
      Math.min(prevIndex + 1, associatedQuestionsCount - 1)
    );
  };

  const handleAssociatedPrev = () => {
    setCurrentAssociatedQuestionIndex((prevIndex) =>
      Math.max(prevIndex - 1, 0)
    );
  };

  const currentAssociatedQuestion = question.associatedQuestions
    ? question.associatedQuestions[currentAssociatedQuestionIndex]
    : null;

  const handleAssociatedToggle = (index) => {
    if (!currentAssociatedQuestion) return;

    const currentChoicesSnapshot = { ...selectedChoices };
    const currentChoice = currentChoicesSnapshot[currentAssociatedQuestion.id];

    // Determine the new choice
    const newChoice = currentChoice === index ? null : index;

    // Optimistically update the UI
    const newChoices = {
      ...currentChoicesSnapshot,
      [currentAssociatedQuestion.id]: newChoice,
    };
    setSelectedChoices(newChoices);

    // Only proceed if the choice has changed
    if (currentChoice !== newChoice) {
      setIsSavingChoice(true);

      saveChoice(exam.id, currentAssociatedQuestion.id, newChoice)
        .then(() => {
          // Update here if needed for handling success
        })
        .catch((error) => {
          console.error(
            "Failed to save student's choice for associated question:",
            error
          );

          // Revert choice on error, resetting to the previous state.
          setSelectedChoices(currentChoicesSnapshot);

          // Notify the user of the error
          Swal.fire({
            icon: 'error',
            title: 'عذرًا...',
            text: 'حدث خطأ أثناء تقديم إجابتك. الرجاء المحاولة مرة أخرى.',
            confirmButtonText: 'حسنًا',
          });
        })
        .finally(() => {
          setIsSavingChoice(false);
        });
    }
  };

  const handleChoiceHover = (choice) => {
    setPreviewChoice(choice);
  };

  const resetPreviewChoice = () => {
    setPreviewChoice(null);
  };

  const extractQuotedWord = (text) => {
    const match = text.match(/"(.*?)"/);
    return match ? match[1] : null;
  };

  const highlightWordInText = (text, word) => {
    const highlighted = text.replace(
      new RegExp(word, 'g'),
      `<span class="highlighted-word">${word}</span>`
    );
    return highlighted;
  };

  const quotedWord =
    question.associatedQuestions && currentAssociatedQuestion
      ? extractQuotedWord(currentAssociatedQuestion.text)
      : null;

  const displayText = (paragraph) => {
    if (previewChoice) {
      return paragraph.replace(
        new RegExp(quotedWord, 'g'),
        `<span class="highlighted-word">${previewChoice}</span>`
      );
    } else {
      return quotedWord
        ? highlightWordInText(paragraph, quotedWord)
        : paragraph;
    }
  };

  return (
    <Grid
      container
      spacing={3}
      sx={{
        padding: 2,
        border: '1px solid #ddd',
        borderRadius: '4px',
        backgroundColor: '#f9f9f9',
      }}
    >
      <Grid item xs={12} md={8}>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{
            fontFamily: 'ExpoArabic',
          }}
        >
          النص الرئيسي
        </Typography>
        <Paper
          sx={{
            maxHeight: '480px',
            overflowY: 'auto',
            padding: '1rem',
            marginTop: '1rem',
            marginBottom: '1rem',
            borderRadius: 2,
          }}
          elevation={5}
        >
          <Typography variant="h6" gutterBottom>
            {question.text.split('#').map((paragraph, index) => {
              const isLast = index !== question.text.split('#').length - 1;
              const displayedParagraph = displayText(paragraph);
              return (
                <React.Fragment key={index}>
                  <span
                    dangerouslySetInnerHTML={{ __html: displayedParagraph }}
                  />
                  {isLast && (
                    <>
                      <br />
                      <br />
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </Typography>
        </Paper>
      </Grid>

      <Grid item xs={12} md={4}>
        {question.associatedQuestions && associatedQuestionsCount > 0 && (
          <>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                fontFamily: 'ExpoArabic',
              }}
            >
              {` السؤال ${currentAssociatedQuestionIndex + 1}`}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {
                question.associatedQuestions?.[currentAssociatedQuestionIndex]
                  ?.text
              }
            </Typography>
            <ChoicesList
              choices={
                question.associatedQuestions?.[currentAssociatedQuestionIndex]
                  ?.choices
              }
              selectedChoice={
                currentAssociatedQuestion
                  ? selectedChoices[currentAssociatedQuestion.id]
                  : undefined
              }
              handleToggle={handleAssociatedToggle}
              handleChoiceHover={handleChoiceHover}
              setPreviewChoice={resetPreviewChoice}
              isSavingChoice={isSavingChoice}
            />
          </>
        )}
        <Grid
          item
          xs={12}
          sx={{
            mt: 3,
          }}
        >
          <ProgressStepper
            steps={associatedQuestionsCount}
            currentStep={currentAssociatedQuestionIndex}
            handleNext={handleAssociatedNext}
            handlePrev={handleAssociatedPrev}
            variant="dots"
            isLastChapter={false}
            isFirstChapter={false}
            nextLabel="السؤال الفرعي التالي"
            prevLabel="السؤال الفرعي السابق"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ComprehensiveQuestion;
