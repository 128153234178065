export const Colors = {
  brightOrange: '#FD8F24',
  darkBlue: '#23314B',
  mediumBlue: '#2B5AAA',
  lightBlue: '#B8D6EE',
  platinum: '#DADADC',
  brightGray: '#EAEAEC',
  independence: '#435775',
  inputColor: '#fafafa',
  electricBlue: '#0a53a2',
  textColor: '#333333',
  darkCerulean: '#003d81',
  chargedBlue: '#15a9e5',
  CetaceanBlue: '#001138',
};
