import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Avatar,
} from '@mui/material';
import { Colors } from '../../Utilities/Colors';
import CourseSignupDialog from './CourseSignupDialog';

const AcademyInfo = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const courses = [
    {
      title: 'دورات لطلاب المدارس الإبتدائية',
      description:
        'دورات تفاعلية تركز على بناء الأساسيات اللغوية وتعزيز مهارات التواصل لدى الأطفال. تشمل الدورة تمارين ممتعة وأنشطة تعليمية تُعنى بتنمية القدرات الإبداعية والتفكير النقدي في مرحلة الطفولة المبكرة.',
    },
    {
      title: 'دورات لطلاب المدارس الإعدادية',
      description:
        'برامج تعليمية مصممة لتعزيز المعرفة وتطوير المهارات الأكاديمية للطلاب في هذه المرحلة الحيوية. تركز الدورات على تحسين الفهم اللغوي وتقوية القدرة على التحليل والتفسير، إلى جانب تشجيع الاستقلالية في التعلم.',
    },
    {
      title: 'دورات لطلاب المدارس الثانوية',
      description:
        'دورات متقدمة تهدف إلى إعداد الطلاب للتميز الأكاديمي والتحضير للامتحانات الرسمية. تغطي الدورات موضوعات متنوعة ومعقدة، مع التركيز على تطوير مهارات البحث والتحليل النقدي، بالإضافة إلى توجيهات خاصة لاختبارات القبول الجامعية والمهنية.',
    },
  ];

  const teacher = {
    name: 'سارة ريان',
    bio: 'تُعتبر الأستاذة سارة ريان من الأعمدة التعليمية المتميزة في مجال تعليم اللغة العبرية، حيث تحمل شهادة متقدمة في تدريس هذه اللغة.\nتتمتع سارة بخبرة عميقة استمرت لعدة سنوات، كانت خلالها ملتزمة بتبني أساليب تعليمية حديثة ومبتكرة. لها الفضل في تطوير مهارات العديد من الطلاب وتحفيزهم على الإبداع والتفوق الأكاديمي.',
    avatar: '/assets/sarah_avatar.png',
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 3,
              height: '100%',
            }}
          >
            <Box
              sx={{
                backgroundColor: Colors.darkCerulean,
                borderRadius: '12px',
                display: 'inline-block',
                padding: '2px 12px 2px 12px',
                mb: 3,
              }}
            >
              <Typography
                variant="h6"
                component="div"
                align="center"
                sx={{
                  color: Colors.inputColor,
                  fontFamily: 'ExpoArabic',
                }}
              >
                مرحبًا بكم في أكاديميتنا
              </Typography>
            </Box>

            <Typography
              sx={{
                color: Colors.independence,
                textAlign: 'center',
                fontFamily: 'ExpoArabic',
                fontSize: '14px',
              }}
            >
              نسعى في معهدنا إلى إشراك الطلاب من جميع الفئات العمرية بفضل توفير
              مجموعة شاملة ومتنوعة من الدورات التعليمية، المصممة بعناية فائقة
              لتلبية احتياجاتهم التعليمية والمهنية. <br />
              ندعوكم للانضمام إلينا واستكشاف آفاق جديدة من الفرص، وتعزيز
              مهاراتكم، والتفاعل المباشر مع خبير في مجالات عدة ومتنوعة.
              <br />
              <br /> في تدريس اللغة العبرية، نضمن تقديم تجربة تعليمية مخصصة
              تستجيب لاحتياجات كل طالب على حدة، بدءًا من التوجيه والمتابعة
              الدقيقة والدعم المستمر من قبل المعلمين، مرورًا بإعداد الطلاب
              للامتحانات، وصولاً إلى تحسين مهارات القراءة والكتابة وغيرها من
              المهارات الأساسية.
              <br />
              تعلموا أن العلم رحلة مستمرة، وأن كل رحلة تبدأ بخطوة... وخطوتكم
              الأولى نحو المستقبل تبدأ هنا.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          <Card
            elevation={0}
            sx={{
              bgcolor: 'transparent',
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                borderRight: '2px solid #cccccc',
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  ml: 3,
                  mr: 2,
                }}
              >
                <Avatar
                  src={teacher.avatar}
                  alt={teacher.name}
                  sx={{
                    width: 100,
                    height: 100,
                    border: `2px solid ${Colors.mediumBlue}`,
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Typography
                  component="div"
                  sx={{
                    fontFamily: 'ExpoArabic',
                    color: Colors.darkCerulean,
                    fontSize: '24px',
                    fontWeight: 'bold',
                  }}
                >
                  {teacher.name}
                </Typography>

                <Typography
                  component="div"
                  gutterBottom
                  sx={{
                    color: Colors.chargedBlue,
                    mb: 1,
                    fontFamily: 'ExpoArabic',
                    fontSize: '16px',
                  }}
                >
                  المعلمــــة المسؤولــــة
                </Typography>
              </Box>

              <CardContent sx={{ pt: 0 }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: Colors.independence,
                    textAlign: 'center',
                    mt: 2,
                    fontFamily: 'ExpoArabic',
                  }}
                >
                  {teacher.bio}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>

      {/* Courses section */}
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 4 }}>
        {courses.map((course, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                px: 2,
              }}
            >
              <Card
                sx={{
                  width: '100%',
                  bgcolor: Colors.brightGray,
                  borderRadius: '12px',
                  border: `1px dashed ${Colors.darkCerulean}`,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: Colors.darkCerulean,
                    color: 'white',
                    textAlign: 'center',
                    fontFamily: 'ExpoArabic',
                    fontSize: '20px',
                    padding: 1,
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                  }}
                >
                  {course.title}
                </Box>
                <CardContent
                  sx={{
                    bgcolor: Colors.inputColor,
                    padding: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: Colors.textColor,
                      height: '150px',
                      fontFamily: 'ExpoArabic',
                      textAlign: 'center',
                    }}
                  >
                    {course.description}
                  </Typography>
                </CardContent>
              </Card>
              <Box sx={{ width: '100%', mt: 2 }}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleDialogOpen}
                  sx={{
                    bgcolor: Colors.chargedBlue,
                    fontFamily: 'ExpoArabic',
                    fontSize: '18px',
                    borderRadius: '8px',
                    '&:hover': { bgcolor: Colors.darkBlue },
                  }}
                >
                  سجل الآن
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}
        <CourseSignupDialog
          open={isDialogOpen}
          handleClose={handleDialogClose}
        />
      </Grid>
    </Box>
  );
};

export default AcademyInfo;
