import { Box } from "@mui/material";
import { motion } from "framer-motion";

export const renderProgress = (daysStudied) => {
  const percentage = (daysStudied / 7) * 100;

  return (
    <Box position="relative" width="100%" borderRadius="12px" overflow="hidden">
      <Box
        style={{
          height: "24px",
          background:
            "repeating-linear-gradient(45deg, #f0f0f0, #f0f0f0 5px, #e0e0e0 5px, #e0e0e0 10px)",
        }}
      ></Box>

      <motion.div
        initial={{ backgroundPosition: "0% 0" }}
        animate={{ backgroundPosition: "-100% 0" }}
        transition={{ duration: 10, repeat: Infinity, ease: "linear" }}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "24px",
          width: `${percentage}%`,
          backgroundImage: `linear-gradient(90deg, #7c91a6 0%, #c5d2de 25%, #7c91a6 50%, #c5d2de 75%, #7c91a6 100%)`,
          backgroundSize: "200% 100%",
          borderRadius: "12px 0 0 12px",
        }}
      ></motion.div>
    </Box>
  );
};
