import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Box,
  List,
  ListItemText,
  Divider,
  ListItemButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Colors } from '../../Utilities/Colors';
import { motion } from 'framer-motion';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
const InfoComponent = ({ infoJson, contentType }) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleListItemClick = (index) => {
    setSelectedItem(index);
  };

  const listVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const listItemVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        stiffness: 120,
      },
    },
  };
  const renderContent = (item) => {
    switch (contentType) {
      case 'websiteInfo':
        return (
          <>
            <Typography
              sx={{
                color: Colors.independence,
                mt: 2,
                mb: 2,
                whiteSpace: 'pre-wrap',
                fontFamily: 'ExpoArabic',
              }}
              variant={isSmallScreen ? 'body2' : 'body1'}
            >
              {item.content}
            </Typography>
            {item.followUpContent &&
              item.followUpContent.map((followUpItem, index) => (
                <Box key={index}>
                  <Typography
                    sx={{
                      color: Colors.independence,
                      mt: 2,
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      fontFamily: 'ExpoArabic',
                    }}
                    variant={isSmallScreen ? 'body2' : 'body1'}
                  >
                    <BoltOutlinedIcon sx={{ verticalAlign: 'middle' }} />
                    {followUpItem.feature}
                  </Typography>
                  <Typography
                    sx={{
                      color: Colors.independence,
                      mt: 1,
                      mb: 2,
                      whiteSpace: 'pre-wrap',
                      m: 1,
                      fontFamily: 'ExpoArabic',
                    }}
                    variant={isSmallScreen ? 'body2' : 'body1'}
                  >
                    {followUpItem.description}
                  </Typography>
                </Box>
              ))}
          </>
        );
      default:
        return (
          <>
            <Typography
              sx={{
                color: Colors.independence,
                mt: 2,
                mb: 2,
                whiteSpace: 'pre-wrap',
                fontFamily: 'ExpoArabic',
              }}
              variant={isSmallScreen ? 'body2' : 'h6'}
            >
              {item.content}
            </Typography>
            {item.link && (
              <Typography
                sx={{
                  fontFamily: 'ExpoArabic',
                  textDecoration: 'underline',
                  color: 'blue',
                  cursor: 'pointer',
                }}
                variant={isSmallScreen ? 'body2' : 'body1'}
                component="a"
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                للتسجيل اضغط هنا
              </Typography>
            )}
          </>
        );
    }
  };

  return (
    <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} p={1}>
      <Box
        minWidth={isSmallScreen ? '100%' : 200}
        maxWidth={isSmallScreen ? '100%' : 300}
        mr={isSmallScreen ? 0 : 2}
        mb={isSmallScreen ? 2 : 0}
      >
        <Paper
          elevation={0}
          sx={{
            height: isSmallScreen ? 'auto' : '552px',
            backgroundColor: 'transparent',
          }}
        >
          <motion.div
            variants={listVariants}
            initial="hidden"
            animate="visible"
            style={{
              overflowX: 'hidden',
              overflowY: 'auto',
              maxHeight: isSmallScreen ? '180px' : '100%',
            }}
          >
            <List>
              {infoJson.map((section, index) => (
                <React.Fragment key={index}>
                  <motion.div variants={listItemVariants}>
                    <ListItemButton
                      selected={selectedItem === index}
                      onClick={() => handleListItemClick(index)}
                      sx={{ textAlign: isSmallScreen ? 'center' : 'right' }}
                    >
                      <ListItemText
                        primary={section.title}
                        primaryTypographyProps={{ fontFamily: 'ExpoArabic' }}
                        sx={{
                          '& .MuiListItemText-primary': {
                            fontWeight: selectedItem === index ? 700 : 400,
                          },
                        }}
                      />
                    </ListItemButton>
                  </motion.div>
                  {index < infoJson.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </motion.div>
        </Paper>
      </Box>
      <Box
        flex={1}
        minWidth={isSmallScreen ? '100%' : ''}
        sx={{
          height: '552px',
        }}
      >
        <Paper
          elevation={0}
          sx={{
            p: 3,
            height: isSmallScreen ? '450px' : '100%',
            overflow: 'auto',
            backgroundColor: 'transparent',
            borderRight: isSmallScreen
              ? 'none'
              : `3px solid ${Colors.platinum}`,
          }}
        >
          <motion.div
            key={selectedItem}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: [0.17, 0.67, 0.83, 0.67], duration: 0.5 }}
          >
            <Typography
              variant={isSmallScreen ? 'h6' : 'h5'}
              component="h2"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: Colors.electricBlue,
                textAlign: isSmallScreen ? 'center' : 'right',
                fontFamily: 'ExpoArabic',
              }}
            >
              {infoJson[selectedItem].title}
            </Typography>
            {renderContent(infoJson[selectedItem])}
          </motion.div>
        </Paper>
      </Box>
    </Box>
  );
};

export default InfoComponent;
