import { useAuth } from "../Contexts/AuthContext";

const useIsAdmin = () => {
  const { user, loading } = useAuth();
  if (loading) {
    return null;
  }
  return user && user.role === "ADMIN";
};

export default useIsAdmin;
