import React from "react";
import {
  Box,
  Typography,
  Button,
  Slide,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../Utilities/Colors";

const ErrorPage = ({ errorCode, title, message }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Slide direction="up" in mountOnEnter unmountOnExit>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          backgroundColor: Colors.inputColor,
          p: isMobile ? 2 : 5,
          textAlign: "center",
        }}
      >
        <Typography
          variant={isMobile ? "h2" : "h1"}
          component="div"
          sx={{
            color: Colors.darkBlue,
            mb: 1,
            fontSize: isMobile ? "10rem" : "12rem",
            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontWeight: 900,
          }}
        >
          {errorCode}
        </Typography>
        <Typography
          variant={isMobile ? "h6" : "h4"}
          component="div"
          sx={{
            color: Colors.independence,
            mb: 3,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: Colors.mediumBlue,
            mb: 4,
            maxWidth: isMobile ? "80%" : "60%",
            textAlign: "center",
          }}
        >
          {message}
        </Typography>
        <Button
          variant="contained"
          startIcon={<HomeOutlinedIcon sx={{ ml: 1 }} />}
          sx={{
            backgroundColor: Colors.brightOrange,
            fontSize: isMobile ? "0.875rem" : "1rem",
            transition: "transform 0.3s",
            "&:hover": {
              transform: "scale(1.05)",
            },
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          العودة إلى الصفحة الرئيسية
        </Button>
      </Box>
    </Slide>
  );
};

export default ErrorPage;
