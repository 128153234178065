import { axiosInstance, apiWrapper } from "./Service";

export async function flagQuestion(questionId, feedback, userId) {
  const response = await apiWrapper(
    () =>
      axiosInstance.post(`/questions/flag/create`, {
        questionId,
        feedback,
        userId,
      }),
    `Feedback sent successfully`,
    `Failed to send feedback`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getQuestionFlags() {
  const response = await apiWrapper(
    () => axiosInstance.get(`/questions/flags`),
    `Flags retrieved successfully`,
    `Failed to retrieve flags`
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function updateFlagStatus(updatedFlag) {
  const response = await apiWrapper(
    () =>
      axiosInstance.put(
        `/questions/flags/${updatedFlag.id}/update`,
        updatedFlag
      ),
    `Flag updated successfully`,
    `Failed to update flag`
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}
