import React from 'react';
import Paper from '@mui/material/Paper';
import { Colors } from './Colors';

const OverlayPaper = (props) => (
  <Paper
    elevation={5}
    sx={{
      textAlign: 'center',
      borderRadius: '8px',
      p: 2,
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: `${Colors.electricBlue}`,
      color: '#EAEAEC',
      transition: 'transform 0.3s, backgroundColor 0.3s, boxShadow 0.3s',
      '&:hover': {
        transform: 'scale(1.02) translateY(-5px)',
        boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.15)',
        backgroundColor: '#084c82',
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: '50%',
        width: '150%',
        height: '100%',
        backgroundImage: 'linear-gradient(120deg, #2B5AAA, #B8D6EE)',
        opacity: 0.2,
        transform: 'rotate(10deg) translateX(-50%)',
      },
    }}
    {...props}
  >
    {props.children}
  </Paper>
);

export default OverlayPaper;
