import React, { useState, useEffect } from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {
  getAllPlansForUsers,
  getPerkById,
} from '../../Services/SubscriptionService';
import useIsSubscriber from '../../Hooks/useIsSubscriber';
import SubscriptionPlans from './SubscriptionPlans';
import { Helmet } from 'react-helmet-async';
import Loader from '../../loaders/loader.svg';
import { Box } from '@mui/material';
import { Colors } from '../../Utilities/Colors';
import BookSubscriptionInfo from './BookSubscriptionInfo';

const SubscriptionPage = () => {
  const [plans, setPlans] = useState([]);
  const { isSubscriber, loading } = useIsSubscriber();
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    setIsFetching(true);
    try {
      const fetchedPlans = await getAllPlansForUsers();
      if (!Array.isArray(fetchedPlans)) {
        console.warn(
          'Expected fetchedPlans to be an array, but got:',
          fetchedPlans
        );
        return;
      }

      const plansWithPerks = await Promise.all(
        fetchedPlans.map(async (plan) => {
          const perks = plan.perkIds?.length
            ? await Promise.all(
                plan.perkIds.map(async (perkId) => {
                  const perk = await getPerkById(perkId);
                  return perk;
                })
              )
            : [];
          return { ...plan, perks };
        })
      );

      setPlans(plansWithPerks);
    } catch (error) {
      console.error('Failed to fetch subscription plans', error);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>صفحة الاشتراك - אל-יע״ל</title>
        <meta
          name="description"
          content="اختر خطة الاشتراك الخاصة بك واستمتع بالمزايا الحصرية."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: 'صفحة الاشتراك - אל-יע״ל',
            description:
              'اختر خطة الاشتراك الخاصة بك واستمتع بالمزايا الحصرية.',
            inLanguage: 'ar',
            url: 'https://elyael.com/subscriptions/plans',
            mainEntityOfPage: {
              '@type': 'WebPage',
              '@id': 'https://elyael.com/subscriptions/plans',
            },
            publisher: {
              '@type': 'Organization',
              name: 'אל-יע״ל',
              logo: {
                '@type': 'ImageObject',
                url: 'http://elyael.com/assets/logo.jpg',
              },
            },
          })}
        </script>
      </Helmet>
      <Container maxWidth="lg" sx={{ mb: 5 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            mt: 5,
          }}
        >
          <Box
            sx={{
              width: { xs: '100%', md: '40%' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: { xs: 'center', md: 'right' },
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontSize: { xs: '20px', md: '28px' },
                fontFamily: 'ExpoArabic',
                mb: 2,
                textAlign: 'right',
                color: `${Colors.chargedBlue}`,
              }}
            >
              المتجـــــــــر
            </Typography>
            <Typography
              variant="h4"
              component="div"
              sx={{
                fontSize: { xs: '40px', md: '70px' },
                fontFamily: 'ExpoArabic',
                maxWidth: '400px',
                textAlign: 'right',
                width: '100%',
                fontWeight: 'bold',
                color: `${Colors.darkCerulean}`,
              }}
            >
              اختــر خطـــة الاشتــــــراك الخاصـة بــك
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: '100%', md: '40%' },
              height: { xs: '200px', md: '400px' },
              backgroundImage: `url('/assets/store-bg.png')`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'left center',
              display: { xs: 'none', md: 'block' },
            }}
          />
        </Box>

        {loading || isFetching ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ height: '60vh', width: '100%' }}
          >
            <img src={Loader} alt="Loading..." />
            <Typography variant="h6" sx={{ mt: 2 }}>
              جارٍ إحضار رزم الإشتراك ...
            </Typography>
          </Box>
        ) : (
          <SubscriptionPlans plans={plans} isSubscriber={isSubscriber} />
        )}

        <BookSubscriptionInfo />
      </Container>
    </>
  );
};

export default SubscriptionPage;
