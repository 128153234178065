import React from 'react';
import Slider from 'react-slick';
import {
  Typography,
  Box,
  Container,
  Grid,
  useTheme,
  useMediaQuery,
  Paper,
  Divider,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Colors } from '../../Utilities/Colors';

const StudentsGrades = () => {
  const images = [
    '/assets/testimonial1.jpg',
    '/assets/testimonial2.jpg',
    '/assets/testimonial3.jpg',
    '/assets/testimonial4.jpg',
    '/assets/testimonial5.jpg',
    '/assets/testimonial6.jpg',
    '/assets/testimonial7.jpg',
    '/assets/testimonial8.jpg',
    '/assets/testimonial9.jpg',
    '/assets/testimonial10.jpg',
    '/assets/testimonial11.jpg',
    '/assets/testimonial12.jpg',
    '/assets/testimonial13.jpg',
    '/assets/testimonial14.jpg',
    '/assets/testimonial15.jpg',
  ];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    centerMode: isSmallScreen ? true : false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        p: { xs: 1, sm: 2 },
      }}
    >
      <Box sx={{ mt: 3, textAlign: 'center', flexGrow: 1 }}>
        <AnimatePresence wait>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: `${Colors.electricBlue}`,
                fontFamily: 'ExpoArabic',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              علامات الطلاب
            </Typography>

            <Divider
              sx={{
                border: `2px solid #c3c3c3`,
                width: '5%',
                margin: 'auto',
              }}
            />

            <Typography
              sx={{
                color: `${Colors.textColor}`,
                fontFamily: 'ExpoArabic',
                mt: 2,
                fontSize: '16px',
              }}
            >
              نستعرض بعض العلامات للطلاب قبل وبعد الاشتراك معنا
            </Typography>
          </motion.div>
        </AnimatePresence>

        <Grid container justifyContent="center" spacing={isSmallScreen ? 1 : 3}>
          <Grid item xs={12} md={10}>
            <Slider {...settings}>
              {images.map((imgSrc, index) => (
                <Box
                  component={motion.div}
                  key={index}
                  sx={{
                    p: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    boxSizing: 'border-box',
                    borderRadius: 2,
                  }}
                  whileHover={{ scale: 1.05 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      borderRadius: '8px',
                      overflow: 'hidden',
                      width: isSmallScreen ? '250px' : '100%',
                    }}
                  >
                    <Box
                      component="img"
                      role="img"
                      aria-labelledby={`testimonial${index + 1}`}
                      sx={{
                        maxWidth: '100%',
                        objectFit: isSmallScreen ? 'contain' : 'stretch',
                        width: '100%',
                        height: '250px',
                      }}
                      src={imgSrc}
                      alt={`Testimonial from student ${index + 1}`}
                    />
                  </Paper>
                </Box>
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default StudentsGrades;
