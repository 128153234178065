import { useEffect, useState } from 'react';
import { useAuth } from '../Contexts/AuthContext';
import { getUserSubscriptionPlan } from '../Services/UserService';

const useIsSubscriber = () => {
  const { user } = useAuth();
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      setLoading(false);
      return;
    }
    const checkSubscription = async () => {
      if (user) {
        try {
          const subscriptionData = await getUserSubscriptionPlan(user.id);
          if (
            subscriptionData &&
            subscriptionData.subscriptionStatus === 'ACTIVE'
          ) {
            setIsSubscriber(
              subscriptionData.subscriptionPlan.role !== 'FREEMIUM'
            );
          } else {
            setIsSubscriber(false);
          }
        } catch (error) {
          console.error('Failed to get subscription plan', error);
          setIsSubscriber(false);
        }
      } else {
        setIsSubscriber(false);
      }
      setLoading(false);
    };

    checkSubscription();
  }, [user]);

  return { isSubscriber, loading };
};

export default useIsSubscriber;
