import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Pagination,
  Chip,
  Grid,
} from '@mui/material';
import gear from '../../../../loaders/gear.svg';
import { StyledTableCell } from '../Inputs/StyledTableCell';
import { StyledTableRow } from '../Inputs/StyledTableRow';
import fetchAnimation from '../../../../loaders/fetchAnimation.json';
import Lottie from 'lottie-react';

const getComprehensiveQuestionsWithAssociates = (questions) => {
  return questions.filter(
    (q) =>
      q.type === 'READING_COMPREHENSION' &&
      q.associatedQuestions &&
      q.associatedQuestions.length > 0
  );
};

const ComprehensiveQuestionsTable = ({
  columns,
  comprehensionQuestions,
  loading,
  fetchComprehensionQuestions,
  totalPages,
  currentComprehensivePage,
  setCurrentComprehensivePage,
  totalQuestionsCount,
}) => {
  const comprehensiveQuestionsWithAssociates =
    getComprehensiveQuestionsWithAssociates(comprehensionQuestions);

  const handleChangePage = (_, newPage) => {
    setCurrentComprehensivePage(newPage - 1);
    fetchComprehensionQuestions(newPage - 1, 5);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        sx={{
          mb: 2,
        }}
      >
        <Grid item xs={12} md={8}></Grid>
        <Grid item xs={12} md={4}>
          <Chip
            label={
              loading ? (
                <div
                  style={{
                    position: 'relative',
                    height: '20px',
                    width: '50px',
                  }}
                >
                  <Lottie
                    animationData={fetchAnimation}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      height: '50px',
                      width: '50px',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </div>
              ) : (
                `إجمالي الأسئلة: ${totalQuestionsCount}`
              )
            }
            color="primary"
            sx={{
              p: 1,
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'auto',
              overflow: 'visible',
            }}
          />
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '15px',
          overflow: 'hidden',
          boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
        }}
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  align={column.align || 'center'}
                  key={column.field}
                >
                  {column.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={columns.length}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={gear}
                      alt="Loading..."
                      style={{ marginRight: '10px' }}
                    />
                    جاري جلب الأسئلة...
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ) : comprehensiveQuestionsWithAssociates.length > 0 ? (
              comprehensiveQuestionsWithAssociates.map((row) => (
                <StyledTableRow key={row.id}>
                  {columns.map((column) => (
                    <StyledTableCell
                      align={column.align || 'right'}
                      key={column.field}
                    >
                      {column.renderCell
                        ? column.renderCell({ row, value: row[column.field] })
                        : row[column.field]}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" colSpan={columns.length}>
                  لا تتوافر بيانات
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Pagination
            count={totalPages}
            page={currentComprehensivePage + 1}
            shape="rounded"
            onChange={handleChangePage}
            sx={{
              '.MuiPaginationItem-root': {
                transform: 'scale(1.2)',
              },
              direction: 'ltr',
              '.MuiPaginationItem-icon': {
                rotate: '180deg',
              },
            }}
          />
        </Stack>
      )}
    </>
  );
};

export default ComprehensiveQuestionsTable;
