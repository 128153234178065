import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

const CompositionGeneralInfo = () => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 3,
        borderRadius: 5,
        background: "#f7f8fc",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            align="center"
            sx={{ mb: 2, fontWeight: "bold" }}
          >
            قسم الكتابة في اختبار YAEL
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            يُعتبر قسم الكتابة من اختبار YAEL فرصة للمتقدمين لإظهار قدرتهم على
            التعبير بشكل مكتوب في اللغة العبرية.
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            الهدف من هذا القسم هو قياس قدرة المتقدم على تنظيم الأفكار والتعبير
            عنها بطريقة منسقة وواضحة، مع مراعاة الالتزام بالقواعد اللغوية وقواعد
            الإملاء الصحيحة في اللغة العبرية.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            من الضروري أن يراعي المتقدم أثناء الكتابة التركيز على الجودة
            والتفصيل بدلاً من الكمية، وأن يتأكد من التقيد بالوقت المحدد للكتابة.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CompositionGeneralInfo;
