import React, { useState } from 'react';
import {
  Box,
  Container,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { Colors } from '../../Utilities/Colors';
import { motion } from 'framer-motion';
import GeneralInfo from './GeneralInfo';
import ExamInfo from './ExamInfo';
import WebsiteInfo from './WebsiteInfo';
import AcademyInfo from './AcademyInfo';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <motion.div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      initial={{ opacity: 0 }}
      animate={{ opacity: value === index ? 1 : 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </motion.div>
  );
}

const Information = () => {
  const [value, setValue] = useState(2);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }} disableGutters>
      <Box
        sx={{
          width: '100%',
          minHeight: '300px',
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-around',
          alignItems: 'center',
          px: isSmallScreen ? 4 : 12,
          mb: isSmallScreen ? 4 : 0,
        }}
      >
        {!isSmallScreen && (
          <Box
            sx={{
              backgroundImage: `url('/assets/information-bg.png')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              flexBasis: '40%',
              minHeight: '300px',
            }}
          />
        )}
        <Box
          sx={{
            flex: 1,
            textAlign: 'right',
            paddingRight: 3,
          }}
        >
          <Typography
            sx={{
              fontFamily: 'ExpoArabic',
              fontSize: '30px',
              color: `${Colors.chargedBlue}`,
            }}
          >
            معلومـــــــات
          </Typography>

          <Typography
            sx={{
              fontFamily: 'ExpoArabic',
              fontSize: '70px',
              mt: 1,
              color: `${Colors.darkCerulean}`,
            }}
          >
            مـــن نحـــن؟
          </Typography>

          <Typography
            sx={{
              fontFamily: 'ExpoArabic',
              fontSize: '22px',
              mt: 1,
              maxWidth: '450px',
              color: `${Colors.textColor}`,
            }}
          >
            يُعتَبرُ اختبار ياعيلنِت نسخةً محوسبةً تتطابق في محتواها مع اختبار
            ياعيل وقد تم تصميمه لتقييم كفاءة اللغة العبرية بشكل دقيق وموثوق.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          borderBottom: 2,
          borderColor: 'divider',
          textAlign: 'center',
          backgroundColor: Colors.chargedBlue,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="FAQ Tabs"
          centered
          TabIndicatorProps={{
            style: {
              backgroundColor: Colors.darkBlue,
              height: isSmallScreen ? '3px' : '5px',
            },
          }}
          sx={{
            margin: 'auto',
            maxWidth: 700,
            padding: 1,
          }}
        >
          <Tab
            label="معلومات عامة"
            sx={{
              fontSize: isSmallScreen ? '0.9rem' : '1.2rem',
              borderLeft: '1px solid white',
              borderRight: '1px solid white',
              color: value === 0 ? Colors.electricBlue : 'white',
              backgroundColor: value === 0 ? 'white' : 'transparent',
              fontFamily: 'ExpoArabic',
              p: isSmallScreen ? 2 : 4,
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: Colors.lightGray,
                color: Colors.darkCerulean,
                transform: 'translateY(-2px)',
              },
            }}
          />
          <Tab
            label="عَـن الامتحان"
            sx={{
              fontSize: isSmallScreen ? '0.9rem' : '1.2rem',
              borderLeft: '1px solid white',
              color: value === 1 ? Colors.electricBlue : 'white',
              backgroundColor: value === 1 ? 'white' : 'transparent',
              fontFamily: 'ExpoArabic',
              p: isSmallScreen ? 2 : 4,
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: Colors.lightGray,
                color: Colors.darkCerulean,
                transform: 'translateY(-2px)',
              },
            }}
          />
          <Tab
            label="عَـن المَعهَد"
            sx={{
              fontSize: isSmallScreen ? '0.9rem' : '1.2rem',
              borderLeft: '1px solid white',
              color: value === 2 ? Colors.electricBlue : 'white',
              backgroundColor: value === 2 ? 'white' : 'transparent',
              fontFamily: 'ExpoArabic',
              p: isSmallScreen ? 2 : 4,
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: Colors.lightGray,
                color: Colors.darkCerulean,
                transform: 'translateY(-2px)',
              },
            }}
          />
          <Tab
            label="عَـن الموقع"
            sx={{
              fontSize: isSmallScreen ? '0.9rem' : '1.2rem',
              borderLeft: '1px solid white',
              color: value === 3 ? Colors.electricBlue : 'white',
              backgroundColor: value === 3 ? 'white' : 'transparent',
              fontFamily: 'ExpoArabic',
              p: isSmallScreen ? 2 : 4,
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: Colors.lightGray,
                color: Colors.darkCerulean,
                transform: 'translateY(-2px)',
              },
            }}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <GeneralInfo />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ExamInfo />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AcademyInfo />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <WebsiteInfo />
      </TabPanel>
    </Container>
  );
};

export default Information;
