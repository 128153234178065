import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { StyledTextarea } from "../Inputs/StyledTextarea";
import DifficultySelect from "../Inputs/DifficultySelect";

const AssociatedQuestionForm = ({
  isSideDialogOpen,
  toggleSideDialog,
  currentAssociatedQuestion,
  handleCurrentAssociatedQuestionChange,
  handleAssociatedChoiceChange,
  submitAssociatedQuestion,
}) => (
  <Dialog open={isSideDialogOpen} onClose={toggleSideDialog}>
    <DialogTitle
      textAlign="center"
      sx={{
        borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
        padding: "10px",
        borderRadius: 2,
      }}
    >
      أضف سؤال مرتبط
    </DialogTitle>
    <DialogContent>
      <Grid container spacing={2} alignItems="center" p={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            نص السؤال
          </Typography>
          <StyledTextarea
            id="text"
            name="text"
            minRows={3}
            maxRows={10}
            value={currentAssociatedQuestion.text}
            onChange={(e) => handleCurrentAssociatedQuestionChange(e, "text")}
            placeholder="نص السؤال"
            aria-label="نص السؤال"
          />
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider />
        </Grid>

        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          item
        >
          {currentAssociatedQuestion.choices.map((choice, index) => (
            <Grid item xs={6} key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TextField
                  label={`الخيار ${index + 1}`}
                  variant="outlined"
                  value={choice}
                  onChange={(e) =>
                    handleAssociatedChoiceChange(index, e.target.value)
                  }
                  style={{
                    flex: 1,
                    backgroundColor: "#fafafa",
                  }}
                />
              </div>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>الإجابة الصحيحة</InputLabel>
            <Select
              value={currentAssociatedQuestion.correctAnswer}
              onChange={(e) =>
                handleCurrentAssociatedQuestionChange(e, "correctAnswer")
              }
              label="الإجابة الصحيحة"
              sx={{
                backgroundColor: "#fafafa",
              }}
            >
              {currentAssociatedQuestion.choices.map((choice, index) => (
                <MenuItem key={index} value={index}>
                  {choice}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <DifficultySelect
            value={currentAssociatedQuestion.difficultyLevel}
            onChange={(e) =>
              handleCurrentAssociatedQuestionChange(e, "difficultyLevel")
            }
          />
        </Grid>

        <Grid item xs={12}>
          <StyledTextarea
            id="explanation"
            name="explanation"
            minRows={2}
            maxRows={10}
            value={currentAssociatedQuestion.explanation}
            onChange={(e) =>
              handleCurrentAssociatedQuestionChange(e, "explanation")
            }
            placeholder="الشرح"
            aria-label="الشرح"
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions
      sx={{
        borderTop: "1px solid rgba(0, 0, 0, 0.3)",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.5)",
        padding: "10px",
        borderRadius: 2,
        justifyContent: "space-between",
      }}
    >
      <Grid container display="flex" alignItems="space-between">
        <Grid item xs={6} container>
          <IconButton
            onClick={toggleSideDialog}
            sx={{
              border: "1px solid",
              borderRadius: "5px",
              padding: "5px 20px",
              color: "#d47d25",
            }}
          >
            <CloseIcon />
            <Typography variant="body1" sx={{ marginRight: 1 }}>
              إلغاء
            </Typography>
          </IconButton>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <IconButton
            onClick={() => submitAssociatedQuestion(currentAssociatedQuestion)}
            sx={{
              border: "1px solid",
              borderRadius: "5px",
              padding: "5px 20px",
              color: "#2b3c53",
            }}
          >
            <CheckIcon />
            <Typography variant="body1" sx={{ marginRight: 1 }}>
              اضافة
            </Typography>
          </IconButton>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
);

export default AssociatedQuestionForm;
