import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ListItemIcon,
  Box,
  IconButton,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { motion, AnimatePresence } from 'framer-motion';
import QuestionText from '../../Utilities/QuestionText';
import WarningIcon from '@mui/icons-material/Warning';
import { Colors } from '../../Utilities/Colors';
import FlagQuestion from './FlagQuestion';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

const fadeInOutVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: 'easeIn',
    },
  },
};

const QuestionAccordion = ({ question, idx, currentPage, startingCount }) => {
  let currentCount = startingCount;
  const [expanded, setExpanded] = useState(
    question.type === 'READING_COMPREHENSION'
  );
  const [flagModalOpen, setFlagModalOpen] = useState(false);

  const determineComprehensiveIcon = () => {
    const associated = question.associatedQuestions;
    if (!associated || associated.length === 0) {
      return <ErrorIcon style={{ color: Colors.brightOrange }} />;
    }

    const correctCount = associated.filter((q) => q.correct).length;

    if (correctCount === associated.length) {
      return <CheckCircleIcon style={{ color: '#4caf50' }} />;
    } else if (correctCount === 0) {
      return <ErrorIcon style={{ color: Colors.brightOrange }} />;
    } else {
      return <WarningIcon style={{ color: '#ffeb3b' }} />;
    }
  };

  const getComprehensionQuestionRange = () => {
    if (question.type === 'READING_COMPREHENSION') {
      const numberOfQuestions = question.associatedQuestions?.length || 0;
      return {
        start: currentCount,
        end: currentCount + numberOfQuestions - 1,
      };
    }
    return { start: currentCount, end: currentCount };
  };

  const questionRange = getComprehensionQuestionRange();

  const handleAccordionChange = (_, newExpanded) => {
    setExpanded(newExpanded);
  };

  const handleFlagClick = (event) => {
    event.stopPropagation();
    setFlagModalOpen(true);
  };

  const handleFlagModalClose = () => {
    setFlagModalOpen(false);
  };

  return (
    <AnimatePresence key={question.id} wait>
      <motion.div
        key={currentPage}
        variants={fadeInOutVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <Accordion
          expanded={expanded}
          onChange={handleAccordionChange}
          key={idx}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${idx}-content`}
            id={`panel${idx}-header`}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                onClick={handleFlagClick}
                sx={{ color: Colors.darkBlue, bgcolor: 'transparent' }}
              >
                <FlagOutlinedIcon />
              </IconButton>

              <ListItemIcon>
                {question.type !== 'READING_COMPREHENSION' ? (
                  question.correct ? (
                    <CheckCircleIcon style={{ color: '#4caf50' }} />
                  ) : (
                    <ErrorIcon style={{ color: Colors.brightOrange }} />
                  )
                ) : (
                  determineComprehensiveIcon()
                )}
              </ListItemIcon>
              <Typography
                component={'span'}
                sx={{
                  color: Colors.darkBlue,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {question.type !== 'READING_COMPREHENSION' ? (
                  <QuestionText
                    text={question.text}
                    type={question.type}
                    count={`${currentCount++}`}
                  />
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                    }}
                  >
                    <span>
                      سؤال فهم المقروء الأسئلة {questionRange.start} -{' '}
                      {questionRange.end}
                    </span>
                    {question.text.split('#').map((paragraph, index) => (
                      <span
                        key={index}
                        style={{
                          marginLeft: '8px',
                          marginTop: '8px',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {paragraph}
                      </span>
                    ))}
                  </Box>
                )}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              flexDirection: 'column',
              padding: '16px',
              backgroundColor: Colors.brightGray,
            }}
          >
            {question.type !== 'READING_COMPREHENSION' ? (
              <Typography>
                الإجابة المختارة: {question.yourAnswer} <br />
                الإجابة الصحيحة: {question.choices[question.correctAnswer]}
                <br />
                {question.explanation && (
                  <>
                    توضيح:
                    {question.explanation.split('#').map((line, index) => (
                      <span key={index}>
                        {line}
                        <br />
                      </span>
                    ))}
                  </>
                )}
              </Typography>
            ) : (
              <>
                {question.associatedQuestions.map((associated, assocIdx) => (
                  <Accordion key={assocIdx}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${idx}-assoc${currentCount}-content`}
                      id={`panel${idx}-assoc${currentCount}-header`}
                    >
                      <ListItemIcon>
                        {associated.correct ? (
                          <CheckCircleIcon style={{ color: '#4caf50' }} />
                        ) : (
                          <ErrorIcon style={{ color: Colors.brightOrange }} />
                        )}
                      </ListItemIcon>
                      <Typography>
                        {`سؤال ${currentCount++}: ${associated.text}`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        الإجابة المختارة: {associated.yourAnswer} <br />
                        الإجابة الصحيحة:{' '}
                        {associated.choices[associated.correctAnswer]}
                        <br />
                        {associated.explanation && (
                          <>
                            توضيح:
                            {associated.explanation
                              .split('#')
                              .map((line, index) => (
                                <span key={index}>
                                  {line}
                                  <br />
                                </span>
                              ))}
                          </>
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <FlagQuestion
          open={flagModalOpen}
          handleClose={handleFlagModalClose}
          question={question}
        />
      </motion.div>
    </AnimatePresence>
  );
};

export default QuestionAccordion;
