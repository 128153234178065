import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import ExamStart from './Components/ExamStart/ExamStart';
import Register from './Components/Register/Register';
import Login from './Components/Login/Login';
import ExamEnd from './Components/ExamEnd/ExamEnd';
import PracticeSelection from './Components/PracticeSelection/PracticeSelection';
import PersonalArea from './Components/PersonalArea/PersonalArea';
import Dashboard from './Components/Admin/Dashboard';
import UsersManagement from './Components/Admin/UsersManagement/UsersManagement';
import SubscriptionPage from './Components/Subscribe/SubscriptionPage';
import SubscriptionsManagement from './Components/Admin/Subscriptions/SubcriptionsManagement';
import Subscribe from './Components/Subscribe/Subscribe';
import DataManagement from './Components/Admin/DataManagement/DataManagement';
import { AuthProvider } from './Contexts/AuthContext';
import ProtectedRoute from './Components/Routes/ProtectedRoute';
import TransactionSuccess from './Components/Subscribe/TransactionSuccess';
import TransactionFail from './Components/Subscribe/TransactionFail';
import PaymentSuccess from './Components/Subscribe/PaymentSuccess';
import PaymentFail from './Components/Subscribe/PaymentFail';
import PasswordReset from './Components/Login/PasswordReset';
import CompositionWriting from './Components/Composition/CompositionWriting';
import CompositionManagement from './Components/Admin/CompositionsManagement/CompositionsManagement/CompositionsManagement';
import CompositionStart from './Components/Composition/CompositionStart';
import Information from './Components/Information/Information';
import Contact from './Components/Contact/Contact';
import ErrorPage from './Components/ErrorPage/ErrorPage';
import MaintenanceScreen from './Components/Home/MaintenanceScreen';
import Footer from './Components/Home/Footer';

const routes = [
  { path: '/', element: <Home /> },
  { path: '/auth/login', element: <Login /> },
  { path: '/information', element: <Information /> },
  { path: '/contact', element: <Contact /> },
  {
    path: '/unauthorized',
    element: (
      <ErrorPage
        errorCode="403"
        title="عفوًا، ليس لديك الصلاحية لعرض هذه الصفحة"
        message="يرجى التحقق من صلاحياتك أو الاتصال بمسؤول النظام إذا كنت تعتقد أن هذا
  خطأ."
      />
    ),
  },
  {
    path: '/auth/register',
    element: <Register />,
  },
  { path: '/auth/password/reset', element: <PasswordReset /> },
  {
    path: '/subscriptions/plans',
    element: <SubscriptionPage />,
  },
  {
    path: '/subscriptions/subscribe',
    element: (
      <ProtectedRoute
        roles={['USER', 'FREEMIUM', 'BRONZE', 'SILVER', 'GOLD', 'ADMIN']}
      >
        <Subscribe />
      </ProtectedRoute>
    ),
  },
  {
    path: '/transaction/fail/*',
    element: (
      <ProtectedRoute
        roles={['USER', 'FREEMIUM', 'BRONZE', 'SILVER', 'GOLD', 'ADMIN']}
      >
        <TransactionFail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/transaction/success/*',
    element: (
      <ProtectedRoute
        roles={['USER', 'FREEMIUM', 'BRONZE', 'SILVER', 'GOLD', 'ADMIN']}
      >
        <TransactionSuccess />
      </ProtectedRoute>
    ),
  },
  {
    path: '/subscriptions/payment/success',
    element: (
      <ProtectedRoute
        roles={['USER', 'FREEMIUM', 'BRONZE', 'SILVER', 'GOLD', 'ADMIN']}
      >
        <PaymentSuccess />
      </ProtectedRoute>
    ),
  },
  {
    path: '/subscriptions/payment/fail',
    element: (
      <ProtectedRoute
        roles={['USER', 'FREEMIUM', 'BRONZE', 'SILVER', 'GOLD', 'ADMIN']}
      >
        <PaymentFail />
      </ProtectedRoute>
    ),
  },
  {
    path: '/practice/selection',
    element: (
      <ProtectedRoute
        roles={['USER', 'FREEMIUM', 'BRONZE', 'SILVER', 'GOLD', 'ADMIN']}
      >
        <PracticeSelection />
      </ProtectedRoute>
    ),
  },
  {
    path: '/exam/initiate',
    element: (
      <ProtectedRoute
        roles={['USER', 'FREEMIUM', 'BRONZE', 'SILVER', 'GOLD', 'ADMIN']}
      >
        <ExamStart />
      </ProtectedRoute>
    ),
  },
  {
    path: '/exam/initiate/:examId',
    element: (
      <ProtectedRoute
        roles={['USER', 'FREEMIUM', 'BRONZE', 'SILVER', 'GOLD', 'ADMIN']}
      >
        <ExamStart />
      </ProtectedRoute>
    ),
  },
  {
    path: '/exam/end',
    element: (
      <ProtectedRoute
        roles={['USER', 'FREEMIUM', 'BRONZE', 'SILVER', 'GOLD', 'ADMIN']}
      >
        <ExamEnd />
      </ProtectedRoute>
    ),
  },
  {
    path: '/exam/end/:examId',
    element: (
      <ProtectedRoute
        roles={['USER', 'FREEMIUM', 'BRONZE', 'SILVER', 'GOLD', 'ADMIN']}
      >
        <ExamEnd />
      </ProtectedRoute>
    ),
  },
  {
    path: '/composition/upload',
    element: (
      <ProtectedRoute roles={['BRONZE', 'SILVER', 'GOLD', 'ADMIN']}>
        <CompositionWriting />
      </ProtectedRoute>
    ),
  },
  {
    path: '/composition/initiate',
    element: (
      <ProtectedRoute roles={['BRONZE', 'SILVER', 'GOLD', 'ADMIN']}>
        <CompositionStart />
      </ProtectedRoute>
    ),
  },
  {
    path: '/me',
    element: (
      <ProtectedRoute
        roles={['USER', 'FREEMIUM', 'BRONZE', 'SILVER', 'GOLD', 'ADMIN']}
      >
        <PersonalArea />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/dashboard',
    element: (
      <ProtectedRoute roles={['ADMIN']} redirectPath="/unauthorized">
        <Dashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/users/management',
    element: (
      <ProtectedRoute roles={['ADMIN']} redirectPath="/unauthorized">
        <UsersManagement />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/subscriptions/management',
    element: (
      <ProtectedRoute roles={['ADMIN']} redirectPath="/unauthorized">
        <SubscriptionsManagement />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/data/management',
    element: (
      <ProtectedRoute roles={['ADMIN']} redirectPath="/unauthorized">
        <DataManagement />
      </ProtectedRoute>
    ),
  },
  {
    path: '/admin/compositions/management',
    element: (
      <ProtectedRoute roles={['ADMIN']} redirectPath="/unauthorized">
        <CompositionManagement />
      </ProtectedRoute>
    ),
  },
  {
    path: '*',
    element: (
      <ErrorPage
        errorCode="404"
        title=" انطلقت على مغامرة خارج الخريطة!"
        message="الرابط الذي تبعته قد يكون مكسورًا، أو قد تم نقل الصفحة. لما لا نستكشف
  بقية الكون؟"
      />
    ),
  },
];

function App() {
  const maintenanceMode = false;

  return (
    <Router>
      <AuthProvider>
        <div
          className="App"
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <NavBar />
          <div
            style={{
              flex: 1,
            }}
          >
            <Routes>
              {maintenanceMode ? (
                <Route path="*" element={<MaintenanceScreen />} />
              ) : (
                routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                ))
              )}
            </Routes>
          </div>
          <Footer />
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
