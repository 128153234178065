import React, { useEffect, useState } from "react";
import { Grid, Container } from "@mui/material";
import Swal from "sweetalert2";

import {
  getLastCompositionByUserId,
  initiateComposition,
} from "../../Services/CompositionService";
import { useAuth } from "../../Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import CompositionButtons from "./CompositionButtons";
import CompositionGeneralInfo from "./CompositionGeneralInfo";
import TipsAndTricks from "./TipsAndTricks";
import Guidelines from "./Guidelines";

const CompositionStart = () => {
  const { user } = useAuth();
  const [unsubmittedComposition, setUnsubmittedComposition] = useState(false);
  const [lastComposition, setLastComposition] = useState(null);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [isCreatingComposition, setisCreatingComposition] = useState(false);

  useEffect(() => {
    const checkUnsubmittedComposition = async () => {
      try {
        setIsLoading(true);
        const retrievedComposition = await getLastCompositionByUserId(user.id);
        setLastComposition(retrievedComposition);
        if (retrievedComposition && !retrievedComposition.submitted) {
          setUnsubmittedComposition(true);
        }
      } catch (e) {
        if (e.message !== "No ongoing composition found") {
          Swal.fire("Error", e.message, "error");
        }
      } finally {
        setIsLoading(false);
      }
    };

    checkUnsubmittedComposition();
  }, [user.id]);

  const handleStartComposition = async () => {
    if (!unsubmittedComposition) {
      try {
        setisCreatingComposition(true);
        await initiateComposition(user.id);
        navigate("/composition/upload");
      } catch (e) {
        Swal.fire("Error", e.message, "error");
      } finally {
        setisCreatingComposition(false);
      }
    } else {
      navigate("/composition/upload");
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={4} container alignItems="stretch">
          <CompositionButtons
            unsubmittedComposition={unsubmittedComposition}
            handleStartComposition={handleStartComposition}
            lastComposition={lastComposition}
            isLoading={isLoading}
            isCreatingComposition={isCreatingComposition}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <CompositionGeneralInfo />
        </Grid>

        <Grid item xs={12} md={5}>
          <TipsAndTricks />
        </Grid>

        <Grid item xs={12} md={7}>
          <Guidelines />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CompositionStart;
