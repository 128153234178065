import { useState, useEffect } from 'react';
import { getAllComprehensionQuestions } from '../Services/QuestionService';
import Swal from 'sweetalert2';

const useComprehensionQuestions = () => {
  const [comprehensionQuestions, setComprehensionQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalQuestionsCount, setTotalQuestionsCount] = useState(0);
  const [currentComprehensivePage, setCurrentComprehensivePage] = useState(0);

  useEffect(() => {
    fetchComprehensionQuestions(currentComprehensivePage, 5);
  }, [currentComprehensivePage]);

  const fetchComprehensionQuestions = async (page = 0, size = 5) => {
    setLoading(true);
    try {
      const comprehensionResponse = await getAllComprehensionQuestions(
        page,
        size
      );
      const comprehensionContent = comprehensionResponse?.content || [];

      setTotalQuestionsCount(comprehensionResponse?.totalElements || 0);

      if (comprehensionContent.length > 0) {
        setComprehensionQuestions(comprehensionContent);
        setTotalPages(comprehensionResponse?.totalPages || 0);
      } else {
        console.warn('No comprehensive questions found.');
      }
    } catch (error) {
      console.error('Error fetching comprehension questions:', error.message);
      Swal.fire({
        title: 'خطأ!',
        text: 'فشل في جلب أسئلة الفهم. يرجى المحاولة مرة أخرى.',
        icon: 'error',
        confirmButtonText: 'حسنًا',
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    comprehensionQuestions,
    loading,
    fetchComprehensionQuestions,
    totalPages,
    totalQuestionsCount,
    currentComprehensivePage,
    setCurrentComprehensivePage,
  };
};

export default useComprehensionQuestions;
