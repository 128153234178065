import React from "react";
import { Skeleton, Paper, Box } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";

const ExamChartSkeleton = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Paper elevation={5} sx={{ borderRadius: 1, height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "100%",
          maxHeight: "350px",
          borderRadius: 1,
          margin: 2,
          padding: 2,
        }}
      >
        <Skeleton
          variant="text"
          width={150}
          height={30}
          sx={{
            marginBottom: 2,
          }}
        />
        <Skeleton
          variant="rectangular"
          height={300}
          width={isSmallScreen ? "90%" : "80%"}
          animation="pulse"
          sx={{
            marginLeft: {
              xs: 5,
              md: theme.spacing(3),
            },
            alignSelf: "center",
          }}
        />
      </Box>
    </Paper>
  );
};

export default ExamChartSkeleton;
