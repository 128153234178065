import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  Box,
} from '@mui/material';
import { motion } from 'framer-motion';
import { Colors } from '../../Utilities/Colors';

const featuresData = [
  {
    title: 'رزمة كتب كورس יע"ל',
    description:
      'استفد من مجموعتنا الفريدة من الكتب التعليمية التي تغطي جميع جوانب الكورس، مُعدة خصيصًا لتعزيز فهمك وتحضيرك للامتحان بشكل شامل.',
    icon: '/assets/icons/book.png',
  },
  {
    title: 'اختبارات عملية تحاكي الواقع',
    description:
      'خوض تجربة اختبارات مصممة لتحاكي ظروف الامتحانات الحقيقية، مما يمنحك فرصة التحضير المثالي لمواجهة التحديات الفعلية للامتحان.',
    icon: '/assets/icons/question.png',
  },
  {
    title: 'رسوم بيانية وتحليل لنتائج الامتحان',
    description:
      'تابع تقدمك بشكل مستمر من خلال رسوم بيانية دقيقة توضح أدائك في الامتحانات، مع تحليلات على نقاط القوة والضعف في كل امتحان.',
    icon: '/assets/icons/data-research.png',
  },
  {
    title: 'كتابة مواضيع الإنشاء  ',
    description:
      'اختبر مهاراتك في كتابة الإنشاء في بيئة محاكاة للواقع، توفر ظروفاً تشبه التي ستواجهها في الاختبار الفعلي.',
    icon: '/assets/icons/content.png',
  },
  {
    title: 'حلول مفصلة لأسئلة الامتحانات',
    description:
      'اطلع على حلول مفصلة للأسئلة، مُعدة لتوفير فهم أعمق للموضوعات وتعزيز مهاراتك، ما يمكنك من التقدم بثقة نحو تحقيق أفضل النتائج.',
    icon: '/assets/icons/search.png',
  },
];

const Features = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container
      sx={{
        overflow: 'hidden',
      }}
      disableGutters
      maxWidth="xl"
    >
      <Grid
        container
        spacing={0}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          margin: 0,
          overflow: 'hidden',
        }}
      >
        {featuresData.map((feature, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 0,
              width: '100%',
            }}
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <Card
                elevation={0}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  p: 1,
                  height: `${isMobile ? '200px' : '300px'}`,
                  width: '300px',
                  backgroundColor: Colors.inputColor,
                  ':hover': {
                    boxShadow: 'none',
                  },
                }}
              >
                <Box
                  sx={{
                    height: `${isMobile ? '34px' : '54px'}`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: '20px',
                  }}
                >
                  <img
                    src={feature.icon}
                    alt="Feature icon"
                    style={{
                      width: isMobile ? 34 : 54,
                      height: isMobile ? 34 : 54,
                    }}
                  />
                </Box>
                <CardContent
                  sx={{
                    textAlign: 'center',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: 2,
                    mt: isMobile ? 0 : 2,
                  }}
                >
                  <Typography
                    variant="body1"
                    gutterBottom
                    noWrap
                    sx={{
                      color: Colors.darkBlue,
                      fontFamily: 'ExpoArabic',
                      fontWeight: 'bold',
                      fontSize: {
                        xs: '12px',
                        sm: '14px',
                        md: '16px',
                      },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      width: '100%',
                    }}
                  >
                    {feature.title}
                  </Typography>

                  <Divider sx={{ width: '20%', mt: 1, mb: 2 }} />
                  <Typography
                    variant={isMobile ? 'caption' : 'body2'}
                    sx={{ color: Colors.darkBlue, fontFamily: 'ExpoArabic' }}
                  >
                    {feature.description}
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Features;
