import React, { useState } from "react";
import {
  Typography,
  Divider,
  Paper,
  Grid,
  Box,
  Stack,
  Pagination,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import QuantitativePerk from "./PerksDetails/QuantitativePerk";
import ContinuousPerk from "./PerksDetails/ContinuousPerk";
import { timeUtil } from "../../Utilities/timeUtil";

const SubscriptionDetails = ({ subscription, perks }) => {
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handlePageChange = (_, value) => {
    setPage(value);
  };

  const perkDetail = perks && perks.length >= page ? perks[page - 1] : null;

  // Existing calculation for regeneration time and current time
  const regenerationTime = perkDetail
    ? new Date(perkDetail.regenerationTime)
    : null;
  const currentTime = new Date();

  // Determine if the perk is regenerated
  const isRegenerated = regenerationTime
    ? currentTime >= regenerationTime
    : false;

  // Calculate the time difference message
  const timeDifferenceMessage = regenerationTime
    ? timeUtil.inHoursAndMinutes(currentTime, regenerationTime)
    : "";

  const regenerationMessage = isRegenerated
    ? "تم التجديد بالكامل"
    : regenerationTime
    ? `يتجدد في: ${timeDifferenceMessage}`
    : "";

  const progressValue = isRegenerated
    ? 0
    : perkDetail
    ? ((perkDetail.usage?.quantityUsed || 0) / perkDetail.perk.quantity) * 100
    : 0;

  return (
    <Paper
      elevation={5}
      sx={{
        p: 4,
        mb: 2,
        overflow: "hidden",
        borderRadius: 2,
        height: isMobile ? "430px" : "380px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        item
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={4} container justifyContent="flex-start">
          <Stack spacing={1}>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              gutterBottom
              sx={{
                textAlign: "center",
              }}
            >
              {subscription
                ? timeUtil.formatDate(subscription.subscriptionStartDate)
                : "N/A"}
            </Typography>

            <Typography
              variant={isMobile ? "caption" : "body2"}
              color="textSecondary"
              gutterBottom
              align="center"
            >
              بداية الاشتراك
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={4}>
          <Box textAlign="center">
            <Typography
              variant={isMobile ? "subtitle1" : "h5"}
              sx={{ fontWeight: "bold" }}
            >
              {subscription?.subscriptionPlan.name || ""}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={4} container justifyContent="flex-end">
          <Stack spacing={1}>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              gutterBottom
              sx={{
                textAlign: "center",
              }}
            >
              {subscription
                ? timeUtil.formatDate(subscription.subscriptionEndDate)
                : "N/A"}
            </Typography>

            <Typography
              variant={isMobile ? "caption" : "body2"}
              color="textSecondary"
              gutterBottom
              align="center"
            >
              نهاية الاشتراك
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Divider
        sx={{
          mt: 2,
          width: "100%",
        }}
      />

      <Grid
        container
        spacing={2}
        alignContent="flex-start"
        sx={{
          overflowY: "auto",
          p: 1,
          flexGrow: 1,
        }}
      >
        {perkDetail ? (
          perkDetail.perk.nature === "QUANTITATIVE" ? (
            <Grid item xs={12}>
              <QuantitativePerk
                perkDetail={perkDetail}
                progressValue={progressValue}
                regenerationMessage={regenerationMessage}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <ContinuousPerk perkDetail={perkDetail} />
            </Grid>
          )
        ) : (
          <Grid item xs={12}>
            <Typography align="center">No perks available</Typography>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          mt: "auto",
        }}
      >
        <Grid item xs={12}>
          <Pagination
            count={perks.length}
            page={page}
            onChange={handlePageChange}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SubscriptionDetails;
