import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Divider,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import AssociatedQuestions from './ComprehensiveQuestion/AssociatedQuestions';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import useEditQuestion from '../../../Hooks/useEditQuestion';
import { StyledTextarea } from './Inputs/StyledTextarea';
import QuestionTypeSelect from './Inputs/QuestionTypeSelect';
import DifficultySelect from './Inputs/DifficultySelect';
import Loader from '../../../loaders/loader.svg';

const EditQuestion = ({ open, onClose, initialData, onSubmit }) => {
  const {
    question,
    currentSubQuestionIndex,
    handleChange,
    addAssociatedQuestion,
    removeAssociatedQuestion,
    handleAssociatedQuestionChange,
    handleSave,
    setCurrentSubQuestionIndex,
    isSaving,
    resetSubQuestionIndex,
  } = useEditQuestion(initialData, onSubmit, onClose);

  const handleClose = () => {
    resetSubQuestionIndex();
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      {isSaving && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bgcolor="rgba(255, 255, 255, 0.7)"
          zIndex={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box textAlign="center">
            <img src={Loader} alt="Saving..." />
            <Typography variant="h6" component="p" mt={2}>
              جارٍ حفظ السؤال...
            </Typography>
          </Box>{' '}
        </Box>
      )}
      <DialogTitle
        textAlign="center"
        sx={{
          borderBottom: '1px solid rgba(0, 0, 0, 0.3)',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
          padding: '10px',
          borderRadius: 2,
        }}
      >
        تعديل السؤال
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          sx={{
            mt: 1,
            p: 2,
            borderRadius: 8,
            backgroundColor: '#ffffff',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
            transition: 'box-shadow 0.3s ease',
            '&:hover': {
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)',
            },
          }}
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              نص السؤال
            </Typography>
            <StyledTextarea
              id="text"
              name="text"
              minRows={3}
              maxRows={9}
              value={question.text}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={4}>
            <DifficultySelect
              value={question.difficultyLevel}
              onChange={handleChange}
            />
          </Grid>

          {!question.associatedQuestions && (
            <>
              <Grid item xs={4}>
                <QuestionTypeSelect
                  value={question.type}
                  handleChange={handleChange}
                  restrict={true}
                />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            <Divider />
          </Grid>

          {/* If it's a comprehensive question, show associated questions */}
          {question.associatedQuestions && (
            <>
              {/* Display only the current sub-question */}
              {currentSubQuestionIndex <
                question.associatedQuestions.length && (
                <AssociatedQuestions
                  associatedQuestions={question.associatedQuestions}
                  currentSubQuestionIndex={currentSubQuestionIndex}
                  handleAssociatedQuestionChange={
                    handleAssociatedQuestionChange
                  }
                  removeAssociatedQuestion={removeAssociatedQuestion}
                  addAssociatedQuestion={addAssociatedQuestion}
                  setCurrentSubQuestionIndex={setCurrentSubQuestionIndex}
                />
              )}
            </>
          )}

          {!question.associatedQuestions && (
            <>
              {[
                'الخيار الأول',
                'الخيار الثاني',
                'الخيار الثالث',
                'الخيار الرابع',
              ].map((label, index) => (
                <Grid key={index} item xs={12} md={6}>
                  <TextField
                    label={label}
                    name={`choices[${index}]`}
                    value={question.choices[index]}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              ))}
            </>
          )}
          {!question.associatedQuestions && (
            <>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>الإجابة الصحيحة</InputLabel>
                  <Select
                    name="correctAnswer"
                    value={question.choices[question.correctAnswer]}
                    onChange={(e) =>
                      handleChange({
                        target: {
                          name: 'correctAnswer',
                          value: question.choices.indexOf(e.target.value),
                        },
                      })
                    }
                  >
                    {question.choices.map((choice, index) => (
                      <MenuItem key={index} value={choice}>
                        {choice}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
          {!question.associatedQuestions && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <StyledTextarea
                    id="explanation"
                    name="explanation"
                    minRows={2}
                    maxRows={10}
                    value={question.explanation}
                    onChange={handleChange}
                    placeholder="الشرح"
                    aria-label="الشرح"
                  />
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          borderTop: '1px solid rgba(0, 0, 0, 0.3)',
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.5)',
          padding: '10px',
          borderRadius: 2,
        }}
      >
        <Grid container display="flex" alignItems="space-between">
          <Grid item xs={6} container>
            <IconButton
              onClick={handleClose}
              sx={{
                border: '1px solid',
                borderRadius: '5px',
                padding: '5px 20px',
                color: '#d47d25',
              }}
            >
              <CancelIcon />
              <Typography variant="body1" sx={{ marginRight: 1 }}>
                إلغاء
              </Typography>
            </IconButton>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <IconButton
              onClick={handleSave}
              sx={{
                border: '1px solid',
                borderRadius: '5px',
                padding: '5px 20px',
                color: '#2b3c53',
              }}
            >
              <SaveIcon />
              <Typography variant="body1" sx={{ marginRight: 1 }}>
                حفظ
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default EditQuestion;
