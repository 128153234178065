import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material";

const DifficultySelect = ({ value, onChange }) => {
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel htmlFor="difficulty-level"> الصعوبة </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label="الصعوبة "
        input={
          <OutlinedInput
            label="الصعوبة "
            name="difficultyLevel"
            id="difficulty-level"
          />
        }
        sx={{
          backgroundColor: "#fafafa",
        }}
      >
        <MenuItem value={"EASY"}>سهل</MenuItem>
        <MenuItem value={"MEDIUM"}>متوسط</MenuItem>
        <MenuItem value={"HARD"}>صعب</MenuItem>
        <MenuItem value={"NOT_SPECIFIED"}>غير محدد</MenuItem>
      </Select>
    </FormControl>
  );
};

export default DifficultySelect;
