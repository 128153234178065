import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  Typography,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import { Colors } from "../../../../Utilities/Colors";
import { StyledTextarea } from "../Inputs/StyledTextarea";
import EditQuestion from "../EditQuestion";
import { timeUtil } from "../../../../Utilities/timeUtil";
import CustomDialogActions from "../../Subscriptions/CustomDialogActions";
import { updateFlagStatus } from "../../../../Services/FlagService";
import Loader from "../../../../loaders/loader.svg";

const FlagDetailDialog = ({ open, handleClose, flag, fetchFlags }) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [reviewComment, setReviewComment] = useState(flag?.reviewComment || ""); // initialize from flag or as empty
  const [status, setStatus] = useState(flag?.status || "PENDING");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEditClick = () => {
    setEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    handleClose();
    setEditDialogOpen(false);
  };

  const handleUpdate = () => {
    setEditDialogOpen(false);
    fetchFlags();
  };

  useEffect(() => {
    setReviewComment(flag?.reviewComment || "");
  }, [flag]);

  const handleSubmitChanges = async () => {
    try {
      setIsSubmitting(true);
      const updatedFlag = {
        ...flag,
        status,
        reviewComment,
      };

      await updateFlagStatus(updatedFlag);
      fetchFlags();
      handleClose();
    } catch (error) {
      console.error("Failed to update flag:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
    >
      {isSubmitting && (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bgcolor="rgba(255, 255, 255, 0.7)"
          zIndex={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box textAlign="center">
            <img src={Loader} alt="Saving..." />
            <Typography variant="h6" component="p" mt={2}>
              جارٍ حفظ التعديلات...
            </Typography>
          </Box>
        </Box>
      )}
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          backgroundColor: Colors.darkBlue,
          color: Colors.brightOrange,
          py: 1,
          textAlign: "center",
        }}
      >
        تفاصيل البَلاغ
      </DialogTitle>
      <DialogContent>
        {flag && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                sx={{ color: Colors.darkBlue, mb: 1, mt: 1 }}
              >
                نص البَلاغ
              </Typography>
              <StyledTextarea
                value={flag.feedback}
                disabled
                aria-label="feedback"
                minRows={3}
                maxRows={6}
                sx={{
                  backgroundColor: Colors.inputColor,
                  color: Colors.darkBlue,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="body1"
                sx={{ color: Colors.independence, textAlign: "center" }}
              >
                {flag.fullName}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: Colors.independence, textAlign: "center" }}
              >
                إسم المُبَلِغ
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="body1"
                sx={{ color: Colors.independence, textAlign: "center" }}
              >
                {timeUtil.formatDate(flag.flaggedAt)}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: Colors.independence, textAlign: "center" }}
              >
                تاريخ البلاغ
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Select
                value={status}
                onChange={handleStatusChange}
                fullWidth
                displayEmpty
                sx={{
                  color: Colors.independence,
                  textAlign: "left",
                  height: "40px",
                  borderColor: Colors.darkBlue,
                  mb: 1,
                }}
              >
                <MenuItem value={"PENDING"}>قيد الانتظار</MenuItem>
                <MenuItem value={"REVIEWED"}>تمت المراجعة</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={6}>
              <Button
                onClick={handleEditClick}
                fullWidth
                variant="outlined"
                sx={{
                  color: Colors.darkBlue,
                  ":hover": {
                    backgroundColor: Colors.darkBlue,
                    color: Colors.brightGray,
                  },
                  height: "40px",
                  borderColor: Colors.darkBlue,
                  mb: 1,
                }}
              >
                تحرير السؤال
              </Button>
            </Grid>

            {flag.reviewedAt && (
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{ color: Colors.independence, textAlign: "center" }}
                >
                  {timeUtil.formatDate(flag.reviewedAt)}
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ color: Colors.independence, textAlign: "center" }}
                >
                  تمت المراجعة في
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <StyledTextarea
                aria-label="review comment"
                placeholder="أضف تعليقًا حول مراجعتك هنا، هذا سوف يرسل للمستخدم. (اختياري)"
                value={reviewComment}
                onChange={(e) => setReviewComment(e.target.value)}
                minRows={3}
                maxRows={6}
                sx={{
                  backgroundColor: Colors.inputColor,
                  borderColor: Colors.mediumBlue,
                  color: Colors.darkBlue,
                }}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <CustomDialogActions
        onCancel={handleClose}
        onConfirm={handleSubmitChanges}
      />
      {flag && editDialogOpen && (
        <EditQuestion
          open={editDialogOpen}
          onClose={handleCloseEditDialog}
          initialData={flag.questionDTO}
          onSubmit={handleUpdate}
        />
      )}
    </Dialog>
  );
};

export default FlagDetailDialog;
