import React, { useEffect } from "react";
import Modal from "@mui/material/Modal";

const IframeModal = ({ open, onClose, iframeURL }) => {
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === "transactionSuccess") {
        window.top.location.href = "/subscriptions/payment/success";
        onClose();
      }
      if (event.data === "transactionFail") {
        window.top.location.href = "/subscriptions/payment/fail";
        onClose();
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="iframe-modal-title"
      aria-describedby="iframe-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <iframe
        src={iframeURL}
        width="370px"
        height="550px"
        title="Payment Frame"
        id="paymentFrameId"
      ></iframe>
    </Modal>
  );
};

export default IframeModal;
