import * as Yup from 'yup';

// Regular expression to match Arabic, English, and Hebrew letters
const nameRegex = /^[a-zA-Z\u0590-\u05FF\u0621-\u064A]+$/;
const gradeRegex = /^[1-9][0-2]?$/; // Assuming grade is between 1 to 12
const numberRegex = /^\d+$/; // Matches only digits
const fullNameRegex = /^[a-zA-Z\u0590-\u05FF\u0621-\u064A\s]+$/; // Regular expression to match Arabic, English, Hebrew letters and spaces for full names

export const registrationSchema = Yup.object({
  email: Yup.string()
    .email('عنوان البريد الإلكتروني غير صالح')
    .required('مطلوب'),
  firstName: Yup.string()
    .min(2, 'يجب أن يكون 2 حروف على الأقل')
    .required('مطلوب')
    .test('no-spaces', 'المسافات غير مسموح بها', (value) => !/\s/.test(value))
    .test(
      'valid-characters',
      'يجب أن يحتوي على حروف عربية أو إنجليزية أو عبرية فقط',
      (value) => nameRegex.test(value)
    ),
  lastName: Yup.string()
    .min(2, 'يجب أن يكون 2 حروف على الأقل')
    .required('مطلوب')
    .test('no-spaces', 'المسافات غير مسموح بها', (value) => !/\s/.test(value))
    .test(
      'valid-characters',
      'يجب أن يحتوي على حروف عربية أو إنجليزية أو عبرية فقط',
      (value) => nameRegex.test(value)
    ),
  password: Yup.string()
    .min(8, 'يجب أن يتكون من 8 أحرف أو أكثر')
    .required('مطلوب'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'يجب أن تتطابق كلمات المرور')
    .required('مطلوب'),
  phoneNumber: Yup.string()
    .matches(/^\d+$/, 'يجب أن يحتوي على أرقام فقط')
    .required('مطلوب'),
});

export const loginSchema = Yup.object({
  email: Yup.string()
    .email('عنوان البريد الإلكتروني غير صالح')
    .required('مطلوب'),
  password: Yup.string().required('مطلوب'),
});

export const contactSchema = Yup.object({
  name: Yup.string()
    .min(2, 'الاسم قصير جدًا!')
    .max(50, 'الاسم طويل جدًا!')
    .required('هذا الحقل مطلوب'),
  email: Yup.string()
    .email('عنوان البريد الإلكتروني غير صحيح')
    .required('هذا الحقل مطلوب'),
  message: Yup.string()
    .min(5, 'الرسالة قصيرة جدًا!')
    .required('هذا الحقل مطلوب'),
});

export const courseSignupSchema = Yup.object({
  name: Yup.string()
    .matches(
      fullNameRegex,
      'يجب أن يحتوي على حروف عربية أو إنجليزية أو عبرية فقط'
    )
    .required('مطلوب'),
  grade: Yup.string()
    .matches(gradeRegex, 'الرجاء إدخال صف من 1-12 ')
    .required('مطلوب'),
  number: Yup.string()
    .matches(numberRegex, 'يجب أن يحتوي على أرقام فقط')
    .required('مطلوب'),
});
