import React from 'react';
import {
  Box,
  Typography,
  Button,
  Slide,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../Utilities/Colors';

const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Slide direction="up" in mountOnEnter unmountOnExit>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90vh',
          backgroundColor: Colors.inputColor,
          p: isMobile ? 2 : 5,
          textAlign: 'center',
        }}
      >
        <Typography
          variant={isMobile ? 'h5' : 'h3'}
          component="div"
          sx={{
            color: Colors.darkBlue,
            mb: 2,
            fontWeight: 600,
          }}
        >
          عملية الشراء ناجحة!
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: Colors.mediumBlue,
            mb: 4,
            maxWidth: isMobile ? '80%' : '60%',
            textAlign: 'center',
          }}
        >
          تمت عملية الشراء بنجاح، شكرًا لثقتكم بنا. يمكنك الآن التوجه إلى
          المنطقة الشخصية لمتابعة خدماتنا.
        </Typography>
        <Button
          variant="contained"
          startIcon={<AccountCircleOutlinedIcon sx={{ ml: 1 }} />}
          sx={{
            backgroundColor: Colors.brightOrange,
            fontSize: isMobile ? '0.875rem' : '1rem',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
          onClick={() => {
            navigate('/me');
          }}
        >
          المنطقة الشخصية
        </Button>
      </Box>
    </Slide>
  );
};

export default PaymentSuccessPage;
