import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Box,
  CircularProgress,
  lighten,
} from '@mui/material';
import { useFormik } from 'formik';
import { Colors } from '../../Utilities/Colors';
import Swal from 'sweetalert2';
import { courseSignupSchema } from '../../Utilities/validationSchemas';
import { sendCourseRegisterFormData } from '../../Services/UserService';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

const CourseSignupDialog = ({ open, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      grade: '',
      number: '',
    },
    validationSchema: courseSignupSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        await sendCourseRegisterFormData(values);
        Swal.fire({
          title: 'تم التسجيل بنجاح!',
          text: 'تم إرسال معلومات التسجيل.',
          icon: 'success',
          confirmButtonText: 'حسنا',
          confirmButtonColor: Colors.brightOrange,
        });
        resetForm();
        handleClose();
      } catch (error) {
        Swal.fire({
          title: 'خطأ!',
          text: error.message || 'حدث خطأ أثناء التسجيل.',
          icon: 'error',
          confirmButtonText: 'حسنا',
          confirmButtonColor: Colors.darkBlue,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          backgroundColor: Colors.darkCerulean,
          color: 'white',
          fontFamily: 'ExpoArabic',
          padding: 2,
        }}
      >
        التسجيل للدورة
      </DialogTitle>
      <DialogContent
        sx={{
          bgcolor: Colors.inputColor,
          borderRight: '2px solid #cccccc',
          paddingTop: 2,
          paddingBottom: 3,
          paddingLeft: 3,
          paddingRight: 3,
        }}
      >
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          noValidate
          sx={{
            width: '100%',
            p: 3,
            borderRadius: 2,
            '& .MuiTextField-root': {
              mt: 1,
              mb: 2,
              '& .MuiOutlinedInput-root': {
                fontFamily: 'ExpoArabic',
                borderRadius: '24px',
                borderColor: Colors.electricBlue,
                '& fieldset': {
                  borderColor: Colors.electricBlue,
                },
              },
              '& label': {
                color: '#adadad',
                fontFamily: 'ExpoArabic',
              },
              '& label.Mui-focused': {
                color: Colors.Independence,
              },
            },
          }}
        >
          <TextField
            fullWidth
            id="name"
            name="name"
            label="الاسم الكامل"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            variant="outlined"
          />
          <TextField
            fullWidth
            id="grade"
            name="grade"
            label="الصف"
            value={formik.values.grade}
            onChange={formik.handleChange}
            error={formik.touched.grade && Boolean(formik.errors.grade)}
            helperText={formik.touched.grade && formik.errors.grade}
            variant="outlined"
          />
          <TextField
            fullWidth
            id="number"
            name="number"
            label="رقم الاتصال"
            value={formik.values.number}
            onChange={formik.handleChange}
            error={formik.touched.number && Boolean(formik.errors.number)}
            helperText={formik.touched.number && formik.errors.number}
            variant="outlined"
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              mt: 2,
            }}
          >
            <Button
              type="submit"
              fullWidth
              variant="contained"
              startIcon={isLoading ? null : <SendOutlinedIcon sx={{ ml: 1 }} />}
              disabled={isLoading}
              sx={{
                borderRadius: '24px',
                color: Colors.platinum,
                maxWidth: '200px',
                bgcolor: Colors.chargedBlue,
                fontFamily: 'ExpoArabic',
                fontWeight: 'bold',
                fontSize: '16px',
                '&:hover': {
                  bgcolor: lighten(Colors.chargedBlue, 0.1),
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'تسجيل'
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CourseSignupDialog;
