import { axiosInstance, apiWrapper } from './Service';

export async function registerUser(registrationData) {
  const response = await apiWrapper(
    () => axiosInstance.post('/auth/register', registrationData),
    'User registered successfully',
    'Failed to register user'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function loginUser(email, password) {
  const response = await apiWrapper(
    () => axiosInstance.post('/auth/login', { email, password }),
    'Login successful',
    'Login failed'
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function logoutUser() {
  const response = await apiWrapper(
    () => axiosInstance.post('/auth/logout'),
    'Logout successful',
    'Failed to logout'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function verifyTwoFACode(twoFACode, email) {
  const response = await apiWrapper(
    () => axiosInstance.post('/auth/verify-2fa', { twoFACode, email }),
    '2FA code verified successfully',
    'Failed to verify 2FA code'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getUserInformation() {
  const response = await apiWrapper(
    () => axiosInstance.get('/users/me'),
    'User information retrieved successfully',
    'Failed to fetch user information'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function refreshAccessToken() {
  const response = await apiWrapper(
    () => axiosInstance.post('/auth/refresh'),
    'Access token refreshed successfully',
    'Failed to refresh access token'
  );
  if (response.error) {
    switch (response.status) {
      case 401:
        throw new Error('Refresh token is invalid or expired');
      case 403:
        throw new Error('Forbidden. Access is denied.');
      case 500:
        throw new Error('Internal server error');
      default:
        throw new Error(
          response.error.message || 'An error occurred, please try again later'
        );
    }
  }

  return response.data;
}

export async function updateUserDetails(userId, userDetails) {
  const response = await apiWrapper(
    () => axiosInstance.put(`/users/${userId}`, userDetails),
    'User details updated successfully',
    'Failed to update user details'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}
export async function getStudyConsistencyByUserId(userId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/users/${userId}/consistency`),
    `Study consistency for user ID ${userId} retrieved successfully`,
    `Failed to get study consistency for user ID ${userId}`
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getRecentExamsScoresByUserId(userId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/users/${userId}/scores/recent`),
    `Recent exam scores for user ID ${userId} retrieved successfully`,
    `Failed to get recent exam scores for user ID ${userId}`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getUserPerkUsage(userId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/users/${userId}/perks`),
    `Perk usage for user ID ${userId} retrieved successfully`,
    `Failed to get perk usage for user ID ${userId}`
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getUserSubscriptionPlan(userId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/users/${userId}/subscription`),
    `Subscription plan for user ID ${userId} retrieved successfully`,
    `Failed to get subscription plan for user ID ${userId}`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function initiatePasswordReset(email) {
  const response = await apiWrapper(
    () => axiosInstance.post('/auth/password/reset', { email }),
    'Reset email sent successfully',
    'Failed to send reset email'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function resetPassword(token, newPassword) {
  const response = await apiWrapper(
    () =>
      axiosInstance.post('/auth/password/reset/confirm', {
        token,
        newPassword,
      }),
    'Password reset successfully',
    'Failed to reset password'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getUserById(userId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/users/${userId}`),
    `User data for user ID ${userId} retrieved successfully`,
    `Failed to get user data for user ID ${userId}`
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function sendContactFormData(contactData) {
  const response = await apiWrapper(
    () => axiosInstance.post('/contact', contactData),
    'Contact data sent successfully',
    'Failed to send contact data'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function sendCourseRegisterFormData(contactData) {
  const response = await apiWrapper(
    () => axiosInstance.post('/course/register', contactData),
    'Course Register data sent successfully',
    'Failed to send Course Register data'
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}
