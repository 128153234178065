import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import CustomDialogActions from "./CustomDialogActions";
import PerkForm from "./PerkForm";

const EditPerk = ({ open, onClose, initialData, onSubmit, fetchPerks }) => {
  const [perk, setPerk] = useState({
    id: initialData?.id || null,
    name: initialData?.name || "",
    description: initialData?.description || "",
    perkType: initialData?.perkType || "",
    frequency: initialData?.frequency || "",
    nature: initialData?.nature || "",
    level: initialData?.level || "",
  });
  useEffect(() => {
    if (initialData) {
      setPerk({
        id: initialData.id || null,
        name: initialData.name || "",
        description: initialData.description || "",
        perkType: initialData.perkType || "",
        frequency: initialData.frequency || "",
        nature: initialData.nature || "",
        level: initialData.level || "",
      });
    }
  }, [initialData]);

  const resetPerk = () => {
    setPerk({
      id: null,
      name: "",
      description: "",
      perkType: "",
      frequency: "",
      nature: "",
      level: "",
    });
  };

  const handleChange = (e) => {
    if (e.target.name === "nature") {
      setPerk({
        ...perk,
        [e.target.name]: e.target.value,
        perkType: "",
      });
    } else {
      setPerk({
        ...perk,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSave = async () => {
    await onSubmit(perk);
    fetchPerks();
    resetPerk();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>تعديل الميزة</DialogTitle>
      <DialogContent>
        <PerkForm perk={perk} onChange={handleChange} />
      </DialogContent>
      <CustomDialogActions onCancel={onClose} onConfirm={handleSave} />
    </Dialog>
  );
};

export default EditPerk;
