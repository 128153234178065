import React from "react";
import { Box, Typography, LinearProgress, Stack, Divider } from "@mui/material";
import { Colors } from "../../../Utilities/Colors";

const difficultyMapping = {
  EASY: "أسئلة سهلة",
  MEDIUM: "أسئلة متوسطة الصعوبة",
  HARD: "أسئلة صعبة",
};

const difficultyOrder = {
  EASY: 1,
  MEDIUM: 2,
  HARD: 3,
};

const questionTypeMapping = {
  LINKING_TOOLS: "أدوات الربط",
  PREPOSITIONS: "حروف الـجَـر",
  PRONOUN_TIME_COMPATIBILITY: "ملائمة الضمير مع الزمن",
  ADJECTIVE_NOUN_COMPATIBILITY: "ملائمة الصفة مع الموصوف",
  LINGUISTIC_RICHNESS: "الثروة اللغوية",
  SENTENCE_REPHRASING: "إعـــادة صياغة الجمل",
  FOLLOW_UP: "أسئلة فهم المقروء",
};

export const PerformanceCard = ({ questionType, data }) => {
  let metricsData = Object.keys(data)
    .map((diff) => ({
      name: difficultyMapping[diff] || diff,
      value: data[diff]?.total || 0,
      correct: data[diff]?.correct || 0,
      order: difficultyOrder[diff] || 99,
    }))
    .filter((entry) => entry.value > 0);

  metricsData = metricsData.sort((a, b) => a.order - b.order);

  const totalQuestions = metricsData.reduce((acc, item) => acc + item.value, 0);
  const totalCorrect = metricsData.reduce((acc, item) => acc + item.correct, 0);

  return (
    <Box
      sx={{
        width: 250,
        height: 350,
        bgcolor: `${Colors.independence}`,
        borderRadius: 2,
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          textAlign: "center",
          color: `${Colors.darkBlue}`,
          padding: 1,
          backgroundColor: `${Colors.platinum}`,
        }}
      >
        {questionTypeMapping[questionType] || questionType}
      </Typography>
      <Box
        sx={{
          p: 1,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {metricsData.map((entry) => (
          <Box
            key={entry.name}
            sx={{
              mb: 1,
              p: 0.2,
              borderRadius: "6px",
              bgcolor: `${Colors.inputColor}`,
              boxShadow: "0 1px 3px rgba(0,0,0,0.08)",
              transition: "transform .2s",
              "&:hover": {
                transform: "scale(1.02)",
              },
            }}
          >
            <Typography
              variant="caption"
              component="div"
              sx={{
                color: `${Colors.darkBlue}`,
                fontWeight: "bold",
                textAlign: "center",
                mb: 1,
              }}
            >
              {entry.name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "75%" }}>
                <LinearProgress
                  variant="determinate"
                  value={(entry.correct / entry.value) * 100}
                  sx={{
                    height: 8,
                    borderRadius: "4px",
                    bgcolor: `${Colors.brightGray}`,
                  }}
                />
              </Box>
            </Box>

            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                color: `${Colors.mediumBlue}`,
                fontSize: "0.7rem",
                display: "block",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {entry.correct}/{entry.value} إجابات صحيحة
            </Typography>
          </Box>
        ))}
      </Box>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={{ xs: 1 }}
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "100%",
          marginTop: 1,
          padding: 1,
          backgroundColor: `${Colors.platinum}`,
        }}
        useFlexGap
        flexWrap="wrap"
      >
        <Box textAlign="center" sx={{ flex: "1" }}>
          <Typography variant="body2" color="textPrimary" fontWeight="bold">
            {totalQuestions}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            مجموع الأسئلة
          </Typography>
        </Box>

        <Box textAlign="center" sx={{ flex: "1" }}>
          <Typography variant="body2" color="textPrimary" fontWeight="bold">
            {totalCorrect}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            الأجوبة الصحيحة
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};
