import Swal from 'sweetalert2';
import { Colors } from './Colors';

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer);
    toast.addEventListener('mouseleave', Swal.resumeTimer);
  },
});

export const deleteSwal = ({
  onConfirm,
  onSuccessDescription = 'تمت العملية بنجاح.',
  onErrorDescription = 'فشل في العملية.',
}) => {
  return new Promise(async (resolve, reject) => {
    const result = await Swal.fire({
      title: 'هل أنت متأكد؟',
      text: 'لن تتمكن من التراجع عن هذا الإجراء!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: Colors.darkBlue,
      cancelButtonColor: Colors.brightOrange,
      confirmButtonText: 'نعم',
      cancelButtonText: 'لا، ألغِ الأمر!',
    });

    if (result.isConfirmed) {
      let loadingDialog = Swal.fire({
        title: 'جارٍ الحذف',
        didOpen: () => {
          Swal.showLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
      });

      try {
        await onConfirm();
        loadingDialog.close();
        Toast.fire({
          icon: 'success',
          title: ` ${onSuccessDescription}`,
          iconColor: Colors.independence,
        });
        resolve(true); // Resolve with true indicating successful confirmation and action
      } catch (error) {
        loadingDialog.close();
        Toast.fire({
          icon: 'error',
          title: `${onErrorDescription}`,
          iconColor: Colors.brightOrange,
        });
        resolve(false); // Resolve with false indicating an error occurred
      }
    } else {
      resolve(false); // Resolve with false if not confirmed
    }
  });
};
