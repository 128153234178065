import { TextareaAutosize, styled } from "@mui/material";

export const StyledTextarea = styled(TextareaAutosize)(() => ({
  width: "100%",
  resize: "none",
  padding: "12px 16px",
  borderRadius: 5,
  fontSize: "1rem",
  fontFamily: "'Noto Sans Arabic', sans-serif",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  border: "1px solid #dcdcdc",
  backgroundColor: "#fafafa",
}));
