import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Link,
  Paper,
  Grow,
  Box,
} from '@mui/material';
import { useAuth } from '../../Contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Tooltip from '@mui/material/Tooltip';
import { Helmet } from 'react-helmet-async';
import Lottie from 'lottie-react';
import hourglassAnimation from '../../loaders/hourglass.json';
import ForgotPassword from './ForgotPassword';
import { useFormik } from 'formik';
import { loginSchema } from '../../Utilities/validationSchemas';

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        await login(values.email, values.password);
        navigate('/me');
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: 'خطأ',
          text: err.message,
          confirmButtonText: 'موافق',
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>تسجيل الدخول - אל-יע״ל</title>
        <meta
          name="description"
          content="صفحة تسجيل الدخول لـ אל-יע״ל. ادخل لتستمتع بخدماتنا الحصرية."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: 'تسجيل الدخول - אל-יע״ל',
            description:
              'صفحة تسجيل الدخول لـ אל-יע״ל. ادخل لتستمتع بخدماتنا الحصرية.',
            inLanguage: 'ar',
            url: 'https://elyael.com/auth/login',
            publisher: {
              '@type': 'Organization',
              name: 'אל-יע״ל',
              logo: {
                '@type': 'ImageObject',
                url: 'https://elyael.com/assets/logo.jpg',
              },
            },
          })}
        </script>
      </Helmet>
      <Grow in timeout={1000}>
        <Container
          component={Paper}
          elevation={3}
          maxWidth="xs"
          disableGutters
          sx={{
            mt: 12,
            borderRadius: 2,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 3,
            }}
          >
            <Typography
              variant="h5"
              align="center"
              sx={{ mb: 3, color: '#23314B' }}
            >
              تسجيل الدخول
            </Typography>

            <form
              onSubmit={formik.handleSubmit}
              noValidate
              style={{
                width: '100%',
                marginTop: 1,
                padding: 2,
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <TextField
                    name="email"
                    label="البريد الإلكتروني"
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    required
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      style: { direction: 'ltr', textAlign: 'left' },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="password"
                    label="كلمة المرور"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    required
                    fullWidth
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Tooltip
                            title={
                              showPassword
                                ? 'إخفاء كلمة المرور'
                                : 'عرض كلمة المرور'
                            }
                          >
                            <IconButton
                              edge="end"
                              onClick={() => setShowPassword(!showPassword)}
                              sx={{
                                transition: 'transform 0.3s',
                                '&:hover': {
                                  transform: 'rotate(30deg)',
                                },
                              }}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      ),
                      style: { direction: 'ltr' },
                    }}
                    inputProps={{
                      style: { direction: 'ltr', textAlign: 'left' },
                    }}
                    sx={{
                      '.MuiInputBase-root:has(> input:-webkit-autofill)': {
                        backgroundColor: '#e8f0fe',
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      mt: 2,
                      height: '40px',
                      backgroundColor: '#435775',
                      color: '#EAEAEC',
                      transition: 'transform 0.3s, boxShadow 0.3s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
                        backgroundColor: '#2B5AAA',
                      },
                    }}
                    fullWidth
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          <Lottie
                            animationData={hourglassAnimation}
                            style={{
                              width: '40px',
                              height: '40px',
                              marginRight: '10px',
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1">
                            جاري تسجيل الدخول...
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      'دخول'
                    )}
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                      mt: 2,
                      py: 1,
                      px: 2,
                      borderRadius: 1,
                      transition: 'background-color 0.3s',
                      '&:hover': {
                        backgroundColor: 'action.hover',
                      },
                    }}
                  >
                    <Grid item>
                      <Link
                        href="#"
                        variant="body2"
                        underline="hover"
                        sx={{
                          fontSize: '0.9rem',
                          fontWeight: 'medium',
                          color: 'text.primary',
                          textDecoration: 'none',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setIsModalOpen(true);
                        }}
                      >
                        هل نسيت كلمة المرور؟
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        href="register"
                        variant="body2"
                        underline="hover"
                        sx={{
                          fontSize: '0.9rem',
                          fontWeight: 'medium',
                          color: 'text.primary',
                          textDecoration: 'none',
                        }}
                      >
                        ليس لديك حساب؟ قم بالتسجيل
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Grow>
      <ForgotPassword
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default Login;
