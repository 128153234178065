import React, { useState } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  Paper,
  InputAdornment,
  IconButton,
  Box,
  Tooltip,
  lighten,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { registerUser } from '../../Services/UserService';
import { useFormik } from 'formik';
import { registrationSchema } from '../../Utilities/validationSchemas';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
    },
    validationSchema: registrationSchema,
    onSubmit: async (values, actions) => {
      try {
        await registerUser(values);
        Swal.fire('نجاح', 'تم التسجيل بنجاح!', 'success').then((result) => {
          if (result.isConfirmed) {
            navigate('/auth/login');
          }
        });
      } catch (error) {
        const errorMessage =
          error?.message || 'فشل التسجيل. الرجاء المحاولة مرة أخرى.';

        Swal.fire('خطأ', errorMessage, 'error');
      } finally {
        actions.setTouched({
          email: true,
          firstName: true,
          lastName: true,
          password: true,
          confirmPassword: true,
          phoneNumber: true,
        });
      }
    },
  });

  return (
    <Container
      component={Paper}
      elevation={5}
      maxWidth="md"
      sx={{
        p: 4,
        mt: 8,
        borderRadius: 2,
        boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
      }}
    >
      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <Typography
          variant="h4"
          component="div"
          fontWeight="bold"
          color="#23314B"
        >
          التسجيل
        </Typography>
        <Typography variant="subtitle2" color="#435775">
          انضم إلى مجتمعنا
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit} noValidate>
        <Grid container spacing={2}>
          {['email', 'phoneNumber', 'firstName', 'lastName'].map((field) => (
            <Grid item xs={12} md={6} key={field}>
              <TextField
                fullWidth
                label={
                  field === 'email'
                    ? 'البريد الإلكتروني'
                    : field === 'firstName'
                    ? 'الاسم الأول'
                    : field === 'lastName'
                    ? 'اسم العائلة'
                    : field === 'phoneNumber'
                    ? 'رقم الهاتف'
                    : field
                }
                name={field}
                value={formik.values[field]}
                onChange={formik.handleChange}
                error={formik.touched[field] && Boolean(formik.errors[field])}
                helperText={formik.touched[field] && formik.errors[field]}
                required
                variant="outlined"
                onBlur={formik.handleBlur}
              />
            </Grid>
          ))}
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="كلمة المرور"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              required
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title={showPassword ? 'Hide Password' : 'Show Password'}
                    >
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              sx={{
                '.MuiInputBase-root:has(> input:-webkit-autofill)': {
                  backgroundColor: '#e8f0fe',
                },
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="تأكيد كلمة المرور"
              name="confirmPassword"
              type={showPassword ? 'text' : 'password'}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              required
              variant="outlined"
              sx={{
                '.MuiInputBase-root:has(> input:-webkit-autofill)': {
                  backgroundColor: '#e8f0fe',
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: '#435775',
                color: '#EAEAEC',
                mt: 2,
                '&:hover': {
                  backgroundColor: lighten('#435775', 0.1),
                },
              }}
            >
              التسجيل
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Register;
