import React from 'react';
import { IconButton, Grid } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const SocialMediaAndAccount = () => {
  const handleRedirect = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
      <Grid item>
        <IconButton
          color="inherit"
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            width: '24px',
            height: '24px',
            padding: 0,
            transition: 'background-color 0.3s, box-shadow 0.3s',
            '& svg': {
              fontSize: '18px',
            },
            '&:hover': {
              backgroundColor: '#bdbdbd',
              boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
            },
          }}
          onClick={() =>
            handleRedirect('https://www.instagram.com/sarahrayyan05/')
          }
        >
          <InstagramIcon color="action" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color="inherit"
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            width: '24px',
            height: '24px',
            padding: 0,
            transition: 'background-color 0.3s, box-shadow 0.3s',
            '& svg': {
              fontSize: '18px',
            },
            '&:hover': {
              backgroundColor: '#bdbdbd',
              boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
            },
          }}
          onClick={() =>
            handleRedirect('https://api.whatsapp.com/message/SJO6H4AWXU7NA1')
          }
        >
          <WhatsAppIcon color="action" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SocialMediaAndAccount;
