import { Box, Button, Container, Typography } from '@mui/material';
import { Colors } from '../../Utilities/Colors';

const BookSubscriptionInfo = () => {
  return (
    <Container maxWidth="lg" sx={{ mb: 5 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'center',
          gap: '20px',
          mt: 5,
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '40%' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: { xs: 'center', md: 'right' },
            p: { xs: 2, md: 4 },
          }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{
              fontSize: { xs: '32px', md: '48px' },
              fontFamily: 'ExpoArabic',
              fontWeight: 'bold',
              color: Colors.darkCerulean,
              mb: 2,
            }}
          >
            رزمة كورس
            <br />
            אל-יע״ל
          </Typography>
          <Typography
            variant="h3"
            component="div"
            sx={{
              fontSize: { xs: '16px', md: '18px' },
              fontFamily: 'ExpoArabic',
              fontWeight: 'bold',
              color: Colors.textColor,
            }}
          >
            الرزمة الكاملة للتدريب والاستعداد للامتحان
          </Typography>
          <Typography
            sx={{
              mt: 2,
              fontSize: { xs: '20px', md: '36px' },
              fontFamily: 'ExpoArabic',
              fontWeight: 'bold',
              color: Colors.textColor,
            }}
          >
            سابقاً بـ <span className="strikethrough">750</span>
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '20px', md: '36px' },
              fontFamily: 'ExpoArabic',
              fontWeight: 'bold',
              color: Colors.textColor,
            }}
          >
            الآن بــــ{' '}
            <span
              style={{
                color: Colors.darkCerulean,
                fontSize: '44px',
              }}
            >
              649
            </span>
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              (window.location.href =
                'https://api.whatsapp.com/message/SJO6H4AWXU7NA1')
            }
            sx={{
              backgroundColor: Colors.chargedBlue,
              '&:hover': {
                backgroundColor: Colors.chargedBlue,
              },
              mt: 2,
              fontFamily: 'ExpoArabic',
              fontSize: { xs: '16px', md: '20px' },
              height: '40px',
              width: '200px',
              alignSelf: { xs: 'center', md: 'flex-start' },
            }}
          >
            اطلب الآن
          </Button>
        </Box>

        <Box
          sx={{
            width: { xs: '100%', md: '60%' },
            height: { xs: '200px', md: '400px' },
            backgroundImage: `url('/assets/books.png')`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        />
      </Box>
    </Container>
  );
};

export default BookSubscriptionInfo;
