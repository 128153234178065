import React from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from '@mui/material';
import { StyledTextarea } from '../DataManagement/Inputs/StyledTextarea';
const PerkForm = ({ perk, onChange }) => {
  const getPerkTypesByNature = (nature) => {
    switch (nature) {
      case 'CONTINUOUS':
        return [
          { value: 'EXAMS_GRAPH', label: 'رسم الامتحانات' },
          { value: 'STUDY_CONSISTENCY', label: 'استمرارية الدراسة' },
          { value: 'RECENT_EXAMS_VIEW', label: 'عرض الامتحانات الأخيرة' },
          { value: 'ANSWER_EXPLANATION', label: 'شرح الأجوبة' },
        ];
      case 'QUANTITATIVE':
        return [
          { value: 'REAL_EXAM', label: 'امتحان حقيقي' },
          { value: 'COMPOSITION', label: 'انشاء' },
        ];
      default:
        return [];
    }
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} md={4}>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="اسم الميزة"
              variant="outlined"
              name="name"
              value={perk.name}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>طبيعة الميزة</InputLabel>
              <Select
                label="طبيعة الميزة"
                name="nature"
                value={perk.nature}
                onChange={onChange}
              >
                <MenuItem value="CONTINUOUS">مستمر</MenuItem>
                <MenuItem value="QUANTITATIVE">كمي</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8}>
        <StyledTextarea
          id="description"
          name="description"
          minRows={3}
          maxRows={3}
          value={perk.description}
          onChange={onChange}
          placeholder="الشرح"
          aria-label="الشرح"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>نوع الميزة</InputLabel>
          <Select
            label="نوع الميزة"
            name="perkType"
            value={perk.perkType}
            onChange={onChange}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: '200px',
                  overflow: 'auto',
                },
              },
            }}
          >
            {getPerkTypesByNature(perk.nature).map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {perk.nature === 'QUANTITATIVE' && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>التكرار</InputLabel>
            <Select
              label="التكرار"
              name="frequency"
              value={perk.frequency}
              onChange={onChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '200px',
                    overflow: 'auto',
                  },
                },
              }}
            >
              <MenuItem value="EIGHT_HOURS">كل 8 ساعات</MenuItem>
              <MenuItem value="BI_DAILY">مرتين كل 24 ساعة</MenuItem>
              <MenuItem value="DAILY">مرة كل 24 ساعة</MenuItem>
              <MenuItem value="WEEKLY">مرة في الأسبوع</MenuItem>
              <MenuItem value="MONTHLY">مرة في الشهر</MenuItem>
              <MenuItem value="ONE_TIME">مرة واحدة فقط</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {perk.nature === 'CONTINUOUS' && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>مستوى</InputLabel>
            <Select
              label="مستوى"
              name="level"
              value={perk.level}
              onChange={onChange}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  );
};

export default PerkForm;
