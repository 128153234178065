import React, { useEffect, useState } from 'react';
import { Typography, Grid, Container } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { createExam } from '../../Services/ExamService';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import writing from '../../loaders/writing.json';
import PracticeTypeCard from './PracticeTypeCard';
import { useAuth } from '../../Contexts/AuthContext';
import {
  getUserPerkUsage,
  getUserSubscriptionPlan,
} from '../../Services/UserService';
import { Helmet } from 'react-helmet-async';
import { dynamicTitleSwal } from '../../Utilities/dynamicTitleSwal';

const PracticeSelection = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const { user } = useAuth();
  const [userPlan, setUserPlan] = useState(null);
  const [userPerkUsage, setUserPerkUsage] = useState(null);
  const userId = user && user.id;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscriptionPlanAndPerkUsage = async () => {
      try {
        setLoading(true);
        if (userId && user.role !== 'ADMIN') {
          const userPlanResponse = await getUserSubscriptionPlan(userId);
          setUserPlan(userPlanResponse);

          const userPerkUsageResponse = await getUserPerkUsage(userId);
          setUserPerkUsage(userPerkUsageResponse);
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) fetchSubscriptionPlanAndPerkUsage();
  }, [userId, user.role]);

  const startRealExam = async () => {
    const loadingSwal = dynamicTitleSwal({
      animationData: writing,
      loadingMessages: [
        'تشغيل الأنظمة الرقمية... فلنجعل هذا الامتحان ممتعًا!',
        'الأسئلة تتسابق إلى الصفحة، جاهزة لتحدي ذكائك!',
        'دعونا نضع النقاط على الحروف! نتأكد من كل التفاصيل...',
        'أخذنا نفسًا عميقًا... ونحن مستعدون! وأنت؟',
      ],
    });

    try {
      await createExam({ userId: user.id }, true);
      loadingSwal.close();
      navigate(`/exam/initiate`);
    } catch (error) {
      loadingSwal.close();
      console.error('Failed to start the real exam:', error.message);

      MySwal.fire({
        title: 'خطأ',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'حسنا',
      });
    }
  };

  const startComposition = () => {
    navigate(`/composition/initiate`);
  };

  const startComingSoon = () => {
    MySwal.fire({
      title: 'قريباً',
      text: 'ستتوفر هذه الميزة قريباً، شكراً لصبرك!',
      icon: 'info',
      confirmButtonText: 'حسناً',
    });
  };

  return (
    <>
      <Helmet>
        <title> اختيار التمرين - אל-יע״ל</title>
      </Helmet>
      <Container maxWidth="lg" sx={{ my: 3 }}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: 'center',
              mb: 3,
              fontFamily: 'ExpoArabic',
            }}
          >
            اختيار نوع التمرين
          </Typography>
          <Grid
            container
            spacing={2}
            alignItems="stretch"
            justifyContent="center"
          >
            {[
              {
                title: 'الاختبار العملي',
                description: 'اختبار معياري يحاكي الواقع.',
                details:
                  'هذا الاختبار مصمم ليحاكي الواقع ويقيس معرفتك بأشكال متعددة ومتنوعة من الأسئلة.',
                buttonLabel: 'ابدأ الامتحان',
                onClick: startRealExam,
                perkType: 'REAL_EXAM',
              },
              {
                title: 'موضوع الكتابة',
                description: 'كتابة مقال إبداعي حول موضوع محدد.',
                details:
                  'في هذا التحدي، ستكتب مقالًا إبداعيًا حول موضوع مثير ومهم. استخدم خيالك ومهاراتك في الكتابة لإنشاء مقال يلهم الآخرين.',
                buttonLabel: 'ابدأ',
                onClick: startComposition,
                perkType: 'COMPOSITION',
              },
              {
                title: 'قريباً',
                description: 'استمتع بأنواع جديدة من التمارين قريباً.',
                details:
                  'نحن نعمل على توفير مزيد من أنواع التمارين لتحسين تجربتك.',
                buttonLabel: 'قريباً',
                onClick: startComingSoon,
                perkType: 'None',
              },
            ].map(({ title, description, buttonLabel, onClick, perkType }) => (
              <Grid item xs={12} md={6} key={title}>
                <PracticeTypeCard
                  title={title}
                  description={description}
                  buttonLabel={buttonLabel}
                  onClick={onClick}
                  perkType={perkType}
                  userPlan={userPlan}
                  userPerkUsage={userPerkUsage}
                  isAdmin={user.role === 'ADMIN'}
                  loading={loading}
                />
              </Grid>
            ))}
          </Grid>
          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              mt: 3,
              fontSize: '0.9rem',
              fontFamily: 'ExpoArabic',
            }}
          >
            في حال حدوث أي مشكلة، يرجى التواصل عبر الواتساب على الرقم:{' '}
            <strong>0505511221</strong>
          </Typography>
        </motion.div>
      </Container>
    </>
  );
};

export default PracticeSelection;
