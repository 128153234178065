import { styled } from "@mui/material/styles";
import { TableRow } from "@mui/material";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  transition: "0.4s",
  "&:hover": {
    boxShadow: "0px 2px 12px rgba(0,0,0,0.1)",
  },
  [theme.breakpoints.down("sm")]: {
    "&:hover": {
      boxShadow: "none",
    },
  },
}));
