import {
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";

const guidelinesList = [
  "يجب أن يكون المقال باللغة العربية وفقًا لقواعد اللغة والإملاء الصحيحة.",
  "تجنب الانتقادات الشخصية أو التعبير عن آراء شخصية بدون دعم من الأدلة.",
  "تأكد من أن المقال يكون متناسبًا مع الموضوع المحدد ويستجيب لمتطلبات المهمة.",
  "استخدم أسلوب كتابي مناسب ومنظم لضمان فهم القارئ بسهولة.",
  "ركز على الجودة والتفصيل بدلاً من الكمية.",
  "تجنب التشتت والانحراف عن الموضوع الرئيسي للمقال.",
];

const Guidelines = () => {
  return (
    <Paper
      elevation={5}
      sx={{
        padding: 3,
        borderRadius: 5,
        background: "#f7f8fc",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          mb: 2,
          fontWeight: "bold",
          borderBottom: "2px solid #333",
          pb: 1,
          color: "#2c3e50",
          textAlign: "center",
        }}
      >
        الإرشادات
      </Typography>

      <List dense={true}>
        {guidelinesList.map((guide, index) => (
          <React.Fragment key={index}>
            <ListItem alignItems="flex-start">
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "medium",
                      textAlign: "right",
                      color: "#34495e",
                    }}
                  >
                    {guide}
                  </Typography>
                }
              />
            </ListItem>
            {index !== guidelinesList.length - 1 && <Divider component="li" />}
          </React.Fragment>
        ))}
      </List>
    </Paper>
  );
};

export default Guidelines;
