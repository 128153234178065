import React from 'react';
import {
  Typography,
  Button,
  Grid,
  Container,
  useMediaQuery,
  useTheme,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Colors } from '../../Utilities/Colors';

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const containerVariants = {
    hidden: { y: -50, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
  };

  const buttonVariants = {
    rest: { scale: 1 },
    hover: { scale: 1.1, transition: { duration: 0.3 } },
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        position: 'relative',
        p: { xs: 1, sm: 2 },
        zIndex: 2,
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        component={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Grid item xs={12}>
          <Typography
            variant={isMobile ? 'h6' : 'h5'}
            align="right"
            gutterBottom
            sx={{
              color: `${Colors.textColor}`,
              fontSize: { xs: '1.25rem', sm: '1.5rem', md: '31px' },
              maxWidth: `${isMobile ? '200px' : '450px'}`,
              fontFamily: 'ExpoArabic',
            }}
          >
            أفضل الموارد التعليمية
            <br />
            والاختبارات العملية لضمان النجاح
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            align="right"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              color: Colors.textColor,
              fontSize: { xs: '1.5rem', sm: '2rem', md: '3.5rem' },
              maxWidth: `${isMobile ? '180px' : '450px'}`,
              fontFamily: 'ExpoArabic',
            }}
          >
            احصــــل علـــى أعلى الدرجــات{' '}
            <span
              style={{
                color: `${
                  isMobile ? `${Colors.darkCerulean}` : `${Colors.darkCerulean}`
                }`,
              }}
            >
              فــي امتحـــــان ياعيل مع سارة
            </span>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              textAlign: `${isMobile ? 'center' : 'right'}`,
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: 'flex-start',
              gap: theme.spacing(2),
            }}
          >
            <motion.div variants={buttonVariants}>
              <Button
                aria-label="ابدأ الآن"
                variant="contained"
                sx={{
                  backgroundColor: '#ffffff',
                  color: `${Colors.darkCerulean}`,
                  '&:hover': {
                    backgroundColor: '#e6e6e6',
                    color: `${Colors.darkCerulean}`,
                  },
                  width: '160px',
                  fontFamily: 'ExpoArabic',
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
                onClick={() => navigate('/practice/selection')}
              >
                ابدأ الآن
              </Button>
            </motion.div>
            <motion.div variants={buttonVariants}>
              <Button
                aria-label="معلومات"
                variant="outlined"
                sx={{
                  borderColor: '#23314B',
                  color: '#ffffff',
                  backgroundColor: `${Colors.chargedBlue}`,
                  '&:hover': {
                    backgroundColor: '#004c8c',
                    color: '#ffffff',
                    borderColor: '#002d5a',
                  },
                  width: '160px',
                  fontFamily: 'ExpoArabic',
                  fontSize: '18px',
                  fontWeight: 'bold',
                }}
                onClick={() => navigate('/information')}
              >
                معرفة المزيد
              </Button>
            </motion.div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Hero;
