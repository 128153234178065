import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import PlanForm from "./PlanForm";
import CustomDialogActions from "./CustomDialogActions";

const EditPlan = ({ open, onClose, initialData, onSubmit, allPerks }) => {
  const [plan, setPlan] = useState({
    id: initialData?.id || null,
    name: initialData?.name || "",
    duration: initialData?.duration || "",
    price: initialData?.price || "",
    perkIds: initialData?.perkIds || [],
    role: initialData?.role || "",
  });

  useEffect(() => {
    if (initialData) {
      setPlan({
        id: initialData.id || null,
        name: initialData.name || "",
        duration: initialData.duration || "",
        price: initialData.price || "",
        perkIds: initialData.perkIds || [],
        role: initialData.role || "",
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "perkIds") {
      setPlan((prevPlan) => ({
        ...prevPlan,
        perkIds: value,
      }));
    } else {
      setPlan((prevPlan) => ({
        ...prevPlan,
        [name]: value,
      }));
    }
  };

  const handleSave = () => {
    onSubmit(plan);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>تعديل الخطة</DialogTitle>
      <DialogContent>
        <PlanForm plan={plan} onChange={handleChange} allPerks={allPerks} />
      </DialogContent>
      <CustomDialogActions onCancel={onClose} onConfirm={handleSave} />
    </Dialog>
  );
};

export default EditPlan;
