import { useState } from 'react';
import Swal from 'sweetalert2';
import { createQuestion } from '../Services/QuestionService';

const QUESTION_INITIAL_STATE = {
  text: '',
  choices: ['', '', '', ''],
  correctAnswer: 0,
  type: '',
  difficultyLevel: 'NOT_SPECIFIED',
  explanation: '',
};

export const useAddQuestion = (
  fetchRegularQuestions,
  fetchComprehensionQuestions
) => {
  const [question, setQuestion] = useState(QUESTION_INITIAL_STATE);
  const [associatedQuestions, setAssociatedQuestions] = useState([]);
  const [currentAssociatedQuestion, setCurrentAssociatedQuestion] = useState(
    QUESTION_INITIAL_STATE
  );
  const [isSideDialogOpen, setSideDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setQuestion({
      ...question,
      [e.target.name]: e.target.value,
    });
  };

  const toggleSideDialog = () => {
    setSideDialogOpen((prevState) => !prevState);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetQuestionState();
    setOpen(false);
  };

  const handleSideDialogClose = () => {
    setSideDialogOpen(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (
        question.type === 'READING_COMPREHENSION' &&
        associatedQuestions.length === 0
      ) {
        Swal.fire({
          title: 'خطأ',
          text: 'يرجى إضافة سؤال مرتبط على الأقل لفهم المقروء',
          icon: 'error',
          customClass: {
            container: 'swal-overlay',
          },
        });

        return false;
      }

      const payload = { ...question };
      if (payload.type === 'READING_COMPREHENSION') {
        payload.associatedQuestions = associatedQuestions;
      }
      await createQuestion(payload);
      Swal.fire('نجاح', 'تم إنشاء السؤال بنجاح', 'success');
      fetchRegularQuestions();
      fetchComprehensionQuestions();
      handleClose();
      return true;
    } catch (error) {
      console.error('فشل في إنشاء السؤال:', error.message);
      Swal.fire('خطأ', 'فشل في إنشاء السؤال', 'error');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const submitAssociatedQuestion = (associatedQuestion) => {
    if (question.type === 'READING_COMPREHENSION') {
      associatedQuestion.type = 'FOLLOW_UP';
    }

    setAssociatedQuestions([...associatedQuestions, associatedQuestion]);
    setCurrentAssociatedQuestion(QUESTION_INITIAL_STATE);
    handleSideDialogClose();
  };

  const handleCurrentAssociatedQuestionChange = (e, field) => {
    setCurrentAssociatedQuestion({
      ...currentAssociatedQuestion,
      [field]: e.target.value,
    });
  };

  const handleAssociatedChoiceChange = (index, value) => {
    const newChoices = [...currentAssociatedQuestion.choices];
    newChoices[index] = value;
    setCurrentAssociatedQuestion({
      ...currentAssociatedQuestion,
      choices: newChoices,
    });
  };

  const removeAssociatedChoice = (index) => {
    const newChoices = currentAssociatedQuestion.choices.filter(
      (_, idx) => idx !== index
    );
    setCurrentAssociatedQuestion({
      ...currentAssociatedQuestion,
      choices: newChoices,
    });
  };

  const addAssociatedChoice = () => {
    setCurrentAssociatedQuestion({
      ...currentAssociatedQuestion,
      choices: [...currentAssociatedQuestion.choices, ''],
    });
  };

  const resetQuestionState = () => {
    setQuestion(QUESTION_INITIAL_STATE);
    setAssociatedQuestions([]);
  };

  const removeAssociatedQuestion = (index) => {
    setAssociatedQuestions((prev) => prev.filter((_, idx) => idx !== index));
  };

  return {
    question,
    setQuestion,
    associatedQuestions,
    setAssociatedQuestions,
    currentAssociatedQuestion,
    setCurrentAssociatedQuestion,
    isSideDialogOpen,
    toggleSideDialog,
    open,
    handleOpen,
    handleClose,
    isLoading,
    setIsLoading,
    handleChange,
    handleSubmit,
    submitAssociatedQuestion,
    handleCurrentAssociatedQuestionChange,
    handleAssociatedChoiceChange,
    removeAssociatedChoice,
    addAssociatedChoice,
    removeAssociatedQuestion,
  };
};
