import React from 'react';
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  List,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { motion } from 'framer-motion';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const ChoicesList = ({
  choices,
  selectedChoice,
  handleToggle,
  handleChoiceHover,
  setPreviewChoice,
  isSavingChoice,
}) => {
  const isChoiceDisabled = (index) => {
    return isSavingChoice;
  };

  return (
    <motion.div layout initial={{ borderRadius: 10 }}>
      <List
        sx={{
          width: '100%',
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: '10px',
          boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
        }}
      >
        {choices.map((choice, index) => {
          const labelId = `checkbox-list-label-${index}`;

          return (
            <ListItem
              key={index}
              sx={{
                transition: 'all 0.3s ease',
              }}
            >
              <ListItemButton
                sx={{
                  borderRadius: '10px',
                }}
                role={undefined}
                onClick={() => handleToggle(index)}
                dense
                selected={selectedChoice === index}
                onMouseEnter={
                  handleChoiceHover ? () => handleChoiceHover(choice) : null
                }
                onMouseLeave={
                  handleChoiceHover ? () => setPreviewChoice(null) : null
                }
                disabled={isChoiceDisabled(index)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '40px',
                  }}
                >
                  {isSavingChoice && selectedChoice === index ? (
                    <CircularProgress size={24} />
                  ) : selectedChoice === index ? (
                    <RadioButtonCheckedOutlinedIcon />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={choice}
                  sx={{
                    textAlign: 'right',
                  }}
                  primaryTypographyProps={{ fontSize: '1.2rem' }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </motion.div>
  );
};

export default ChoicesList;
