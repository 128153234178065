import React, { useState } from 'react';
import { Grid, Container, Box, Tabs, Tab } from '@mui/material';
import AddQuestion from './AddQuestion';
import QuestionDetails from './QuestionDetails';
import QuestionTable from './QuestionsTable';
import EditQuestion from './EditQuestion';
import ComprehensiveQuestionsTable from './ComprehensiveQuestion/ComprehensiveQuestionsTable';
import {
  columnsForRegular,
  columnsForComprehensive,
} from './tableColumnsConfig';
import { motion } from 'framer-motion';

import QuestionFlagsTable from './QuestionFlags/QuestionFlagsTable';
import useRegularQuestions from '../../../Hooks/useRegularQuestions';
import useComprehensionQuestions from '../../../Hooks/useComprehensionQuestions';
import { deleteSwal } from '../../../Utilities/deleteSwal';
import {
  deleteQuestion,
  getAllComprehensionQuestions,
  getAllQuestions,
} from '../../../Services/QuestionService';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <motion.div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      initial={{ opacity: 0 }}
      animate={{ opacity: value === index ? 1 : 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </motion.div>
  );
}

const DataManagement = () => {
  const {
    comprehensionQuestions,
    loading: loadingComprehensive,
    fetchComprehensionQuestions,
    totalPages: totalComprehensivePages,
    totalQuestionsCount: totalComprehensiveQuestionsCount,
    currentComprehensivePage,
    setCurrentComprehensivePage,
  } = useComprehensionQuestions();

  const {
    loading: loadingRegular,
    fetchRegularQuestions,
    currentRegularPage,
    selectedQuestionType,
    setCurrentRegularPage,
    regularQuestions,
    totalPages,
    totalQuestionsCount,
    setSelectedQuestionType,
  } = useRegularQuestions();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [questionDialogOpen, setQuestionDetailsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const handleUpdate = () => {
    fetchRegularQuestions(currentRegularPage, 5, selectedQuestionType);
    fetchComprehensionQuestions(currentComprehensivePage);
  };

  const handleEdit = (question) => {
    setCurrentQuestion(question);
    setEditDialogOpen(true);
  };

  const handleDeleteRegular = async (id) => {
    const wasDeleted = await deleteSwal({
      onConfirm: async () => {
        const response = await deleteQuestion(id);
        if (response.status !== 204) {
          throw new Error(
            response.error || 'Some error occurred while deleting the question.'
          );
        }
      },
      onSuccessDescription: 'تم حذف السؤال.',
      onErrorDescription: 'حدث خطأ أثناء حذف السؤال.',
    });
    if (wasDeleted) {
      try {
        const typeParam =
          selectedQuestionType.length > 0
            ? selectedQuestionType.join(',')
            : null;
        // Re-fetch to get the updated total number of questions
        const updatedResponse = await getAllQuestions(
          currentRegularPage,
          5,
          typeParam
        );
        // Check if there are no questions left on the current page and it's not the first page
        if (updatedResponse?.content.length === 0 && currentRegularPage > 0) {
          setCurrentRegularPage((prevPage) => prevPage - 1);
        } else {
          fetchRegularQuestions(currentRegularPage, 5, selectedQuestionType);
        }
      } catch (error) {
        console.error('Error while re-fetching questions:', error);
      }
    }
  };

  const handleDeleteComprehensive = async (id) => {
    const wasDeleted = await deleteSwal({
      onConfirm: async () => {
        const response = await deleteQuestion(id);
        if (response.status !== 204) {
          throw new Error(
            response.error || 'Some error occurred while deleting the question.'
          );
        }
      },
      onSuccessDescription: 'تم حذف السؤال.',
      onErrorDescription: 'حدث خطأ أثناء حذف السؤال.',
    });

    if (wasDeleted) {
      try {
        // Assuming you have similar state variables for comprehensive questions
        const updatedResponse = await getAllComprehensionQuestions(
          currentComprehensivePage,
          5
        );
        if (
          updatedResponse?.content.length === 0 &&
          currentComprehensivePage > 0
        ) {
          setCurrentComprehensivePage((prevPage) => prevPage - 1);
        } else {
          fetchComprehensionQuestions(currentComprehensivePage, 5);
        }
      } catch (error) {
        console.error(
          'Error while re-fetching comprehension questions:',
          error
        );
      }
    }
  };

  const regularColumns = columnsForRegular(
    handleEdit,
    handleDeleteRegular,
    setCurrentQuestion,
    setQuestionDetailsOpen
  );
  const comprehensiveColumns = columnsForComprehensive(
    handleEdit,
    handleDeleteComprehensive,
    setCurrentQuestion,
    setQuestionDetailsOpen
  );

  const handleCloseEditDialog = () => {
    setCurrentQuestion(null);
    setEditDialogOpen(false);
  };

  const handleCloseQuestionDetails = () => {
    setQuestionDetailsOpen(false);
  };

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            sx={{
              mt: 3,
            }}
          >
            <Tab
              label="الأسئلة المتنوعة"
              sx={{
                fontSize: '1.1rem',
              }}
            />
            <Tab
              label="أسئلة فهم المقروء"
              sx={{
                fontSize: '1.1rem',
              }}
            />
            <Tab
              label="مشاكل الأسئلة"
              sx={{
                fontSize: '1.1rem',
              }}
            />
          </Tabs>

          <TabPanel value={selectedTab} index={0}>
            <Grid item xs={12}>
              <QuestionTable
                columns={regularColumns}
                regularQuestions={regularQuestions}
                loading={loadingRegular}
                fetchRegularQuestions={fetchRegularQuestions}
                totalPages={totalPages}
                currentRegularPage={currentRegularPage}
                setCurrentRegularPage={setCurrentRegularPage}
                totalQuestionsCount={totalQuestionsCount}
                selectedQuestionType={selectedQuestionType}
                setSelectedQuestionType={setSelectedQuestionType}
              />
            </Grid>
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <ComprehensiveQuestionsTable
              columns={comprehensiveColumns}
              comprehensionQuestions={comprehensionQuestions}
              loading={loadingComprehensive}
              fetchComprehensionQuestions={fetchComprehensionQuestions}
              totalPages={totalComprehensivePages}
              currentComprehensivePage={currentComprehensivePage}
              setCurrentComprehensivePage={setCurrentComprehensivePage}
              totalQuestionsCount={totalComprehensiveQuestionsCount}
            />
          </TabPanel>

          <TabPanel value={selectedTab} index={2}>
            <QuestionFlagsTable />
          </TabPanel>
        </Grid>
      </Grid>

      <AddQuestion
        fetchRegularQuestions={fetchRegularQuestions}
        fetchComprehensionQuestions={fetchComprehensionQuestions}
        currentRegularPage={currentRegularPage}
        currentComprehensivePage={currentComprehensivePage}
        selectedQuestionType={selectedQuestionType}
      />

      <QuestionDetails
        open={questionDialogOpen}
        onClose={handleCloseQuestionDetails}
        question={currentQuestion}
      />

      <EditQuestion
        open={editDialogOpen}
        onClose={handleCloseEditDialog}
        initialData={currentQuestion}
        onSubmit={handleUpdate}
      />
    </Container>
  );
};

export default DataManagement;
