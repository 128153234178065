import React from "react";
import { Typography, Button } from "@mui/material";

// Helper function to truncate text
const truncateText = (text, limit = 100) => {
  if (typeof text !== "string") return "";

  if (text.length > limit) {
    return `${text.substring(0, limit)}...`;
  }

  return text;
};

const QuestionText = ({ text, type, count }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const displayText = isExpanded ? text : truncateText(text);

  const paragraphs = displayText?.split("#") || [];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
        <Typography component="span">
          {type !== 7 ? `سؤال ${count}: ` : `قطعة فهم المقروء: `}
        </Typography>
        {paragraphs.map((paragraph, pIdx) => (
          <Typography key={pIdx}>
            {paragraph.split("~").map((part, index, arr) => (
              <React.Fragment key={index}>
                {part}
                {index !== arr.length - 1 && (
                  <span
                    style={{
                      display: "inline-block",
                      borderBottom: "2.5px solid #23314B",
                      borderRadius: "10px",
                      width: "3rem",
                      textAlign: "center",
                      fontWeight: "bold",
                      lineHeight: 1,
                    }}
                  >
                    &nbsp;
                  </span>
                )}
              </React.Fragment>
            ))}
          </Typography>
        ))}
        {text.length > 100 && (
          <Button
            onClick={toggleExpand}
            sx={{
              border: "none",
              background: "transparent",
              color: "#2B5AAA",
              marginLeft: "8px",
            }}
          >
            {isExpanded ? "أخفي نص القطعة " : "أظهر نص القطعة"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuestionText;
