import React from 'react';
import {
  Box,
  Typography,
  Container,
  Link,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { motion } from 'framer-motion';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Colors } from '../../Utilities/Colors';
import { useTheme } from '@emotion/react';

const easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: { y: 60, opacity: 0, transition: { duration: 0.6, ease: easing } },
  animate: { y: 0, opacity: 1, transition: { duration: 0.6, ease: easing } },
};

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        mt: 3,
        backgroundColor: `${Colors.CetaceanBlue}`,
        color: '#EAEAEC',
        py: 1,
      }}
    >
      <Container disableGutters>
        <motion.div variants={fadeInUp}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid
              item
              sx={{
                width: `${isMobile ? '100%' : 'auto'}`,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'ExpoArabic',
                  textAlign: `${isMobile ? 'center' : 'right'}`,
                }}
              >
                جميع الحقوق محفوظة © El-Yael
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: isMobile ? 'none' : 'flex',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontFamily: 'ExpoArabic',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                صُنع بكل
                <FavoriteIcon
                  sx={{
                    color: '#FD8F24',
                    ml: 0.8,
                    mr: 0.8,
                    fontSize: '1rem',
                  }}
                />
                من قبل
                <Link
                  href="https://devfadi.com/"
                  target="_blank"
                  rel="noopener"
                  sx={{
                    color: 'inherit',
                    textDecoration: 'underline',
                    mr: 0.5,
                  }}
                >
                  فادي
                </Link>
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'ExpoArabic',
                fontSize: '14px',
                width: `${isMobile ? '100%' : 'auto'}`,
                justifyContent: `${isMobile ? 'center' : 'flex-end'}`,
              }}
            >
              <Link href="/information" sx={{ color: 'inherit', ml: 2 }}>
                معلومات
              </Link>
              <Link
                href="/subscriptions/plans"
                sx={{ color: 'inherit', ml: 2 }}
              >
                المتجر
              </Link>
              <Link href="/contact" sx={{ color: 'inherit', ml: 2 }}>
                اتصل بنا
              </Link>
              <Link href="/auth/login" sx={{ color: 'inherit', ml: 2 }}>
                تسجيل دخول
              </Link>
            </Grid>
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Footer;
