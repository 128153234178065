import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import Swal from "sweetalert2";
import { createPerk } from "../../../Services/SubscriptionService";
import CustomDialogActions from "./CustomDialogActions";
import PerkForm from "./PerkForm";

const AddPerk = ({ fetchPerks, open, onClose }) => {
  const [perk, setPerk] = useState({
    name: "",
    description: "",
    perkType: "",
    frequency: "",
    nature: "",
    level: "",
  });

  const handlePerkChange = (e) => {
    if (e.target.name === "nature") {
      setPerk({
        ...perk,
        [e.target.name]: e.target.value,
        perkType: "",
      });
    } else {
      setPerk({
        ...perk,
        [e.target.name]: e.target.value,
      });
    }
  };
  const resetPerk = () => {
    setPerk({
      name: "",
      description: "",
      perkType: "",
      frequency: "",
      nature: "",
      level: "",
    });
  };
  const handlePerkSubmit = async () => {
    const finalPerk = { ...perk };

    ["frequency", "level"].forEach((key) => {
      if (finalPerk[key] === "") finalPerk[key] = null;
    });

    try {
      await createPerk(finalPerk);
      Swal.fire("نجاح", "تم إنشاء الفائدة بنجاح", "success");
      fetchPerks();
      handleClose();
      resetPerk();
    } catch (error) {
      console.error("فشل في إنشاء الفائدة:", error.message);
      Swal.fire("خطأ", "فشل في إنشاء الفائدة", "error");
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
        align="center"
      >
        إضافة ميزة جديدة
      </DialogTitle>
      <DialogContent>
        <PerkForm perk={perk} onChange={handlePerkChange} />
      </DialogContent>
      <CustomDialogActions
        onCancel={handleClose}
        onConfirm={handlePerkSubmit}
      />
    </Dialog>
  );
};

export default AddPerk;
