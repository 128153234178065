import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
  Box,
} from "@mui/material";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import {
  deleteSubscriptionPlan,
  updateSubscriptionPlan,
} from "../../../Services/SubscriptionService";
import EditPlan from "./EditPlan";
import CustomPagination from "./CustomPagination";
import { deleteSwal } from "../../../Utilities/deleteSwal";

const MotionCard = motion(Card);

const Plans = ({ plans, allPerks, fetchPlans }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const itemsPerPage = 3;
  const [page, setPage] = useState(1);

  const handleEditClick = (plan) => {
    setSelectedPlan(plan);
    setEditDialogOpen(true);
  };

  const handleDeletePlan = async (planId) => {
    const result = await deleteSwal({
      onConfirm: () => deleteSubscriptionPlan(planId),
      onSuccessDescription: "تم حذف خطتك.",
      onErrorDescription: "فشل في حذف الخطة.",
    });
    if (result) {
      fetchPlans();
    }
  };

  const handleUpdatePlan = async (updatedPlan) => {
    try {
      await updateSubscriptionPlan(updatedPlan.id, updatedPlan);
      Swal.fire("تم التحديث!", "تم تحديث خطتك بنجاح.", "success");
      fetchPlans();
    } catch (error) {
      Swal.fire("خطأ!", "فشل في تحديث الخطة.", "error");
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const paginatedPlans = plans.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <Grid container spacing={2}>
      {paginatedPlans.map((p) => (
        <Grid item xs={12} sm={6} md={4} key={p.id}>
          <MotionCard
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            elevation={3}
            sx={{
              height: "100%",
              borderRadius: "15px",
              boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
              "&:hover": {
                boxShadow: "0 5px 15px rgba(0,0,0,0.1)",
              },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <CardContent>
              <Box
                sx={{
                  textAlign: "center",
                  padding: "0.5rem",
                  margin: "0.5rem -1rem",
                  background:
                    p.name === "الخطة الذهبية"
                      ? "linear-gradient(45deg, rgba(255, 215, 0, 0.5), rgba(255, 179, 0, 0.8))"
                      : p.name === "الخطة الفضية"
                      ? "linear-gradient(45deg, rgba(192, 192, 192, 0.5), rgba(158, 158, 158, 0.8))"
                      : p.name === "الخطة البرونزية"
                      ? "linear-gradient(45deg, rgba(205, 127, 50, 0.5), rgba(142, 75, 35, 0.8))"
                      : "none",
                }}
              >
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{
                    fontWeight: "bold",
                    color: "black",
                    mt: 1,
                  }}
                >
                  {p.name}
                </Typography>
              </Box>

              <Typography
                variant="h6"
                color="textSecondary"
                gutterBottom
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                {p.price}₪
              </Typography>
              <Typography
                variant="h6"
                color="textSecondary"
                gutterBottom
                sx={{ textAlign: "center", fontWeight: "bold" }}
              >
                {p.duration} يومًا
              </Typography>
              <div sx={{ marginTop: "2rem", width: "80%", mx: "auto" }}>
                <List
                  sx={{
                    bgcolor: "background.paper",
                    borderRadius: "10px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  }}
                >
                  {p.perks.map((perk, index) => (
                    <React.Fragment key={perk.id}>
                      <ListItem
                        disablePadding
                        sx={{
                          padding: "0.5rem 1.5rem",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <ListItemText
                          primary={perk.name}
                          sx={{ mr: 2, textAlign: "right" }}
                        />
                        <ListItemAvatar
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Avatar
                            sx={{
                              width: 24,
                              height: 24,
                            }}
                          >
                            <StarIcon sx={{ fontSize: "1rem" }} />
                          </Avatar>
                        </ListItemAvatar>
                      </ListItem>
                      {index !== p.perks.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </div>
            </CardContent>
            <CardActions
              sx={{
                justifyContent: "space-between",
                padding: "0 1rem 1rem 1rem",
              }}
            >
              <IconButton
                aria-label="delete"
                onClick={() => handleDeletePlan(p.id)}
                sx={{
                  color: "grey.600",
                  "&:hover": { color: "error.dark" },
                }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                aria-label="edit"
                onClick={() => handleEditClick(p)}
                sx={{
                  color: "grey.600",
                  "&:hover": { color: "info.dark" },
                }}
              >
                <EditIcon />
              </IconButton>
            </CardActions>
          </MotionCard>
        </Grid>
      ))}
      <EditPlan
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        initialData={selectedPlan}
        allPerks={allPerks}
        onSubmit={handleUpdatePlan}
      />
      <CustomPagination
        itemsCount={plans.length}
        itemsPerPage={itemsPerPage}
        currentPage={page}
        onPageChange={handlePageChange}
      />
    </Grid>
  );
};

export default Plans;
