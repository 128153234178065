import axios from "axios";
import { refreshAccessToken } from "./UserService";

const DEFAULT_ERROR_MESSAGES = {
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  500: "Internal Server Error",
};

const createAxiosInstance = (baseURL) => {
  const instance = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  });

  // Add interceptor to handle 401 unauthorized errors
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response) {
        if (error.response.status === 401) {
          // Check for either 'jwt-expired' or 'jwt-missing' headers, and if this isn't a retry request
          if (
            (error.response.headers["jwt-expired"] ||
              error.response.headers["jwt-missing"]) &&
            !originalRequest._retry
          ) {
            originalRequest._retry = true;
            // Attempt to refresh the token
            try {
              await refreshAccessToken();
              // Retry the original request
              return instance(originalRequest);
            } catch (refreshError) {
              if (refreshError.message === "Refresh token expired") {
                alert(refreshError.message);
                // Redirect to login page or logout user logic
              }
              return Promise.reject(refreshError);
            }
          }
        } else if (
          error.response.status === 400 &&
          error.response.headers["jwt-invalid"]
        ) {
          alert("Your session is invalid. Please log in again.");
          // Redirect to login page or logout user logic
          return Promise.reject(error);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

const extractErrorMessage = (error, defaultErrorMessage) => {
  if (!error.response) {
    if (error.message === "Network Error") {
      return "Cannot connect to server. Please check your network connection.";
    }
    return error.message;
  }

  const { status } = error.response;
  const serverError = error.response.data.error;
  const errorDetails = error.response.data.message;

  // Return server-provided message or fallback to default
  return (
    errorDetails ||
    serverError ||
    DEFAULT_ERROR_MESSAGES[status] ||
    defaultErrorMessage
  );
};

export async function apiWrapper(apiCall, successMessage, errorMessage) {
  try {
    const response = await apiCall();
    if (response.status === 204) {
      return {
        data: { content: [], totalPages: 0 },
        error: null,
        status: response.status,
      };
    } else if (response.status >= 200 && response.status < 300) {
      console.log(successMessage);
      return { data: response.data, error: null, status: response.status };
    } else {
      const errorStatusMessage = `Error status: ${response.status}`;
      const handledError = extractErrorMessage(
        new Error(errorStatusMessage),
        errorMessage
      );
      console.error(errorMessage, errorStatusMessage);
      return {
        data: null,
        error: { message: handledError },
        status: response.status,
      };
    }
  } catch (error) {
    const handledError = extractErrorMessage(error, errorMessage);
    console.error(errorMessage, error.message);
    return {
      data: null,
      error: { message: handledError },
      status: error.response?.status,
    };
  }
}

export const axiosInstance = createAxiosInstance(
  process.env.REACT_APP_API_BASE_URL
);
export const adminAxiosInstance = createAxiosInstance(
  process.env.REACT_APP_ADMIN_BASE_URL
);
