import React, { useState } from "react";
import { Table, TableBody, TableHead, Button, Pagination } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { StyledTableRow } from "../../DataManagement/Inputs/StyledTableRow";
import { StyledTableCell } from "../../DataManagement/Inputs/StyledTableCell";
import { timeUtil } from "../../../../Utilities/timeUtil";
import gear from "../../../../loaders/gear.svg";

const themeStyles = {
  tableHeader: {
    backgroundColor: "#435775",
    textAlign: "center",
  },
  tableCellDefault: {
    textAlign: "center",
    color: "#DADADC",
  },
  viewButton: {
    color: "#2B5AAA",
    mr: 1,
    borderColor: "#2B5AAA",
    "&:hover": {
      backgroundColor: "#2B5AAA",
      color: "#EAEAEC",
    },
  },
  deleteButton: {
    color: "#FD8F24",
    borderColor: "#FD8F24",
    "&:hover": {
      backgroundColor: "#FD8F24",
      color: "#EAEAEC",
    },
  },
};
const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

const CompositionsTable = ({
  compositions,
  users,
  loading,
  handleDeleteConfirmation,
  setSelectedComposition,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 8;

  return (
    <>
      <Table>
        <TableHead>
          <StyledTableRow sx={themeStyles.tableHeader}>
            <StyledTableCell sx={themeStyles.tableCellDefault}>
              المستخدم
            </StyledTableCell>
            <StyledTableCell sx={themeStyles.tableCellDefault}>
              ملاحظات
            </StyledTableCell>
            <StyledTableCell sx={themeStyles.tableCellDefault}>
              الدرجة
            </StyledTableCell>
            <StyledTableCell sx={themeStyles.tableCellDefault}>
              المدة الزمنية
            </StyledTableCell>

            <StyledTableCell sx={themeStyles.tableCellDefault}>
              الإجراءات
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={5}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={gear}
                    alt="Loading..."
                    style={{ marginRight: "10px" }}
                  />
                  جاري جلب تسليمات الطلاب...
                </div>
              </StyledTableCell>
            </StyledTableRow>
          ) : compositions.length === 0 ? (
            <StyledTableRow>
              <StyledTableCell align="center" colSpan={5}>
                لا تتوافر بيانات
              </StyledTableCell>
            </StyledTableRow>
          ) : (
            compositions
              .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
              .map((composition) => (
                <StyledTableRow key={composition.id}>
                  <StyledTableCell
                    sx={{ color: "#2B5AAA", textAlign: "center" }}
                  >
                    {users[composition.userId]?.firstName &&
                    users[composition.userId]?.lastName
                      ? `${users[composition.userId].firstName} ${
                          users[composition.userId].lastName
                        }`
                      : "جار التحميل..."}
                  </StyledTableCell>

                  <StyledTableCell
                    sx={{ color: "#23314B", textAlign: "center" }}
                  >
                    {composition.teacherNotes ? (
                      truncateString(composition.teacherNotes, 30)
                    ) : (
                      <strong>لا توجد ملاحظات بعد</strong>
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ color: "#FD8F24", textAlign: "center" }}
                  >
                    {composition.grade || <strong>لم يتم التقييم</strong>}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ color: "#23314B", textAlign: "center" }}
                  >
                    {timeUtil.inMinutesAndSeconds(
                      composition.compositionStartDate,
                      composition.compositionEndDate
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={themeStyles.tableCellDefault}>
                    <Button
                      onClick={() => setSelectedComposition(composition)}
                      sx={themeStyles.viewButton}
                    >
                      <OpenInNewIcon />
                    </Button>
                    <Button
                      onClick={() => handleDeleteConfirmation(composition.id)}
                      sx={themeStyles.deleteButton}
                    >
                      <DeleteIcon />
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))
          )}
        </TableBody>
      </Table>
      <Pagination
        count={Math.ceil(compositions.length / rowsPerPage)}
        onChange={(_, newPage) => setCurrentPage(newPage)}
        variant="outlined"
        shape="rounded"
        style={{
          marginTop: "20px",
          paddingBottom: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      />
    </>
  );
};

export default CompositionsTable;
