import React from "react";
import { Tooltip, Button } from "@mui/material";
import { timeUtil } from "../../../../Utilities/timeUtil";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const renderCellContent = (flag, column, handleClickOpen) => {
  if (column.field === "questionDTO.text") {
    const fullText = flag.questionDTO.text;
    const truncatedText = truncateText(fullText, 25);

    return (
      <Tooltip title={fullText} arrow>
        <span>{truncatedText}</span>
      </Tooltip>
    );
  }

  if (column.field === "feedback") {
    const fullText = flag[column.field];
    const truncatedText = truncateText(fullText, 30);

    return (
      <Tooltip title={fullText} arrow>
        <span>{truncatedText}</span>
      </Tooltip>
    );
  }

  if (column.field === "status") {
    return translateStatus(flag[column.field]);
  }

  if (column.field === "actions") {
    return (
      <Button
        onClick={() => handleClickOpen(flag)}
        sx={{
          color: "#2B5AAA",
          borderColor: "#2B5AAA",
          "&:hover": {
            backgroundColor: "#2B5AAA",
            color: "#EAEAEC",
          },
        }}
      >
        <OpenInNewIcon />
      </Button>
    );
  }

  if (column.renderCell) {
    return column.renderCell({ row: flag, value: flag[column.field] });
  }

  return flag[column.field];
};

export const columns = [
  {
    field: "questionDTO.text",
    headerName: "نص السؤال",
    width: "25%",
    renderCell: (params) => {
      const fullText = params.row.questionDTO.text;
      const truncatedText = truncateText(fullText, 40);

      return (
        <Tooltip title={fullText} arrow>
          <span>{truncatedText}</span>
        </Tooltip>
      );
    },
  },
  { field: "feedback", headerName: "التعليق", width: "30%" },
  { field: "fullName", headerName: " المستخدم", width: "15%" },
  { field: "status", headerName: "الحالة", width: "15%" },
  {
    field: "flaggedAt",
    headerName: "تاريخ الإبلاغ",
    width: "15%",
    renderCell: (params) => {
      return timeUtil.formatDate(params.row.flaggedAt);
    },
  },
  { field: "actions", headerName: "الإجراءات", width: "10%" },
];

export const truncateText = (text, length) => {
  if (text.length <= length) {
    return text;
  }
  return text.slice(0, length) + "...";
};

export const translateStatus = (status) => {
  const statusTranslations = {
    PENDING: "قيد الانتظار",
    REVIEWED: "تمت المراجعة",
  };

  const statusColors = {
    PENDING: "lightcoral",
    REVIEWED: "green",
  };

  const translatedStatus = statusTranslations[status] || status;
  const statusStyle = { color: statusColors[status] || "black" };

  return <span style={statusStyle}>{translatedStatus}</span>;
};
