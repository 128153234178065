import { motion } from "framer-motion";
import CountUp from "react-countup";

const ScoreCountUp = ({ end }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      style={{ display: "inline-block" }}
    >
      <CountUp end={end} duration={3} separator="," />
    </motion.div>
  );
};

export default ScoreCountUp;
