import React from 'react';
import { Box, Container } from '@mui/material';
import ContactForm from './ContactForm';
import AcademyInfo from './AcademyInfo';

const Contact = () => {
  return (
    <Container component="main" maxWidth="lg">
      <ContactForm />

      <Box sx={{ mt: 2 }}>
        <AcademyInfo />
      </Box>
    </Container>
  );
};

export default Contact;
