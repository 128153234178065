import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Pagination,
  Grid,
  Paper,
  Stack,
  Divider,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
} from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import HistoryIcon from '@mui/icons-material/History';
import { timeUtil } from '../../Utilities/timeUtil';
import { Colors } from '../../Utilities/Colors';
import { useNavigate } from 'react-router-dom';

const RecentExams = ({ recentExamsScores, isExamTimeOver, navigateToExam }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const ITEMS_PER_PAGE = isMobile ? 2 : 3;
  const [currentPage, setCurrentPage] = useState(1);

  const filteredExams = recentExamsScores.filter((exam) =>
    isExamTimeOver(exam.endTime)
  );

  const sortedExams = [...recentExamsScores].sort(
    (a, b) => new Date(b.endTime).getTime() - new Date(a.endTime).getTime()
  );

  const paginatedExams = sortedExams.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleNavigateToHistory = (examId) => {
    navigate(`/exam/end/${examId}`);
  };
  return (
    <Paper elevation={5} sx={{ borderRadius: 1, mb: 2 }}>
      <Box
        sx={{
          padding: 1,
          mt: 1.3,
          borderRadius: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          position: 'relative',
        }}
      >
        <Typography
          sx={{
            marginBottom: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <EventIcon sx={{ ml: 1 }} />
          الامتحانات الأخيرة
        </Typography>
        {paginatedExams.length > 0 ? (
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {paginatedExams.map((exam) => (
              <Grid item xs={6} md={4} key={exam.examId}>
                <Box
                  sx={{
                    position: 'relative',
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: isMobile ? 1 : 2,
                    flexGrow: 1,
                    minHeight: '116px',
                    border: `1px solid ${Colors.independence}`,
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: Colors.inputColor,
                    transition: 'transform 0.3s',
                  }}
                >
                  {isExamTimeOver(exam.endTime) && (
                    <Tooltip title="عرض الامتحان" arrow>
                      <IconButton
                        size="small"
                        sx={{
                          position: 'absolute',
                          top: isMobile ? 2 : 8,
                          left: isMobile ? 2 : 8,
                          color: Colors.mediumBlue,
                          border: isMobile
                            ? ''
                            : `1px solid ${Colors.mediumBlue}`,
                          '&:hover': {
                            bgcolor: Colors.lightBlue,
                          },
                        }}
                        onClick={() => handleNavigateToHistory(exam.examId)}
                      >
                        <HistoryIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Typography variant="subtitle1" color={Colors.darkBlue}>
                    امتحان
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={{ xs: 1, sm: 3 }}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ width: '100%' }}
                      useFlexGap
                      flexWrap="wrap"
                    >
                      <Box textAlign="center">
                        <Typography
                          variant="subtitle2"
                          color={Colors.mediumBlue}
                        >
                          {isExamTimeOver(exam.endTime)
                            ? `${exam.score}%`
                            : 'جاري...'}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          النتيجة
                        </Typography>
                      </Box>
                      <Box textAlign="center" sx={{ flex: '1' }}>
                        {exam.isSubmitted || isExamTimeOver(exam.endTime) ? (
                          <>
                            <Typography
                              variant="subtitle2"
                              color={Colors.mediumBlue}
                            >
                              {timeUtil.extractTime(exam.duration)}
                            </Typography>
                            <Typography
                              variant="caption"
                              color={Colors.independence}
                            >
                              المدة
                            </Typography>
                          </>
                        ) : (
                          <Button
                            size="small"
                            variant="contained"
                            sx={{
                              backgroundColor: Colors.brightOrange,
                              color: Colors.inputColor,
                              '&:hover': {
                                backgroundColor: Colors.darkBlue,
                              },
                            }}
                            onClick={() => navigateToExam(exam.examId)}
                          >
                            استكمال الامتحان
                          </Button>
                        )}
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Typography
              variant={isMobile ? 'body2' : 'body1'}
              align="center"
              color={Colors.independence}
            >
              لا يوجد امتحانات حديثة حتى الآن.
            </Typography>
          </Box>
        )}

        <Box display="flex" justifyContent="center" marginTop={3}>
          <Pagination
            count={Math.ceil(filteredExams.length / ITEMS_PER_PAGE)}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="primary"
          />
        </Box>
      </Box>
    </Paper>
  );
};

export default RecentExams;
