import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  lighten,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { useFormik } from 'formik';
import { Colors } from '../../Utilities/Colors';
import Swal from 'sweetalert2';
import { sendContactFormData } from '../../Services/UserService';
import { contactSchema } from '../../Utilities/validationSchemas';

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: contactSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        await sendContactFormData(values);
        Swal.fire({
          title: 'تم الإرسال!',
          text: 'تم إرسال بياناتك بنجاح.',
          icon: 'success',
          confirmButtonText: 'حسنا',
          confirmButtonColor: Colors.brightOrange,
        });
        resetForm();
      } catch (error) {
        Swal.fire({
          title: 'خطأ!',
          text: error.message || 'حدث خطأ أثناء إرسال بيانات الاتصال.',
          icon: 'error',
          confirmButtonText: 'حسنا',
          confirmButtonColor: Colors.darkBlue,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <Container
      sx={{
        width: '100%',
        backgroundImage: isMobile ? 'none' : `url('/assets/contact-bg.jpg')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'left',
        minHeight: '500px',
      }}
    >
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        noValidate
        sx={{
          width: '100%',
          maxWidth: '400px',
          p: 3,
          '& .MuiTextField-root': {
            mt: 1,
            mb: 2,
            '& .MuiOutlinedInput-root': {
              fontFamily: 'ExpoArabic',
              borderRadius: '24px',
              borderColor: Colors.electricBlue,
              '& fieldset': {
                borderColor: Colors.electricBlue,
              },
            },
            '& label': {
              color: '#adadad',
              fontFamily: 'ExpoArabic',
            },
            '& label.Mui-focused': {
              color: Colors.Independence,
            },
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '60px',
            color: Colors.darkCerulean,
            textAlign: 'center',
            padding: '20px 0',
            fontFamily: 'ExpoArabic',
            fontWeight: 'bold',
          }}
        >
          تواصــــل
        </Typography>
        <TextField
          fullWidth
          id="name"
          name="name"
          label="الاسم الكامل"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          variant="outlined"
        />
        <TextField
          required
          fullWidth
          id="email"
          label="البريد الإلكتروني"
          name="email"
          autoComplete="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          variant="outlined"
        />
        <TextField
          required
          fullWidth
          id="message"
          label="رسالتك"
          name="message"
          multiline
          rows={4}
          value={formik.values.message}
          onChange={formik.handleChange}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
          variant="outlined"
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            mt: 2,
          }}
        >
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              borderRadius: '24px',
              color: Colors.platinum,
              maxWidth: '200px',
              bgcolor: Colors.chargedBlue,
              fontFamily: 'ExpoArabic',
              fontWeight: 'bold',
              fontSize: '16px',
              '&:hover': {
                bgcolor: lighten(Colors.chargedBlue, 0.1),
              },
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'إرسال'
            )}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ContactForm;
