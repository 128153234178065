import { useState, useEffect, useCallback } from 'react';
import { updateQuestion } from '../Services/QuestionService';
import Swal from 'sweetalert2';
import { Colors } from '../Utilities/Colors';

const useEditQuestion = (initialData, onSubmit, onClose) => {
  const [question, setQuestion] = useState({
    id: initialData?.id || null,
    text: initialData?.text || '',
    choices: Array(4)
      .fill('')
      .map((_, index) => initialData?.choices?.[index] || ''),
    correctAnswer: initialData?.correctAnswer || 0,
    type: initialData?.type || 'LINKING_TOOLS',
    difficultyLevel: initialData?.difficultyLevel || 'NOT_SPECIFIED',
    explanation: initialData?.explanation || '',
    associatedQuestions: initialData?.associatedQuestions || [],
  });

  const [currentSubQuestionIndex, setCurrentSubQuestionIndex] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;

      if (name.startsWith('choices[')) {
        const index = parseInt(
          name.replace('choices[', '').replace(']', ''),
          10
        );
        const newChoices = [...question.choices];
        newChoices[index] = value;

        setQuestion((prevQuestion) => ({
          ...prevQuestion,
          choices: newChoices,
        }));
      } else {
        setQuestion((prevQuestion) => ({
          ...prevQuestion,
          [name]: value,
        }));
      }
    },
    [question.choices]
  );

  const addAssociatedQuestion = useCallback(() => {
    setQuestion((prev) => ({
      ...prev,
      associatedQuestions: [
        ...(prev.associatedQuestions || []),
        {
          text: '',
          choices: ['', '', '', ''],
          correctAnswer: 0,
          difficultyLevel: 'NOT_SPECIFIED',
        },
      ],
    }));
    setCurrentSubQuestionIndex((prev) => prev + 1);
  }, []);

  const removeAssociatedQuestion = useCallback((index) => {
    setQuestion((prev) => {
      const updatedAssociatedQuestions = prev.associatedQuestions.filter(
        (_, idx) => idx !== index
      );
      return {
        ...prev,
        associatedQuestions: updatedAssociatedQuestions,
      };
    });
    setCurrentSubQuestionIndex((prev) => (prev > 0 ? prev - 1 : 0));
  }, []);

  const handleAssociatedQuestionChange = useCallback(
    (e, index, field, choiceIdx = null) => {
      setQuestion((prev) => {
        const newAssociatedQuestions = [...(prev.associatedQuestions || [])];
        const { value } = e.target;

        switch (field) {
          case 'text':
            newAssociatedQuestions[index].text = value;
            break;
          case 'correctAnswer':
            newAssociatedQuestions[index].correctAnswer = parseInt(value, 10); // Convert string to number
            break;
          case 'choices':
            if (choiceIdx !== null) {
              newAssociatedQuestions[index].choices[choiceIdx] = value;
            } else {
              console.error(
                'Choice index not provided for choice field update.'
              );
            }
            break;
          case 'difficultyLevel':
            newAssociatedQuestions[index].difficultyLevel = value;
            break;
          case 'explanation':
            newAssociatedQuestions[index].explanation = value;
            break;
          default:
            break;
        }

        return {
          ...prev,
          associatedQuestions: newAssociatedQuestions,
        };
      });
    },
    []
  );

  const handleSave = async () => {
    setIsSaving(true);
    let Toast = Swal.mixin({
      toast: true,
      background: Colors.inputColor,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      customClass: {
        title: 'my-title-class',
      },
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    try {
      await updateQuestion(question.id, question);
      onSubmit();
      onClose();

      Toast.fire({
        icon: 'success',
        title: 'تم تحديث السؤال بنجاح',
        iconColor: Colors.independence,
      });
    } catch (error) {
      console.error('Failed to update question:', error);

      Toast.fire({
        icon: 'error',
        title: 'فشل في تحديث السؤال',
        iconColor: Colors.brightOrange,
      });
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    if (initialData) {
      setQuestion(initialData);
    }
  }, [initialData]);

  const resetSubQuestionIndex = useCallback(() => {
    setCurrentSubQuestionIndex(0);
  }, []);

  return {
    question,
    currentSubQuestionIndex,
    handleChange,
    addAssociatedQuestion,
    removeAssociatedQuestion,
    handleAssociatedQuestionChange,
    handleSave,
    setCurrentSubQuestionIndex,
    isSaving,
    resetSubQuestionIndex,
  };
};

export default useEditQuestion;
