import { axiosInstance, apiWrapper } from "./Service";

export async function getQuestionById(questionId) {
  const response = await apiWrapper(
    () => axiosInstance.get(`/questions/${questionId}`),
    `Question retrieved successfully`,
    `Failed to get question`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getQuestionsByIds(questionIds, userId) {
  const response = await apiWrapper(
    () =>
      axiosInstance.get(`/questions`, {
        params: { ids: questionIds.join(","), userId },
      }),
    `Questions retrieved successfully`,
    `Failed to get questions`
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getAllQuestions(page, size = 10, questionTypes = null) {
  const params = {
    page,
    size,
    ...(questionTypes && { types: questionTypes }),
  };

  const response = await apiWrapper(
    () =>
      axiosInstance.get(`/questions/all`, {
        params,
      }),
    `Questions retrieved successfully`,
    `Failed to get questions`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }
  return response.data;
}
export async function getAllComprehensionQuestions(page, size = 10) {
  const response = await apiWrapper(
    () =>
      axiosInstance.get(`/questions/comprehension`, {
        params: {
          page: page,
          size: size,
        },
      }),
    `Comprehension Questions retrieved successfully`,
    `Failed to get comprehension questions`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }
  return response.data;
}

export async function createQuestion(questionDTO) {
  const response = await apiWrapper(
    () => axiosInstance.post(`/questions`, questionDTO),
    `Question created successfully`,
    `Failed to create question`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function updateQuestion(questionId, questionDTO) {
  const response = await apiWrapper(
    () => axiosInstance.put(`/questions/${questionId}`, questionDTO),
    `Question updated successfully`,
    `Failed to update question`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function deleteQuestion(questionId) {
  const response = await apiWrapper(
    () => axiosInstance.delete(`/questions/${questionId}`),
    `Question deleted successfully`,
    `Failed to delete question`
  );

  if (response.error) {
    throw new Error(response.error.message);
  }
  return response;
}

export async function uploadQuestionsFile(file) {
  const formData = new FormData();
  formData.append("file", file);

  const response = await apiWrapper(
    () =>
      axiosInstance.post(`/questions/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    `File uploaded successfully`,
    `Failed to upload the file`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}
