import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { CircularProgress, Typography } from "@mui/material";
import { Colors } from "../../Utilities/Colors";
import { StyledTextarea } from "../Admin/DataManagement/Inputs/StyledTextarea";
import { useAuth } from "../../Contexts/AuthContext";
import { flagQuestion } from "../../Services/FlagService";
import Swal from "sweetalert2";

const FlagQuestion = ({ open, handleClose, question }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [feedback, setFeedback] = useState("");
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleFeedbackChange = (e) => setFeedback(e.target.value);

  const submitFeedback = async () => {
    setLoading(true); // Set loading to true when the process starts
    try {
      await flagQuestion(question.id, feedback, user.id);

      Swal.fire({
        icon: "success",
        title: "تم إرسال ملاحظاتك بنجاح!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        customClass: {
          popup: "colored-toast",
        },
      });

      handleClose();
    } catch (error) {
      console.error("Failed to send feedback:", error);

      Swal.fire({
        icon: "error",
        title: "عذرًا...",
        text: "حدث خطأ ما!",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        customClass: {
          popup: "colored-toast",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            bgcolor: Colors.platinum,
            boxShadow: 5,
            borderRadius: 2,
            p: 4,
            maxWidth: isMobile ? "90vw" : 600,
            width: "100%",
            boxSizing: "border-box",
            outline: "none",
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            gutterBottom
            sx={{
              textAlign: "center",
              color: Colors.darkBlue,
              fontSize: isMobile ? "1.2rem" : "1.5rem",
            }}
          >
            هل وجدت شيئًا غير صحيح؟
          </Typography>
          <Typography
            id="modal-description"
            sx={{
              mb: 2,
              color: Colors.independence,
              fontSize: isMobile ? "1rem" : "1.2rem",
            }}
          >
            ساعدنا في تحسين جودة الأسئلة! إذا وجدت شيئًا خاطئًا أو مضللًا،
            أخبرنا عن تفاصيله هنا.
          </Typography>
          <StyledTextarea
            aria-label="feedback"
            minRows={3}
            placeholder="ما الذي لفت انتباهك؟ 🤔"
            value={feedback}
            onChange={handleFeedbackChange}
            sx={{
              mb: 2,
              borderColor: Colors.lightBlue,
              "&:focus": {
                boxShadow: `0 0 0 0.2rem ${Colors.mediumBlue}30`,
              },
            }}
          />
          <Button
            onClick={submitFeedback}
            variant="contained"
            disabled={loading}
            sx={{
              bgcolor: loading ? "grey" : Colors.brightOrange,
              color: Colors.inputColor,
              "&:hover": {
                bgcolor: loading ? "grey" : Colors.darkBlue,
              },
              fontSize: isMobile ? "0.9rem" : "1rem",
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "أرسل ملاحظاتك 🚀"
            )}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default FlagQuestion;
