import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { StyledTextarea } from "./Inputs/StyledTextarea";

const QuestionDetails = ({ open, onClose, question }) => {
  if (!question) return null;

  const isComprehensive =
    question.associatedQuestions && question.associatedQuestions.length > 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={isComprehensive ? "md" : "sm"}
    >
      <DialogTitle
        sx={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          paddingBottom: 2,
          marginBottom: 2,
          textAlign: "center",
        }}
      >
        تفاصيل السؤال
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{}}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom>
              نص السؤال:
            </Typography>
            <StyledTextarea
              id="text"
              name="text"
              minRows={3}
              maxRows={10}
              value={question.text.replace(/#/g, "\n")}
              placeholder="نص السؤال"
              aria-label="نص السؤال"
              readOnly
            />
          </Grid>

          <Grid item xs={12}>
            {isComprehensive ? (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    textAlign: "center",
                    marginBottom: 1,
                    fontWeight: "bold",
                  }}
                >
                  الأسئلة المرتبطة
                </Typography>
                <Grid container spacing={1}>
                  {question.associatedQuestions.map(
                    (associatedQuestion, index) => (
                      <Grid item xs={6} key={index}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={1}>
                            <QuestionAnswerIcon />
                          </Grid>
                          <Grid item xs={11}>
                            <Typography variant="subtitle1" gutterBottom>
                              {associatedQuestion.text}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                            >
                              الجواب الصحيح:
                              {
                                associatedQuestion.choices[
                                  associatedQuestion.correctAnswer
                                ]
                              }
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  )}
                </Grid>
              </>
            ) : (
              <>
                <Typography variant="subtitle1" gutterBottom>
                  الخيارات:
                </Typography>

                <Grid container spacing={2}>
                  {question.choices.map((choice, index) => (
                    <Grid item xs={6} key={index}>
                      <Paper
                        elevation={3}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 5,
                          border:
                            index === question.correctAnswer
                              ? "3px solid #84f558"
                              : "none",
                          transition: "0.2s",
                          padding: 1,
                          "&:hover": {
                            background: "rgba(0, 0, 0, 0.05)",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            justifyContent: "center",
                            display: "flex",
                            marginRight: 1,
                            fontWeight: "bold",
                            fontSize: "1.2rem",
                          }}
                        >
                          {index + 1}.
                        </Typography>
                        <Typography
                          sx={{
                            textAlign: "right",
                            flexGrow: 1,
                            mr: 1,
                            fontWeight: "bold",
                          }}
                        >
                          {choice}
                        </Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
          paddingTop: 2,
          justifyContent: "center", // Center align the content horizontally
          textAlign: "center", // Center align the button text
        }}
      >
        <Button
          onClick={onClose}
          variant="contained"
          sx={{
            backgroundColor: "#2b3c53",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "#2b3c53",
            },
            padding: "5px 40px",
          }}
        >
          إغلاق
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionDetails;
