import { axiosInstance, apiWrapper } from "./Service";

export async function getBriefOverview() {
  const response = await apiWrapper(
    () => axiosInstance.get("/admin/brief-overviews"),
    "Fetched brief overviews successfully!",
    "Failed to fetch brief overviews."
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function getAllUsers() {
  const response = await apiWrapper(
    () => axiosInstance.get("/admin/users"),
    "Fetched all users successfully!",
    "Failed to fetch users."
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function banUser(email, isBanned) {
  const response = await apiWrapper(
    () => axiosInstance.put(`/admin/users/${email}/ban`, { isBanned }),
    `${isBanned ? "Banned" : "Unbanned"} user successfully!`,
    `Failed to ${isBanned ? "ban" : "unban"} user.`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}

export async function deleteUser(email) {
  const response = await apiWrapper(
    () => axiosInstance.delete(`/admin/users/${email}`),
    `User deleted successfully!`,
    `Failed to delete user.`
  );
  if (response.error) {
    throw new Error(response.error.message);
  }

  return response.data;
}
