import React, { useState } from "react";
import { Modal, Button, TextField, Typography, Box } from "@mui/material";
import Swal from "sweetalert2";
import { initiatePasswordReset } from "../../Services/UserService";

const ForgotPassword = ({ isOpen, handleClose }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    try {
      await initiatePasswordReset(email);

      handleClose();

      setTimeout(() => {
        Swal.fire({
          icon: "success",
          title: "تم إرسال البريد بنجاح",
          text: "يرجى التحقق من بريدك الإلكتروني لاستعادة كلمة المرور.",
          confirmButtonText: "حسنًا",
        });
      }, 300);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "حدث خطأ",
        text: error.message,
        confirmButtonText: "حسنًا",
      });
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          p: 3,
          backgroundColor: "#fff",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 3,
          borderRadius: 2,
          width: "90vw",
          maxWidth: 400,
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            textAlign: "center",
            fontWeight: 500,
            mb: 2,
          }}
        >
          استعادة كلمة المرور
        </Typography>
        <TextField
          label="أدخل بريدك الإلكتروني"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
          sx={{
            boxShadow: "none",
            "&:hover": {
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          إرسال البريد
        </Button>
      </Box>
    </Modal>
  );
};

export default ForgotPassword;
