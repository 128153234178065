import React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export default function ProgressStepper({
  steps,
  currentStep,
  handleNext,
  handlePrev,
  variant = 'progress',
  isLastChapter,
  isFirstChapter,
  nextLabel = 'التالي',
  prevLabel = 'السابق',
}) {
  const finalNextLabel =
    variant === 'progress' && currentStep === steps - 1
      ? 'الفصل التالي'
      : nextLabel;

  const finalPrevLabel =
    variant === 'progress' && currentStep === 0 ? 'الفصل السابق' : prevLabel;

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item xs={12}>
        {variant === 'progress' ? (
          <MobileStepper
            variant="progress"
            steps={steps}
            position="static"
            activeStep={currentStep}
            sx={{
              flexGrow: 1,
              width: '100%',
              background: 'transparent',
              '& .MuiLinearProgress-root': {
                height: '8px',
                borderRadius: '5px',
              },
              '& .MuiLinearProgress-barColorPrimary': {
                backgroundColor: 'rgb(67, 87, 117)',
              },
              '& .MuiLinearProgress-colorPrimary': {
                backgroundColor: 'rgb(191, 208, 233)',
              },
            }}
            nextButton={
              <Button
                size="medium"
                variant="outlined"
                onClick={handleNext}
                disabled={isLastChapter && currentStep === steps - 1}
                sx={{
                  marginRight: 1,
                  fontWeight: 'bold',
                }}
              >
                {finalNextLabel}
                <KeyboardArrowLeft sx={{ marginLeft: 1 }} />
              </Button>
            }
            backButton={
              <Button
                size="medium"
                variant="outlined"
                onClick={handlePrev}
                disabled={isFirstChapter && currentStep === 0}
                sx={{
                  marginLeft: 1,
                  fontWeight: 'bold',
                }}
              >
                <KeyboardArrowRight sx={{ marginRight: 1 }} />
                {finalPrevLabel}
              </Button>
            }
          />
        ) : (
          <MobileStepper
            variant="dots"
            steps={steps}
            position="static"
            activeStep={currentStep}
            sx={{
              flexGrow: 1,
              background: 'transparent',
              '& .MuiMobileStepper-dot': {
                backgroundColor: 'rgb(191, 208, 233)',
              },
              '& .MuiMobileStepper-dotActive': {
                backgroundColor: 'rgb(67, 87, 117)',
              },
            }}
            nextButton={
              <Button
                size="medium"
                variant="outlined"
                onClick={handleNext}
                disabled={currentStep === steps - 1}
                sx={{
                  marginRight: 1,
                  fontWeight: 'bold',
                }}
              >
                {finalNextLabel}
                <KeyboardArrowLeft sx={{ marginLeft: 1 }} />
              </Button>
            }
            backButton={
              <Button
                size="medium"
                variant="outlined"
                onClick={handlePrev}
                disabled={currentStep === 0}
                sx={{
                  marginLeft: 1,
                  fontWeight: 'bold',
                }}
              >
                <KeyboardArrowRight sx={{ marginRight: 1 }} />
                {finalPrevLabel}
              </Button>
            }
          />
        )}
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{
            fontFamily: 'ExpoArabic',
          }}
        >
          {`${currentStep + 1}/${steps} - ${
            variant === 'progress' ? 'أسئلة الفصل الحالي' : 'أسئلة فهم المقروء'
          }`}
        </Typography>
      </Grid>
    </Grid>
  );
}
