import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { motion } from "framer-motion";
import GroupIcon from "@mui/icons-material/Group";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PieChartIcon from "@mui/icons-material/PieChart";
import OverlayPaper from "../../Utilities/OverlayPaper";
import AttachmentOutlinedIcon from "@mui/icons-material/AttachmentOutlined";
import { Helmet } from "react-helmet-async";

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title> لوحة التحكم - אל-יע״ל</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "#EAEAEC",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "90vh",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontWeight: "bold",
            marginBottom: 5,
            color: "#23314B",
          }}
        >
          لوحة التحكم
        </Typography>

        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{ width: "100%", maxWidth: 1000 }}
        >
          {[
            {
              title: "إدارة المستخدمين",
              icon: <GroupIcon fontSize="large" />,
              helperText: "إدارة كل المستخدمين المسجلين بالنظام",
              path: "/admin/users/management",
            },
            {
              title: "إدارة المعلومات",
              icon: <QuestionMarkIcon fontSize="large" />,
              helperText: "إضافة، تعديل، أو حذف المعلومات المتاحة",
              path: "/admin/data/management",
            },
            {
              title: "إدارة الخطط و المزايا",
              icon: <PieChartIcon fontSize="large" />,
              helperText: "مشاهدة الخطط والمزايا  ",
              path: "/admin/subscriptions/management",
            },
            {
              title: "ادارة مواضيع الانشاء",
              icon: <AttachmentOutlinedIcon fontSize="large" />,
              helperText: "تقييم ومتابعة مواضيع الانشاء",
              path: "/admin/compositions/management",
            },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <motion.div
                whileHover={{
                  scale: 1.05,
                  boxShadow: "0px 20px 30px rgba(0, 0, 0, 0.2)",
                }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
              >
                <Link to={item.path} style={{ textDecoration: "none" }}>
                  <OverlayPaper>
                    <Box
                      sx={{
                        marginBottom: 2,
                        p: 1,
                        color: "#DADADC",
                        fontSize: "2.5rem",
                      }}
                    >
                      {item.icon}
                    </Box>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ marginBottom: 1, fontWeight: "600" }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      align="center"
                      sx={{ opacity: 0.75 }}
                    >
                      {item.helperText}
                    </Typography>
                  </OverlayPaper>
                </Link>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
